import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataPoint } from 'src/app/components/chart/chart.component';
import { PlatexListingOrder } from 'src/app/models/platexListingOrder';
import TrackingMetric from 'src/app/models/trackingMetric';
import { ListingService } from 'src/app/services/listing-service';

const HOUR = 3600000;

@Component({
  selector: 'pla-edit-listing-page',
  templateUrl: './edit-listing-page.component.html',
  styleUrls: ['./edit-listing-page.component.scss'],
})
export class EditListingPageComponent implements OnInit {
  private advertId: string;
  private oriListing: PlatexListingOrder;
  public listing: PlatexListingOrder;

  public trackingData: TrackingMetric[] = [];
  public impressionData: DataPoint[] = [];
  public clickData: DataPoint[] = [];
  public maxY: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private listingService: ListingService,
    private router: Router
  ) {
    this.advertId = this.activatedRoute.snapshot.params.advertId;
  }

  ngOnInit(): void {
    this.listingService.GetListing(this.advertId).subscribe(
      (_: PlatexListingOrder) => {
        this.listing = {
          ..._,
          price: Number.parseFloat((_.price / 100).toFixed(2)),
        } as PlatexListingOrder;
        this.oriListing = {
          ..._,
          price: Number.parseFloat((_.price / 100).toFixed(2)),
        } as PlatexListingOrder;
        this.listingLoaded();
      },
      () => {
        this.router.navigate([`/account/selling`]);
      }
    );
  }

  private listingLoaded(): void {
    this.listingService.GetListingMetrics(this.listing.registration).subscribe(
      (_: TrackingMetric[]) => this.sortMetrics(_),
      () => {}
    );
  }

  public hasChanged(): boolean {
    var res = JSON.stringify(this.listing) != JSON.stringify(this.oriListing);
    return res;
  }

  public DeleteListingHandler(): void {
    // delete listing
    this.listingService.DeleteListing(this.listing.advertId).subscribe(() => {
      this.router.navigate([`/account/selling`]);
    });
  }

  public UpdateListingHandler(): void {
    const postListing = JSON.parse(
      JSON.stringify(this.listing)
    ) as PlatexListingOrder;
    postListing.price = postListing.price * 100; // minor currency
    this.listingService.UpdateListing(postListing).subscribe(
      (_: PlatexListingOrder) => {
        this.listing = { ..._, price: _.price / 100 } as PlatexListingOrder;
        this.oriListing = { ..._, price: _.price / 100 } as PlatexListingOrder;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  private sortMetrics(metrics: TrackingMetric[]): void {
    const aggregateData = this.PadAggregate(metrics, 7);
    this.trackingData = aggregateData;
    this.impressionData = this.extractMetricData(
      aggregateData,
      'impressionCount'
    );
    this.clickData = this.extractMetricData(aggregateData, 'clickCount');
    this.maxY = this.calcMaxY(aggregateData);
  }

  private clamp = (num, min, max) => {
    return Math.min(Math.max(num, min), max);
  };

  private calcMaxY(metrics: TrackingMetric[]): number {
    const sorted = [...metrics]
      .sort(
        (a, b) =>
          Math.max(a.clickCount, a.comparedCount + a.impressionCount) -
          Math.max(b.clickCount, b.comparedCount + b.impressionCount)
      )
      .reverse();
    const highestRecord = sorted[0];
    var calcY = Math.max(
      highestRecord.clickCount,
      highestRecord.comparedCount + highestRecord.impressionCount
    );
    calcY = Math.round(this.clamp(calcY * 1.5, 1, calcY + 20)); // adds 10% onto max value, max 20 extra
    return calcY;
  }

  private extractMetricData(
    metrics: TrackingMetric[],
    key: string
  ): DataPoint[] {
    const m = metrics.map((m: TrackingMetric) => {
      return new DataPoint(m.day, m[key]);
    });
    return m;
  }

  private PadAggregate(
    metrics: TrackingMetric[],
    days: number = 7
  ): TrackingMetric[] {
    var aggregateData: any[] = [];

    const span = HOUR * 24;
    const totalSpan = span * days;
    const endDate = new Date(Date.now());
    endDate.setHours(0, 0, 0, 0);
    endDate.setDate(endDate.getDate() + 1);
    const startDate = new Date(endDate.getTime() - totalSpan);

    for (let i = startDate.getTime(); i < endDate.getTime(); i += span) {
      const dayMetric = metrics.filter((m) => {
        const tempDay = new Date(m.day);
        tempDay.setHours(0, 0, 0, 0);
        return tempDay.getTime() == i;
      });
      if (dayMetric.length == 0) {
        var _date = new Date(i);
        _date.setHours(0, 0, 0, 0);
        aggregateData.push(new TrackingMetric().AddDate(_date));
      } else {
        const day = dayMetric[0];
        day.impressionCount += day.comparedCount; // compares wont show, just count as impressions
        aggregateData.push(day);
      }
    }

    return aggregateData;
  }
}
