<div class="component-space"></div>
<div class="component-container not-fixed {{ w_drop ? 'drop' : '' }}">
  <div class="content-container no-ds">
    <div class="header-container">
      <div class="header">
        <div class="logo">
          <a [routerLink]="'/landing'"
            ><pla-platex-direct [colour]="'white'"></pla-platex-direct
          ></a>
        </div>
        <div class="navigation">
          <div class="desktop flex-space">
            <!-- NON ICON NAVIGATION -->
            <ng-container *ngFor="let navOption of navigation">
              <ng-container *ngIf="navOption.showOnDesktop && !navOption.icon">
                <ng-container *ngIf="navOption.menu; else noMenu">
                  <a class="nav-item" [matMenuTriggerFor]="menu">
                    {{ navOption?.viewRoute }}
                  </a>
                  <!-- MAIN DROPDOWN MENU -->
                  <mat-menu #menu="matMenu">
                    <!-- SUB MENU -->
                    <ng-container *ngFor="let navMenuItem of navOption.menu">
                      <ng-container *ngIf="navMenuItem.subMenu; else noSubMenu">
                        <button mat-menu-item [matMenuTriggerFor]="subMatMenu">
                          {{ navMenuItem?.viewRoute }}
                        </button>
                        <mat-menu #subMatMenu="matMenu">
                          <button
                            *ngFor="let navMenuSubItem of navMenuItem.subMenu"
                            mat-menu-item
                            (click)="navMenuSubItem.action()"
                          >
                            {{ navMenuSubItem?.viewRoute }}
                          </button>
                        </mat-menu>
                      </ng-container>

                      <!-- NO SUB MENU -->
                      <ng-template #noSubMenu>
                        <button mat-menu-item (click)="navMenuItem.action()">
                          {{ navMenuItem?.viewRoute }}
                        </button>
                      </ng-template>
                    </ng-container>
                  </mat-menu>
                </ng-container>

                <!-- NO MAIN DROPDOWN MENU -->
                <ng-template #noMenu>
                  <ng-container *ngIf="navOption != null">
                    <a class="nav-item" [routerLink]="navOption.route">
                      {{ navOption?.viewRoute }}
                    </a>
                  </ng-container>
                </ng-template>
              </ng-container>
            </ng-container>

            <div class="flex-space border-left"></div>

            <!-- SEARCH BAR -->
            <ng-container *ngIf="showDesktopSearchBar">
              <div class="desktop-search-container">
                <pla-option-search
                  [defaultSizing]="false"
                  [searchCriteria]="searchInput"
                  [endpoint]="'search-results'"
                  [inlineComponent]="true"
                  [deleteOnEnter]="true"
                ></pla-option-search>
              </div>
            </ng-container>

            <!-- ICON NAV OPTIONS -->
            <ng-container *ngFor="let navOption of navigation; let last = last">
              <ng-container *ngIf="navOption.showOnDesktop && navOption.icon">
                <ng-container *ngIf="navOption.menu; else noMenu">
                  <a class="nav-item withMenu" [matMenuTriggerFor]="menu">
                    <mat-icon
                      class="material-icons-outlined"
                      [matBadge]="notifcationCount"
                      [matBadgeOverlap]="true"
                      [matBadgeHidden]="notifcationCount == 0"
                      [matBadgeColor]="'warn'"
                      >{{ navOption.iconName }}</mat-icon
                    >
                  </a>
                  <mat-menu #menu="matMenu">
                    <ng-container *ngFor="let navMenuItem of navOption.menu">
                      <button mat-menu-item (click)="navMenuItem.action()">
                        {{ navMenuItem?.viewRoute }}
                      </button>
                    </ng-container>
                  </mat-menu>
                </ng-container>
                <ng-template #noMenu>
                  <a class="nav-item noMenu" [routerLink]="navOption.route">
                    <mat-icon
                      class="material-icons-outlined"
                      [matBadge]="notifcationCount"
                      [matBadgeOverlap]="true"
                      [matBadgeHidden]="notifcationCount == 0"
                      [matBadgeColor]="'warn'"
                      >{{ navOption.iconName }}</mat-icon
                    >
                  </a>
                </ng-template>
              </ng-container>
            </ng-container>
          </div>

          <div class="mobile flex-space">
            <div class="flex-space"></div>
            <a
              *ngIf="showSearchBar"
              class="nav-item"
              (click)="toggleSearch($event, !showFloatingSearch)"
            >
              <mat-icon>{{
                showFloatingSearch ? "search_off" : "search"
              }}</mat-icon>
            </a>
            <a class="nav-item" (click)="toggleNavigation()">
              <mat-icon
                [matBadge]="notifcationCount"
                [matBadgeOverlap]="true"
                [matBadgeHidden]="notifcationCount == 0"
                [matBadgeColor]="'warn'"
                >menu</mat-icon
              >
            </a>
          </div>
        </div>
      </div>
      <div
        class="navigation mobile floating-navigation"
        *ngIf="showSearchBar"
        [ngClass]="{ open: showFloatingSearch }"
      >
        <div class="m-auto">
          <pla-option-search
            [defaultSizing]="false"
            [searchCriteria]="searchInput"
            [endpoint]="'search-results'"
            [inlineComponent]="true"
            [deleteOnEnter]="true"
            [smaller]="true"
            (searched)="toggleSearch(null, false)"
          ></pla-option-search>
        </div>
      </div>
    </div>
  </div>
</div>
