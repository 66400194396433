<div class="component-container pad-header">
  <div class="page">
    <div class="main-content-container content-container">
      <div class="center-content-container">
        <!-- TOP DETAIL SECTION -->
        <div class="horizontal-section">
          <!-- REGISTRATION VIEWER -->
          <div class="section-fill flex-space">
            <div class="pla_card spacing flex-space" id="plate_viewer_image">
              <pla-image-slider
                [defaultImage]="quickImage"
                [imageOptionsChange]="imageOptionEmitter"
                [showShare]="true"
                [showFullscreen]="false"
                [loop]="true"
                [dots]="false"
                [arrows]="true"
                [selectionChange]="selectionChangeEmitter"
                (selectionChanged)="imageOptionChange($event)"
              ></pla-image-slider>
            </div>
            <div id="plate_viewer_selector">
              <select
                [disabled]="imageOptions.length <= 1"
                [compareWith]="imageCompareFn"
                [(ngModel)]="selectedImageOption"
                (ngModelChange)="selectionChangeEmitter.emit($event)"
              >
                <ng-container *ngFor="let option of imageOptions">
                  <option [ngValue]="option">{{ option.carName }}</option>
                </ng-container>
              </select>
            </div>

            <!-- INLINE WITH IMAGE -->
            <div id="title-container" class="inline">
              <div class="plate-container">
                <div style="padding: 20px 0; margin: auto">
                  <pla-plate
                    [loading]="registrationMetaData.loading"
                    [rawPlate]="
                      registrationMetaData.data?.formattedRegistration
                    "
                    [white]="false"
                    [mobileSmall]="false"
                    [openModal]="false"
                    [showPrice]="false"
                    [useCustom]="true"
                    [customSize]="9"
                    [customFontSize]="60"
                  ></pla-plate>
                  <a
                    *ngIf="showFullSearch"
                    class="search_all_link"
                    (click)="searchAllClickHandler()"
                    >Search more "{{ registrationString }}" plates</a
                  >
                </div>
              </div>

              <div *ngIf="isThirdPartyPlate; else platexPaymentDesc">
                <p class="pricing" *ngIf="priceRange != null">
                  <span *ngIf="priceRange.length > 1"
                    >Prices from <br />
                    {{ priceRange[0] / 100 | currency : "GBP" }} -
                    {{ priceRange[1] / 100 | currency : "GBP" }}</span
                  >
                  <span *ngIf="priceRange.length == 1">{{
                    priceRange[0] / 100 | currency : "GBP"
                  }}</span>
                </p>
              </div>
            </div>

            <div
              class="page-mobile-only pla_card no-card spacing no-top no-bottom slider-container"
              *ngIf="
                !registrationPaymentDetails.loading &&
                registrationPaymentDetails.data &&
                tabs.length > 1
              "
            >
              <pla-switch-toggle
                [selectedTabId]="activeSwitch"
                [tabs]="tabs"
                (switchChange)="SwitchChange($event)"
              ></pla-switch-toggle>
            </div>

            <!-- ADD ALL DESKTOP BELOW VIEW HERE -->

            <div class="page-desktop-only" *ngIf="isDesktopView()">
              <ng-container
                *ngTemplateOutlet="fullWidthPricingSection"
              ></ng-container>
            </div>

            <!-- NO DATA SECTION-->
            <div
              *ngIf="
                !registrationPaymentDetails.loading &&
                !registrationPaymentDetails.data
              "
            >
              <p style="text-align: center; font-size: 20px">
                Not currently available
              </p>

              <!-- NOTIFY -->
              <div
                style="text-align: center; padding: 0 0 20px 0"
                *ngIf="notifiedFetch"
              >
                <span class="ultra-light" style="padding: 10px">
                  Would you like to be notified if
                  {{ formattedRegistration }} becomes available for sale?
                </span>

                <div>
                  <pla-signed-up-content
                    *ngIf="formattedRegistration"
                    [requesting]="'notify plate ' + formattedRegistration"
                  >
                    <button
                      lockedContent
                      class="platex-button large-button"
                      mat-raised-button
                    >
                      <mat-icon>notifications</mat-icon>
                      Notify Me
                    </button>
                    <div unlockedContent>
                      <button
                        mat-raised-button
                        class="platex-button large-button"
                        (click)="notifyRegistrationHandler()"
                      >
                        <ng-container *ngIf="!notified; else notifyActive">
                          <mat-icon>notifications</mat-icon>
                          Notify Me
                        </ng-container>
                        <ng-template #notifyActive>
                          <mat-icon>notifications_active</mat-icon>
                          Notified
                        </ng-template>
                      </button>
                    </div>
                  </pla-signed-up-content>
                </div>
              </div>
            </div>

            <div class="page-desktop-only" *ngIf="isDesktopView()">
              <div *ngIf="showCompareResults">
                <ng-container *ngTemplateOutlet="compareSection"></ng-container>
              </div>
            </div>

            <!-- MOBILE VIEW -->
            <div class="page-mobile-only">
              <ng-container
                *ngIf="
                  !registrationPaymentDetails.loading &&
                    registrationPaymentDetails.data;
                  else plateDetailsSection
                "
              >
                <ng-container *ngIf="activeSwitch == 'plateinfo'">
                  <ng-container
                    *ngTemplateOutlet="plateDetailsSection"
                  ></ng-container>
                  <ng-container
                    *ngTemplateOutlet="fullWidthPricingSection"
                  ></ng-container>
                </ng-container>
                <ng-container *ngIf="activeSwitch == 'compare'">
                  <ng-container
                    *ngTemplateOutlet="compareSection"
                  ></ng-container>
                </ng-container>
              </ng-container>
            </div>

            <div
              *ngIf="checkoutEnabled"
              [style.display]="
                isDesktopView()
                  ? 'block'
                  : activeSwitch == 'checkout'
                  ? 'block'
                  : 'none'
              "
            >
              <ng-container *ngTemplateOutlet="checkoutSection"></ng-container>
            </div>

            <!-- ADD ALL DESKTOP VIEW ABOVE HERE -->
          </div>

          <!-- RIGHT HAND COLUMN START -->

          <div>
            <div class="page-desktop-only" *ngIf="isDesktopView()">
              <ng-container
                *ngTemplateOutlet="plateDetailsSection"
              ></ng-container>
            </div>
          </div>

          <!-- RIGHT HAND COLUMN END -->
        </div>
      </div>
    </div>
  </div>

  <ng-template #platexPaymentDesc>
    <div class="platex-purchase-row">
      <pla-platex-direct></pla-platex-direct>
      <p class="pricing">
        <span>{{ getPlatexPrice() / 100 | currency : "GBP" }}</span>
      </p>
      <button mat-flat-button class="turquoise-button">Buy</button>
    </div>
    <div>
      <div class="payment-messages">
        <div id="paypal-placement"></div>

        <!-- KLARNA W/OUT PRICE -->
        <klarna-placement
          data-key="top-strip-promotion-auto-size"
          data-locale="en-GB"
        ></klarna-placement>
        <!-- end Placement -->

        <!-- CARD ICONS -->
        <div class="pla_card-logos">
          <img class="logo" src="./../../../assets/payment/pay-visa.svg" />
          <img class="logo" src="./../../../assets/payment/pay-mcard.svg" />
          <img class="logo" src="./../../../assets/payment/pay-apple.svg" />
          <img class="logo" src="./../../../assets/payment/pay-google.svg" />
          <img class="logo" src="./../../../assets/payment/pay-klarna.svg" />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #loadingSpinner>
    <div class="spinner-loader">
      <mat-progress-spinner
        class="spinner cyan"
        mode="indeterminate"
        [color]="'#327b8d'"
        [strokeWidth]="7"
        [diameter]="75"
      ></mat-progress-spinner>
    </div>
  </ng-template>

  <ng-template #plateDetailsSection>
    <div id="plate_details" class="pla_card-spacing no-top no-bottom">
      <!-- REGISTRATOIN DETAIL INFORMATION -->
      <div
        class="pla_card section-fill no-left no-p-left px-1 spacing even-padding"
      >
        <div class="detail-content-container">
          <div>
            <h3 style="padding: 0 5px">
              Plate Info for {{ formattedRegistration }}
            </h3>
            <div
              id="info-items-container"
              *ngIf="!registrationMetaData.loading; else loadingSpinner"
            >
              <div
                *ngIf="
                  isRegisteredToVehicle &&
                  !registeredVehicleInfo.loading &&
                  registeredVehicleInfo.data
                "
                class="info-item"
              >
                <mat-icon class="">no_crash</mat-icon>
                <span class="value"
                  >Registered to a {{ registeredVehicleInfo.data.colour }}
                  {{ registeredVehicleInfo.data.make }} ({{
                    getCarYear(registeredVehicleInfo.data)
                  }})</span
                >
                <div class="flex-space"></div>
              </div>

              <div class="info-item">
                <mat-icon class="">style</mat-icon>
                <span class="value"
                  >{{ registrationMetaData.data.style }} Style</span
                >
                <div class="flex-space"></div>
              </div>

              <div class="info-item">
                <mat-icon class="">today</mat-icon>
                <span class="value"
                  >{{
                    registrationMetaData.data.style == "Dateless"
                      ? "Original Issue From "
                      : "Original Issue "
                  }}
                  {{ registrationMetaData.data.issueMonth }}
                  {{ registrationMetaData.data.issueYear }}</span
                >
                <div class="flex-space"></div>
              </div>

              <div class="info-item">
                <mat-icon class="">map</mat-icon>
                <span class="value"
                  >DVLA Office -
                  {{ registrationMetaData.data.issueRegion }}</span
                >
                <div class="flex-space"></div>
              </div>

              <div
                class="info-item toggle text-collapse toggler"
                (click)="toggleSoldDataVisibilityHandler()"
              >
                <mat-icon class="">{{ priceHistoryIcon }}</mat-icon>
                <span class="value">{{ priceHistoryTitle }}</span>
                <div class="flex-space"></div>
                <div
                  *ngIf="
                    !registrationHistory.loading &&
                    (requireLogin ||
                      isThirdPartyPlate ||
                      hasSoldData(
                        minDataPointsForTable,
                        requireDifferentConsecutivePointsForTable
                      ))
                  "
                  class="hide-after-break"
                >
                  <mat-icon *ngIf="!historyOpen">expand_more</mat-icon>
                  <mat-icon *ngIf="historyOpen">expand_less</mat-icon>
                </div>
              </div>
            </div>
          </div>

          <div
            id="registration-history"
            [ngClass]="{ open: historyOpen }"
            *ngIf="!registrationMetaData.loading"
          >
            <pla-signed-up-content
              class="text-collapse content"
              *ngIf="formattedRegistration"
              [ngClass]="{ closed: !historyOpen }"
              [requesting]="'registration history ' + formattedRegistration"
              [active]="false"
            >
              <div lockedContent>
                <div class="px-1">
                  <p class="mb-0">
                    <strong class="fw-bold">Register</strong> now for FREE to
                    view Price History, Favourite plates and more.<br />Already
                    a member -
                    <strong class="fw-bold">Login.</strong>
                  </p>
                </div>
              </div>

              <div unlockedContent>
                <div
                  class="text-collapse content"
                  *ngIf="!registrationHistory.loading"
                >
                  <div
                    class="chart-container"
                    *ngIf="
                      hasSoldData(
                        minDataPointsForChart,
                        requireDifferentConsecutivePointsForChart
                      )
                    "
                  >
                    <pla-chart
                      [dataPoints]="registrationHistoryPoints"
                    ></pla-chart>
                  </div>
                  <pla-sold-data-table
                    *ngIf="
                      hasSoldData(
                        minDataPointsForTable,
                        requireDifferentConsecutivePointsForTable
                      )
                    "
                    [data]="registrationHistory.data"
                  ></pla-sold-data-table>

                  <p
                    *ngIf="
                      !hasSoldData(
                        minDataPointsForTable,
                        requireDifferentConsecutivePointsForTable
                      )
                    "
                  >
                    No Sales Recorded{{
                      dvlaPlate ? " - New Issue Government Stock" : null
                    }}
                  </p>
                </div>
              </div>
            </pla-signed-up-content>
            <div
              *ngIf="
                !registrationMetaData.loading &&
                registrationMetaData.data &&
                formattedRegistration
              "
            >
              <p
                style="margin: 15px auto 0; text-align: center"
                *ngIf="
                  registrationMetaData.data.style != 'Dateless';
                  else datelessRegPass
                "
              >
                {{ formattedRegistration }} can be assigned to vehicles
                registered after {{ registrationMetaData.data.issueMonth }}
                {{ registrationMetaData.data.issueYear }}
              </p>
              <ng-template #datelessRegPass>
                <p style="margin: 15px auto 0; text-align: center">
                  {{ formattedRegistration }} can be assigned to any vehicle
                </p>
              </ng-template>
            </div>
          </div>

          <div class="flex-space"></div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #fullWidthPricingSection>
    <!-- PRICING SECTION -->
    <div
      id="title-container"
      class="box-container"
      *ngIf="!registrationPaymentDetails.loading"
    >
      <div class="plate-container">
        <div style="padding: 20px 0 10px; margin: auto">
          <pla-plate
            [loading]="registrationMetaData.loading"
            [rawPlate]="registrationMetaData.data?.formattedRegistration"
            [white]="false"
            [mobileSmall]="false"
            [openModal]="false"
            [showPrice]="false"
            [useCustom]="true"
            [customSize]="9"
            [customFontSize]="60"
          >
          </pla-plate>
          <a
            *ngIf="showFullSearch"
            class="search_all_link"
            (click)="searchAllClickHandler()"
            >Search more "{{ registrationString }}" plates</a
          >
        </div>
      </div>

      <div *ngIf="isThirdPartyPlate; else platexPaymentDesc">
        <p class="pricing" *ngIf="priceRange != null">
          <span *ngIf="priceRange.length > 1"
            >Prices from <br />
            {{ priceRange[0] / 100 | currency : "GBP" }} -
            {{ priceRange[1] / 100 | currency : "GBP" }}</span
          >
          <span *ngIf="priceRange.length == 1">{{
            priceRange[0] / 100 | currency : "GBP"
          }}</span>
        </p>
      </div>

      <div *ngIf="!showCompareResults">
        <button
          mat-flat-button
          style="display: block; margin: 10px auto"
          (click)="showCompareResults = true"
          class="turquoise-border-button"
        >
          See All Deals
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #compareSection>
    <!-- COMBINED SECTION -->
    <div class="horizontal-section">
      <pla-compare-results-table
        style="width: 100%"
        *ngIf="
          registrationMetaData.data?.formattedRegistration &&
          registrationPaymentDetails.data
        "
        [showHeader]="!isThirdPartyPlate"
        [showTitle]="false"
        [showNotFoundRows]="true"
        [registration]="registrationMetaData.data?.formattedRegistration"
        [sellerResults]="registrationPaymentDetails.data.sellersResp.sellers"
        [lastChecked]="registrationPaymentDetails.data.sellersResp.lastUpdated"
      >
      </pla-compare-results-table>
    </div>

    <!-- PLATEX SELLING SECTION -->
    <div
      class="horizotal-section platex-selling"
      *ngIf="false && !isThirdPartyPlate"
    >
      <div class="section-fill" *ngIf="!registrationPaymentDetails.loading">
        <div
          class="no-card flex-space spacing padding top-seller-container"
          *ngIf="registrationPaymentDetails.data"
        >
          <h2>
            Todays best deals for
            <pla-plate
              [rawPlate]="registrationMetaData.data.formattedRegistration"
              [inlineText]="true"
              [showPrice]="false"
              [openModal]="false"
              [loading]="registrationMetaData.loading"
              [white]="true"
            >
            </pla-plate>
          </h2>
          <pla-seller-table
            [sellers]="registrationPaymentDetails.data.sellersResp.sellers"
          ></pla-seller-table>
          <span class="ultra-light" style="margin-top: 5px"
            >Last updated
            {{
              registrationPaymentDetails.data.sellersResp.lastUpdated
                | date : "mediumDate"
            }}</span
          >
        </div>
      </div>

      <!-- <div *ngIf="!showCompareSellers">
        <button
          mat-flat-button
          style="margin-bottom: 20px"
          class="see-more-button turquoise-border-button"
          (click)="showMoreDealsClickHandler()"
        >
          See More Deals
        </button>
      </div> -->
    </div>
  </ng-template>

  <ng-template #checkoutSection>
    <div *ngIf="!isThirdPartyPlate">
      <div
        *ngIf="!registrationPaymentDetails.loading"
        class="price-breakdown-container pla_card no-card spacing"
      >
        <pla-price-breakdown
          [paymentDetails]="registrationPaymentDetails.data"
        ></pla-price-breakdown>
      </div>
      <div>
        <div *ngIf="!registrationPaymentDetails.loading; else loadingSpinner">
          <div class="flex-space" *ngIf="registrationPaymentDetails.data">
            <pla-plate-checkout-steps
              [continueCheckout]="continueCheckout"
              [paymentDetails]="registrationPaymentDetails.data"
            ></pla-plate-checkout-steps>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
