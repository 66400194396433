import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPCONSTANTS } from '../http/http-constants';
import { PlateViewerConfig } from '../models/plateViewerConfig';
import { PlatexComparisonSite } from '../models/platexComparisonSite';
import { SplitSearchResp } from '../models/splitSearchResp';
import { PlatopediaWord } from '../models/platopediaWord';

@Injectable({ providedIn: 'root' })
export class AdminService {
  constructor(private http: HttpClient) {}

  public verify(): Observable<any> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/authenticate/verify`;
    return this.http.get(url);
  }

  public splitSearch(
    term: string,
    plate1: string,
    plate2: string
  ): Observable<SplitSearchResp> {
    var url = `${HTTPCONSTANTS.platoProtocol}${HTTPCONSTANTS.platoBaseAddress}/side-compare/${term}/${plate1}/${plate2}`;
    return this.http.get<SplitSearchResp>(url);
  }

  public getImageConfigGroups(): Observable<PlateViewerConfig[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/admin/get-plate-viewer-config-groups`;
    return this.http.get<PlateViewerConfig[]>(url);
  }

  public getImageConfigsForGroup(
    groupId: string
  ): Observable<PlateViewerConfig[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/admin/get-plate-viewer-config-by-group/${groupId}`;
    return this.http.get<PlateViewerConfig[]>(url);
  }

  public getCompareSites(): Observable<PlatexComparisonSite[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/compare/sites`;
    return this.http.get<PlatexComparisonSite[]>(url);
  }

  public addPlatopediaWord(word: PlatopediaWord): Observable<void> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/platopedia/add`;
    return this.http.post<void>(url, word);
  }

  public addMultiTermHeader(header: string): Observable<void> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/multi-term/header/add/${header}`;
    return this.http.get<void>(url);
  }

  public addMultiTermsToHeader(
    header: string,
    terms: string[]
  ): Observable<void> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/search/multi-term/terms/add/${header}`;
    return this.http.post<void>(url, terms);
  }
}
