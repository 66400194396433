export enum SaleType {
  DVLA_AUTION = 0,
  DVLA = 1,
  PLATEX = 2,
  THIRD_PARTY = 3,
  CURRENT_LISTING = 4,
  INITIAL_PRICE = 5,
  DVLA_AUCTION_IMPORT = 6,
  THIRD_PARTY_AUCTION_IMPORT = 7,
  PRICE_CHANGE = 8,
  CUSTOM = 9,
}

export class SoldPlate {
  public id: number;
  public registration: string;
  public inf_sold_price: string;
  public sold_price: string;
  public price: number;
  public style: string;
  public sold_date: Date;
  public saleType: SaleType;
  public prefix: string;
  public numbers: string;
  public letters: string;
  public seller: string;
}
