export class BYOTermBreakdown {
  public style: string;
  public prefix: string;
  public numbers: string;
  public letters: string;

  public construct(
    _sty: string,
    _pre: string,
    _num: string,
    _let: string
  ): BYOTermBreakdown {
    this.style = _sty;
    this.prefix = _pre;
    this.numbers = _num;
    this.letters = _let;
    return this;
  }
}

export class BYOTermResponse {
  public current: BYOTermBreakdown;
  public prefix: BYOTermBreakdown;
  public suffix: BYOTermBreakdown;

  public construct(): BYOTermResponse {
    this.current = new BYOTermBreakdown().construct('current', '', '', '');
    this.prefix = new BYOTermBreakdown().construct('prefix', '', '', '');
    this.suffix = new BYOTermBreakdown().construct('suffix', '', '', '');

    return this;
  }
}
