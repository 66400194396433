import { EventEmitter, Injectable } from '@angular/core';
import {
  BANNER_IMAGES,
  DEFAULT_IMAGE_ID,
  quick_config,
} from '../consts/default-image';
import { PlateViewerConfig } from '../models/plateViewerConfig';
import { AdminService } from './admin-service';
import { SessionService } from './session-service';
import { SmartPlateViewer } from './smart-plate-viewer-service';

export class ImageKeyPair {
  constructor(
    public imageId: number,
    public key: string,
    public carName: string,
    public image: string
  ) {}
}

export class ImageGenerationRequest {
  constructor(
    public key: string,
    public config: PlateViewerConfig,
    public registration: string,
    public callback: (key: ImageKeyPair) => void
  ) {}
}

@Injectable({ providedIn: 'root' })
export class ImageService {
  private _spv: SmartPlateViewer = new SmartPlateViewer();
  private images: ImageKeyPair[] = [];
  private configs: PlateViewerConfig[] = [];
  private requestQueue: ImageGenerationRequest[] = [];
  public defaultImage: ImageKeyPair;

  private requesting: boolean = false;
  private hasLoaded: boolean = false;
  private fetchComplete: EventEmitter<void> = new EventEmitter<void>();
  private serviceReady: EventEmitter<void> = new EventEmitter<void>();
  private generateComplete: EventEmitter<void> = new EventEmitter<void>();
  private queueCleared: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private adminService: AdminService,
    private sessionService: SessionService
  ) {}

  public INIT(): ImageService {
    this.forceStoredImage();

    this.fetchComplete.subscribe(() => {
      this.hasLoaded = true;
      this.serviceReady.emit();
    });
    this.generateComplete.subscribe(() => {
      this.CompleteQueueItem();
    });
    this.fetchConfigs();

    return this;
  }

  public getQuickConfig(): PlateViewerConfig {
    return quick_config;
  }

  public getDefaultImage(): { id: number; url: string } {
    return BANNER_IMAGES.filter((_) => _.id == DEFAULT_IMAGE_ID)[0];
  }

  private forceStoredImage(): void {
    this.defaultImage = new ImageKeyPair(
      this.getDefaultImage().id,
      'DEFAULT',
      'DEFAULT',
      this.getDefaultImage().url
    );
  }

  public quickBuild(
    quickConfig,
    registration: string,
    callback: (key: ImageKeyPair) => void
  ): void {
    this.generateQuickImage(quickConfig, registration, callback);
  }

  public whenReady(callback: () => void): void {
    if (this.hasLoaded && !this.requesting) {
      callback();
      return;
    }

    // waiting
    this.serviceReady.subscribe(() => {
      callback();
    });
  }

  private CompleteQueueItem(): void {
    if (this.requestQueue.length == 0) {
      this.queueCleared.emit();
      return;
    }
    var req = this.requestQueue.pop();
    this.executeImageGenerationRequest(req);
  }

  public getImageByKey(key: string): ImageKeyPair {
    if (this.images.length == 0) return null;
    var imageByKey = this.images.filter((i) => i.key == key);
    if (imageByKey == null || imageByKey == [] || imageByKey.length == 0)
      return null;
    return imageByKey[0];
  }

  public getConfigById(id: number): PlateViewerConfig {
    if (this.configs.length == 0) return null;
    var configById = this.configs.filter((i) => i.id == id);
    if (configById == null || configById == [] || configById.length == 0)
      return null;
    return configById[0];
  }

  private fetchConfigs(): void {
    this.adminService
      .getImageConfigGroups() // this is unique groups
      .subscribe((_configs: PlateViewerConfig[]) => {
        _configs = _configs.map((c: PlateViewerConfig) => {
          var _ = new PlateViewerConfig(c);
          return _;
        });

        this.configs = _configs;
        setTimeout(() => {
          this.fetchComplete.emit();
        }, 1000);
      });
  }

  public generateImageForAllConfigs(
    pageQuickImage: PlateViewerConfig,
    registration: string,
    startCount = 0,
    maxCount: number = -1,
    callback: (keys: ImageKeyPair[]) => void
  ): void {
    var keys: ImageKeyPair[] = [];
    var sub = this.queueCleared.subscribe(() => {
      sub.unsubscribe();
      this.generateImageLoop(
        registration,
        startCount,
        maxCount,
        (key: ImageKeyPair) => {
          keys.push(key);
          if (
            startCount + keys.length == this.configs.length ||
            (maxCount > -1 && keys.length + startCount == maxCount)
          ) {
            keys = keys.filter((k) => {
              var inNVI = [pageQuickImage.id].filter((nvi) => nvi == k.imageId);
              return inNVI.length == 0;
            });
            callback(keys);
          }
        }
      );
    });

    if (this.requesting) {
      // will get triggered
    } else {
      this.queueCleared.emit();
    }
  }

  private generateImageLoop(
    registration: string,
    configIndex: number,
    maxCount: number,
    callback: (key: ImageKeyPair) => void
  ) {
    this.generateImage(
      this.configs[configIndex],
      registration,
      (key: ImageKeyPair) => {
        callback(key); // send key to array

        if (
          configIndex < this.configs.length - 1 &&
          (maxCount == -1 || configIndex <= maxCount)
        )
          this.generateImageLoop(
            registration,
            configIndex + 1,
            maxCount,
            callback
          );
      }
    );
  }

  private addToQueue(req: ImageGenerationRequest): void {
    this.requestQueue.push(req);
  }

  private createRequest(
    key: string,
    config: PlateViewerConfig,
    registration: string,
    callback: (key: ImageKeyPair) => void
  ): ImageGenerationRequest {
    var request = new ImageGenerationRequest(
      key,
      config,
      registration,
      callback
    );
    return request;
  }

  public generateImage(
    config: PlateViewerConfig,
    registration: string,
    callback: (key: ImageKeyPair) => void
  ): void {
    var key = `${registration}_${config.id}`.replace(' ', '-');

    var existingVal = this.getImageByKey(key);
    if (existingVal != null) {
      callback(existingVal);
      return;
    }

    var request = this.createRequest(key, config, registration, callback);

    if (this.requesting) {
      this.addToQueue(request);
      return;
    }

    this.executeImageGenerationRequest(request);
  }

  private generateQuickImage(
    quickConfig: PlateViewerConfig,
    registration: string,
    callback: (key: ImageKeyPair) => void
  ): void {
    var key = `${registration}_${quickConfig.id}`.replace(' ', '-');
    var existingVal = this.getImageByKey(key);
    if (existingVal != null) {
      callback(existingVal);
      return;
    }
    var request = this.createRequest(key, quickConfig, registration, callback);
    if (this.requesting) {
      this.addToQueue(request);
      return;
    }

    this.executeImageGenerationRequest(request);
  }

  private executeImageGenerationRequest(req: ImageGenerationRequest): void {
    this.requesting = true;
    var _ = new PlateViewerConfig(req.config);
    let params = {
      isAdmin: this.sessionService.isAdmin(),
      text: req.registration,
      ..._.getConfig()['config'],
    };
    this._spv.startWithPosition(params, (imageSrc: string) => {
      _.image = imageSrc;
      this._spv.dispose();
      var newKeyPairEntry = new ImageKeyPair(
        req.config.id,
        req.key,
        req.config.carName,
        imageSrc
      );
      this.images.push(newKeyPairEntry);
      this.requesting = false;
      req.callback(newKeyPairEntry);
      this.generateComplete.emit(); // trigger waiting generations
    });
  }
}
