<div class="page">
  <div class="section page-width page-container">
    <div>
      <h1>Create an account</h1>
      <p>
        A Platex account gives you access to all our services. It lets you buy
        and sell registrations, track orders and much more.
      </p>
    </div>

    <div class="details-container">
      <div class="inner-container">
        <h1>Register</h1>
        <form class="form-container" [formGroup]="registerForm">
          <mat-form-field>
            <input
              matInput
              required
              formControlName="email"
              placeholder="Email"
              name="email"
              type="email"
            />
            <mat-error
              *ngIf="
                registerForm.controls.email.hasError('email') &&
                !registerForm.controls.email.hasError('required')
              "
            >
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="registerForm.controls.email.hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              required
              formControlName="firstName"
              placeholder="First Name"
              name="first_name"
              type="text"
            />
            <mat-error
              *ngIf="registerForm.controls.firstName.hasError('required')"
            >
              First name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              required
              formControlName="lastName"
              placeholder="Surname"
              name="last_name"
              type="text"
            />
            <mat-error
              *ngIf="registerForm.controls.lastName.hasError('required')"
            >
              Surname is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              required
              formControlName="password"
              placeholder="Password"
              name="password"
              type="password"
            />
            <mat-error
              *ngIf="registerForm.controls.password.hasError('required')"
            >
              Password is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              required
              formControlName="passwordRepeat"
              placeholder="Repeat Password"
              name="password"
              type="password"
            />
            <mat-error
              *ngIf="registerForm.controls.passwordRepeat.hasError('required')"
            >
              Repeat Password is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </form>

        <div class="response-errors" *ngIf="registerErrors">
          <ng-container *ngFor="let error of registerErrors">
            <p>{{ error }}</p>
          </ng-container>
        </div>

        <div style="width: 90%">
          <button mat-raised-button color="warn" (click)="register()">
            Sign Up
          </button>
        </div>
      </div>

      <div class="flex-space">
        <div class="spacer"></div>
      </div>

      <div class="inner-container" id="info-container">
        <div class="d-none">
          <h3>Established since</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et
            lacus elit. Suspendisse at condimentum leo. Nulla hendrerit et
            sapien sed laoreet. Praesent diam neque, auctor vel malesuada in,
            pretium et ligula.
          </p>
        </div>

        <div class="d-none">
          <h3>Secure online transactions</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et
            lacus elit. Suspendisse at condimentum leo. Nulla hendrerit et
            sapien sed laoreet. Praesent diam neque, auctor vel malesuada in,
            pretium et ligula.
          </p>
        </div>

        <div>
          <h3 class="text-center">Already have an account?</h3>
          <div style="width: 100%">
            <button mat-raised-button color="warn" (click)="login()">
              Log In
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
