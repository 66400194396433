<div class="page">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container">
    <div class="center-content-container">
      <!-- SEARCH CRITERIA -->
      <div class="pla_card no-card mobile spacing no-left no-right no-top">
        <pla-banner-image [taller]="true" [carBanner]="'LAMBORGHINI_DIABLO'">
          <div class="page-title">
            <h1>INITIALS</h1>
            <span class="sub">Search yours now</span>
          </div>
        </pla-banner-image>
        <!--  -->
        <div class="search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <pla-wildcard-character-select
                (inlineSearch)="doInlineSearch($event)"
                [boxCount]="3"
                [showMessage]="false"
                [letters]="initials"
                [style]="'any_text'"
                [withPadding]="false"
                [loading]="
                  dlf_searching ||
                  dnf_searching ||
                  current_searching ||
                  prefix_searching ||
                  suffix_searching
                "
                [validationRegex]="'[A-Za-z]+$'"
              ></pla-wildcard-character-select>
              <span class="header">Search up to 3 initials</span>
            </div>
          </div>
        </div>
      </div>

      <!-- RESULTS -->
      <div
        *ngIf="
          initialResults ||
            dlf_searching ||
            dnf_searching ||
            current_searching ||
            prefix_searching ||
            suffix_searching;
          else infoSection
        "
      >
        <div class="pla_card no-card mobile spacing">
          <pla-initial-section
            [header]="'Dateless - Letters First'"
            [results]="initialResults.dateless_letter_first"
            [searching]="dlf_searching"
            [resultChange]="dlf_resultsChange"
          ></pla-initial-section>
          <pla-initial-section
            [header]="'Dateless - Numbers First'"
            [results]="initialResults.dateless_number_first"
            [searching]="dnf_searching"
            [resultChange]="dnf_resultsChange"
          ></pla-initial-section>
          <pla-initial-section
            [header]="'Current Style'"
            [results]="initialResults.current"
            [searching]="current_searching"
            [resultChange]="c_resultsChange"
          ></pla-initial-section>
          <pla-initial-section
            [header]="'Prefix Style'"
            [results]="initialResults.prefix"
            [searching]="prefix_searching"
            [resultChange]="p_resultsChange"
          ></pla-initial-section>
          <pla-initial-section
            [header]="'Suffix Style'"
            [results]="initialResults.suffix"
            [searching]="suffix_searching"
            [resultChange]="s_resultsChange"
          ></pla-initial-section>
        </div>
      </div>

      <ng-template #infoSection>
        <!-- Page steps -->
        <div class="d-none page-steps">
          <pla-page-steps [steps]="pageSteps"></pla-page-steps>
        </div>

        <div class="pla_card no-card no-top no-p-top mobile spacing padding">
          <div class="px-4">
            <div class="text-title heading">
              <h2 class="border-bottom mb-1">
                <b class="strong">Create A Monogram</b>
              </h2>
            </div>
            <div class="text-section">
              <div class="text-paragraph">
                <p>
                  Initials are the most popular option for personalising a
                  registration and its never been easier to find your perfect
                  initial plate. Simply enter up to 3 letters and click search
                  and
                  <pla-glossary-term-word
                    [term]="'PLATO'"
                    [text]="'PLATO'"
                  ></pla-glossary-term-word>
                  will return you the best registrations to showcase your
                  initials.
                </p>
              </div>
            </div>

            <div class="text-title heading mt-3">
              <h2 class="border-bottom mb-1">
                <b class="strong">Notify</b>
              </h2>
            </div>
            <div class="text-section">
              <div class="text-paragraph">
                <p class="indent">
                  If the particular number you wanted to go alongside your
                  initials is not available then why not take advantage of our
                  free `Notify` service. Once enabled you will be notified first
                  giving you the best chance to snap it up!
                </p>
              </div>

              <!-- TODO: FEEDBACK -->
              <div class="text-paragraph d-none">
                <div class="faq-item">
                  <mat-icon>help_outline</mat-icon>
                  <h4>
                    I want a particular number to go alongside my initials but
                    it’s not there?
                  </h4>
                </div>
                <p class="indent">
                  This may mean that the number was not part of the series of
                  has already been sold. Toggle to ‘See All’ and if you see the
                  registration, you want click Notify and should it ever come
                  back to the market you can be informed!
                </p>

                <div class="faq-item">
                  <mat-icon>help_outline</mat-icon>
                  <h4>Can I put any of these registrations on my vehicle?</h4>
                </div>
                <p class="indent">
                  You are not allowed to make a vehicle appear younger by
                  transferring a more recent registration. Simply click on the
                  registration to see the minimum age of a vehicle needed for
                  that registration.
                </p>
              </div>
            </div>
          </div>

          <!-- FEATURED LISTING -->
          <div class="featured-container mt-3">
            <div class="px-4">
              <h2 class="border-bottom mb-1">
                <b class="strong">Number 1 Plates</b>
              </h2>
            </div>
            <div>
              <pla-featured-plates
                [featureType]="initialFeatured"
                [showTitle]="false"
              ></pla-featured-plates>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
