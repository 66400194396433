<div class="page">
  <div class="content-container">
    <div class="side-container p-3 pt-0 position-relative">
      <div class="position-sticky">
        <pla-side-nav></pla-side-nav>
      </div>
    </div>
    <div class="center-content-container">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative"
      >
        <pla-banner-image
          [carBanner]="'PORSCHE_918_S'"
          [rawImage]="true"
          [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column p-4 p-md-4 new-banner-height"
            style="background-color: rgba(6, 34, 41, 0.45)"
          >
            <!-- HEADER TEXT -->
            <div
              class="text-white text-center w-100 align-self-center justify-content-center"
            >
              <p class="h1 mb-1 fw-bold">Number Plate News</p>
              <div>
                <p class="fw-light fs-6" style="color: #facf15">
                  Hot off the Press Plate News and DVLA Updates
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>

        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 py-2"
            >
              <a
                style="color: #327b8d"
                class="d-block text-decoration-none"
                href="/"
                >Home</a
              >
              <span>/</span>
              <a
                style="color: #327b8d; white-space: pre"
                class="text-decoration-none d-block"
                href="/blogs"
                >Number Plate News</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="content-container">
        <div class="center-content-container">
          <div
            class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-2"
          >
            <ng-container *ngFor="let blogPost of blogPosts">
              <a
                href="/blogs/{{ blogPost.handle }}"
                class="d-block text-dark text-decoration-none py-2 border-bottom"
              >
                <!-- BLOG POST ROW -->
                <p class="mb-0 d-none d-md-block">
                  {{ blogPost.created | ordinalDate }}
                </p>
                <div
                  class="d-flex align-items-stretch justify-content-start gap-3"
                >
                  <div>
                    <img
                      src="{{ blogPost.imageUrl | noCache }}"
                      class="d-none d-sm-block w-100 flex-shrink-0"
                      style="max-width: 200px; min-width: 200px"
                    />
                    <img
                      src="{{ blogPost.imageUrl | noCache }}"
                      class="d-block d-sm-none w-100 mb-2 flex-shrink-0"
                      style="max-width: 150px; min-width: 120px"
                    />
                  </div>
                  <div
                    class="d-flex flex-fill flex-column align-items-start justify-content-start"
                  >
                    <p
                      class="link-underline text-decoration-none fs-5 text-decoration-none"
                    >
                      {{ blogPost.title }}
                    </p>
                    <small class="d-none d-md-block">
                      {{ blogPost.excerpt }}
                    </small>
                    <div class="flex-fill"></div>
                    <p class="mb-0 d-block d-md-none">
                      {{ blogPost.created | ordinalDate }}
                    </p>
                    <div
                      class="w-100 d-flex align-items-center justify-content-start justify-content-md-end"
                    >
                      <p
                        class="mb-0 text-decoration-none"
                        style="color: #327b8d"
                      >
                        Read On...
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </ng-container>
          </div>
        </div>

        <div class="side-container lg bg-light">
          <div class="">
            <pla-featured-plates
              [featureType]="rsFeatured"
              [plateClass]="'flat'"
              [plateSize]="'featured'"
              [priceFontSize]="12"
              [priceClass]="'text-muted fst-italic'"
              [maxCount]="14"
            ></pla-featured-plates>
          </div>
          <div class="border-top">
            <pla-featured-plates
              [featureType]="raFeatured"
              [plateClass]="'flat'"
              [plateSize]="'featured'"
              [priceFontSize]="12"
              [maxCount]="14"
            ></pla-featured-plates>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" *ngIf="false">
    <pla-banner-image
      class="w-100"
      [rawImage]="true"
      [backgroundImage]="true"
      [carBanner]="'AVENTADOR_S_FOOTER'"
      [backgroundClass]="'w-100 desktop-mobile-bottom'"
    >
      <div
        class="d-flex align-items-stretch justify-content-end new-banner-height"
      >
        <div
          class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
            style="max-width: 450px"
          >
            <p class="fs-5 text-white">
              With the entire market of registrations on one single portal,
              industry leading optimised search facilities and even price
              comparison it's no wonder buyers are choosing PlateX to find their
              perfect plate.
            </p>

            <div class="align-self-end mt-4">
              <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                Begin Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </pla-banner-image>
  </div>
</div>
