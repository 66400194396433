<div class="page">
  <div class="section page-width page-container">
    <div>
      <h1>Sign in or create an account</h1>
      <p>
        A Platex account gives you access to all our services. It lets you buy
        and sell registrations, track orders and much more.
      </p>
    </div>

    <div class="details-container">
      <div class="inner-container">
        <h1>Sign In</h1>
        <div class="form-container">
          <mat-form-field>
            <input
              matInput
              required
              placeholder="Email"
              name="email"
              type="email"
              [(ngModel)]="loginModel.email"
            />
          </mat-form-field>

          <mat-form-field>
            <input
              matInput
              required
              placeholder="Password"
              name="password"
              type="password"
              [(ngModel)]="loginModel.password"
              color="warn"
            />
          </mat-form-field>

          <div *ngIf="!loading">
            <button mat-raised-button color="warn" (click)="login()">
              Sign In
            </button>
          </div>
        </div>

        <div class="loading-container" *ngIf="loading">
          <mat-progress-spinner
            class="loading-spinner"
            color="accent"
            mode="indeterminate"
          >
          </mat-progress-spinner>
        </div>
      </div>

      <div class="flex-space">
        <div class="spacer"></div>
      </div>

      <div class="inner-container">
        <h1>Create an account</h1>
        <p>
          A Platex account gives you access to all our services. It lets you buy
          and sell registrations, track orders and much more.
        </p>
        <div>
          <button mat-raised-button color="warn" (click)="signUp()">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
