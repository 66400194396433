<div class="page pad-header">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container" id="results-section">
    <div class="center-content-container">
      <!-- SEARCH CARD + BANNER -->
      <div class="pla_card mobile spacing no-top no-card no-left no-right">
        <pla-banner-image
          *ngIf="false"
          [taller]="true"
          [registration]="bannerRegistration"
          [update]="bannerUpdate"
        >
          <div class="banner-content-container">
            <div class="banner-title">
              <div class="page-banner-title">Search Results</div>
            </div>
          </div>
        </pla-banner-image>

        <div class="rel-search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <pla-option-search
                [optionsEnabled]="false"
                [endpoint]="'search-results'"
                [searchCriteria]="searchCriteria"
                [loading]="superSearching || perfectSearching"
                [plateStyle]="true"
              ></pla-option-search>
              <span class="header">Search a word or registration</span>
              <div
                *ngIf="alternativeOptions && alternativeOptions.length > 0"
                class="alt-search-options"
              >
                <ng-container *ngFor="let option of alternativeOptions">
                  <div
                    (click)="routeToNewSearch(option)"
                    class="alt-search-option"
                  >
                    <div class="icon">
                      <mat-icon>search</mat-icon>
                    </div>
                    <span>{{ option.term }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <div
          class="alert-box-container"
          *ngIf="searchCriteria != null && fetchedAlerts"
        >
          <ng-container *ngIf="hasAlert(); else noAlert">
            <p>
              <mat-icon> notifications_active </mat-icon>
              You have alerts setup for this search.
              <span class="white-text-button" (click)="addQuickAlert()"
                >Cancel Alert</span
              >
            </p>
          </ng-container>

          <ng-template #noAlert>
            <pla-signed-up-content
              *ngIf="searchCriteria"
              [showButton]="false"
              class="d-block w-100"
              [requesting]="'term alert ' + searchCriteria"
            >
              <div lockedContent>
                <p class="d-block text-center">
                  <mat-icon> notifications_none </mat-icon>
                  <span class="white-text-button" (click)="addQuickAlert()"
                    >Alert Me</span
                  >
                  when new "{{ searchCriteria | uppercase }}" plates are added.
                </p>
              </div>
              <div unlockedContent>
                <p>
                  <mat-icon> notifications_none </mat-icon>
                  <span class="white-text-button" (click)="addQuickAlert()"
                    >Alert Me</span
                  >
                  when new "{{ searchCriteria | uppercase }}" plates are added.
                </p>
              </div>
            </pla-signed-up-content>
          </ng-template>
        </div>
      </div>

      <!-- PERFECT CARD -->
      <div class="pla_card no-card mobile spacing padding">
        <div
          *ngIf="
            !perfectSearching &&
            perfectResults &&
            perfectResults.registrations.length > 0
          "
          class="perfect-container"
        >
          <pla-search-result-table
            [searchTableType]="'perfect'"
            [hideOnEmpty]="true"
            [searching]="perfectSearching"
            [searchTitle]="searchCriteria"
            [searchType]="'perfect'"
            [resultType]="'plate'"
            [pluralResultType]="'plates'"
            [results]="perfectResults"
            [resultChange]="perfectResultChange"
            [showFilter]="false"
            [showSort]="false"
            [topResultSet]="true"
          ></pla-search-result-table>
        </div>

        <!-- NEWLY LISTED CARD -->
        <div
          class="perfect-container"
          *ngIf="
            !superSearching &&
            newlyListedResults &&
            newlyListedResults.registrations.length > 0
          "
        >
          <pla-search-result-table
            [searchTableType]="'newly_listed'"
            [hideOnEmpty]="true"
            [searching]="superSearching"
            [headerText]="'Newly Listed'"
            [results]="newlyListedResults"
            [resultChange]="newlyListedResultChange"
            [showFilter]="false"
            [showSort]="false"
            [topResultSet]="true"
          ></pla-search-result-table>
        </div>

        <!-- MAIN SEARCH CARD -->
        <pla-search-result-table
          [searching]="superSearching"
          [searchTitle]="searchCriteria"
          [searchTableType]="'main'"
          [results]="results"
          [showRatings]="true"
          [resultChange]="resultChange"
          [topResultSet]="false"
        ></pla-search-result-table>
      </div>

      <div
        *ngIf="false"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;
          padding: 10px;
          margin: 20px 0;
          background: #214953e6;
          color: white;
        "
      >
        <p style="margin: 0">Did we help you find what you're looking for?</p>
        <a
          href="https://uk.trustpilot.com/review/platex.co.uk"
          target="_blank"
          rel="noopener"
          class="d-block w-50"
          style="max-width: 250px"
          ><img
            class="d-block w-100 m-auto"
            src="https://cdn.platex.co.uk/images/trustpilot-logo-upd.png?t=12"
        /></a>
      </div>

      <div class="pla_card no-card mobile spacing padding">
        <!-- SYNONYM CARDS -->
        <ng-container *ngFor="let synonymResult of synonymResults">
          <div *ngIf="synonymResults">
            <pla-search-result-table
              [searchTableType]="'synonym'"
              [searching]="false"
              [headerText]="generateSynonymHeaderText(synonymResult)"
              [results]="synonymResult.resp"
              [showFilter]="false"
              [showSort]="false"
              [showRatings]="true"
              [topResultSet]="false"
              [sortOnStart]="false"
            ></pla-search-result-table>
            <!-- <pla-see-more-button
              [term]="synonymResult.synonym"
              [align]="'right'"
              [padding]="true"
            ></pla-see-more-button> -->
          </div>
        </ng-container>
      </div>

      <!-- BYO CARD -->
      <div class="pla_card no-card mobile spacing padding" *ngIf="byoTerm">
        <div>
          <h2 class="byo-title">Build-Your-Own</h2>
        </div>
        <pla-byo-search-page
          [defaultStyle]="'current'"
          [breakdowns]="byoTerm"
          [searchDefault]="true"
          [inlineSearch]="true"
        ></pla-byo-search-page>
      </div>
    </div>
  </div>
</div>
