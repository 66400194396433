<div *ngIf="blank">
  <div
    class="{{ plateSize }} blank plate-container {{ center ? 'center' : '' }} {{
      mobileSmall ? 'mobile-small' : ''
    }} {{ mobileTiny ? 'mobile-tiny' : '' }}"
    [style.width]="useCustom ? customSize + 'ch' : auto"
    [style.fontSize]="useCustom ? customSize + 'px' : auto"
    style="height: 0px !important"
  >
    <div
      class="{{ customClass }} {{ borderClass }} {{ sizeClass }} {{
        fontClass
      }} {{ flagClass }} plate {{ noShadow ? 'no-shadow' : '' }} {{
        white ? 'white' : ''
      }}"
    ></div>
  </div>
</div>
<div *ngIf="!blank">
  <div
    *ngIf="!loading; else skeleton"
    [ngClass]="{ 'inline-text': inlineText }"
    (contextmenu)="contextMenu($event)"
  >
    <ng-container *ngIf="rawPlate == null; else showRawPlate">
      <div
        class="{{ plateSize }} position-relative plate-container {{
          center ? 'center' : ''
        }} {{ mobileSmall ? 'mobile-small' : '' }} {{
          mobileTiny ? 'mobile-tiny' : ''
        }}"
        [style.width]="useCustom ? customSize + 'ch' : auto"
        [style.fontSize]="useCustom ? customSize + 'px' : auto"
        (click)="plateDetail()"
        *ngIf="plate"
      >
        <div
          *ngIf="plate.plateOwner == -1 && showSoldRibbon"
          class="position-absolute overflow-hidden w-100 h-100"
          style="pointer-events: none"
        >
          <div class="bg-danger text-white position-absolute sold-ribbon">
            <small class="sold-small">SOLD</small>
          </div>
        </div>

        <div
          class="{{ customClass }} {{ borderClass }} {{ sizeClass }} {{
            fontClass
          }} {{ flagClass }} plate {{ noShadow ? 'no-shadow' : '' }} {{
            white ? 'white' : ''
          }}"
          [style.margin]="noMargin ? '0px auto' : '10px auto'"
          [class.faded-plate]="disabled"
          [class.on-price-hover]="changeHover"
        >
          <p
            *ngIf="plate.formattedRegistration"
            [style.fontSize]="useCustom ? customFontSize + 'px' : auto"
            class="plate-font plate-text"
          >
            {{ termFirst ? hoverText : plate.formattedRegistration }}
          </p>
          <p
            *ngIf="!plate.formattedRegistration"
            [style.fontSize]="useCustom ? customFontSize + 'px' : auto"
            class="plate-font plate-text"
          >
            {{ termFirst ? hoverText : plate.registration }}
          </p>

          <p
            *ngIf="changeHover"
            class="plate-font inclusive-plate-font price-hover mb-0"
          >
            {{ termFirst ? plate.formattedRegistration : hoverText }}
          </p>
        </div>
        <p *ngIf="showPercentage">{{ plate.percentage }}</p>
        <p
          *ngIf="showPrice"
          data-test="plate-price"
          class="plate-price mb-0 {{ priceClass }} d-flex align-items-center {{
            showIndex ? 'justify-content-between' : 'justify-content-center'
          }}"
          [style.fontSize]="
            priceFontSize !== undefined ? priceFontSize + 'px' : auto
          "
        >
          <span class="text-start me-1" *ngIf="showIndex">{{
            secondaryText
          }}</span>
          <span>
            <ng-container *ngIf="plate.priceBreakdown; else rawPrice">
              <ng-container *ngIf="shouldShowPence(); else noPence">{{
                plate.priceBreakdown.total | currency : "GBP"
              }}</ng-container>
            </ng-container>
            <ng-template #noPence>
              {{
                plate.priceBreakdown.total
                  | currency : "GBP" : "symbol" : "1.0-0"
              }}
            </ng-template>
            <ng-template #rawPrice>
              <ng-container *ngIf="shouldShowPence(); else raw_noPence">{{
                plate.price / 100 | currency : "GBP"
              }}</ng-container>
              <ng-template #raw_noPence>
                {{ plate.price / 100 | currency : "GBP" : "symbol" : "1.0-0" }}
              </ng-template>
            </ng-template>
          </span>
        </p>
      </div>
    </ng-container>
    <ng-template #showRawPlate>
      <ng-container *ngIf="rawPlate != ''">
        <div
          class="{{ plateSize }} plate-container {{ center ? 'center' : '' }} {{
            mobileSmall ? 'mobile-small' : ''
          }} {{ mobileTiny ? 'mobile-tiny' : '' }}"
          [style.width]="useCustom ? customSize + 'ch' : auto"
          [style.fontSize]="useCustom ? customFontSize + 'px' : auto"
          (click)="plateDetail()"
        >
          <div
            class="{{ borderClass }} {{ sizeClass }} {{ fontClass }} {{
              customClass
            }} {{ flagClass }} plate {{ noShadow ? 'no-shadow' : '' }} {{
              white ? 'white' : ''
            }}"
            [style.margin]="noMargin ? '0px auto' : '10px auto'"
            [class.faded-plate]="disabled"
          >
            <p
              class="plate-font"
              [style.fontSize]="useCustom ? customFontSize + 'px' : auto"
            >
              {{ rawPlate }}
            </p>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>

<ng-template #skeleton>
  <div class="skeleton">
    <div
      class="plate-size skeleton-item {{ plateSize }} plate-container {{
        mobileSmall ? 'mobile-small' : ''
      }} {{ mobileTiny ? 'mobile-tiny' : '' }}"
      [style.margin]="noMargin ? '0px auto' : '10px auto'"
    ></div>
  </div>
</ng-template>
