import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pla-platex-direct',
  templateUrl: './platex-direct.component.html',
  styleUrls: ['./platex-direct.component.scss'],
})
export class PlatexDirectComponent implements OnInit {
  @Input('align') public align: 'left' | 'center' | 'right' = 'center';
  @Input('size') public size: 'small' | 'regular' | 'large' = 'regular';
  @Input('direct') public direct: boolean = true;
  @Input('platePortal') public platePortal: boolean = false;
  @Input('colour') public colour: 'default' | 'white' = 'default';

  constructor() {}

  ngOnInit(): void {}
}
