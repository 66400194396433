import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SessionService } from '../services/session-service';
import { catchError, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService,
    private snackbar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private http: HttpClient
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.AuthRequest(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          if (this.sessionService.isLoggedIn()) {
            return this.sessionService.RefreshToken().pipe(
              switchMap(() => {
                request = this.AuthRequest(request);
                return next.handle(request);
              })
            );
          } else {
            return next.handle(request);
          }
        } else if (error.status == 404) {
          // this could be anything
          return next.handle(request);
        }

        return throwError(error);
      })
    );
  }

  private AuthRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (this.sessionService.isLoggedIn()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.sessionService.getToken()}`,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          'Session-ID': localStorage.getItem('local_session'),
        },
      });
    }
    return request;
  }

  private HandleUnauth(): void {
    this.snackbar
      .open(
        'You need to be logged in to complete this action. Please login and try again',
        'Close',
        {
          duration: 3000,
        }
      )
      .afterDismissed()
      .subscribe(() => {
        this.sessionService.setSuccessRoute(this.router.url);
        this.router.navigate(['/login']);
      });
  }
}
