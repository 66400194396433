<div class="page">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container" id="results-section">
    <div class="center-content-container">
      <!-- SEARCH CARD + BANNER -->
      <div class="pla_card no-card no-left no-right mobile spacing no-top">
        <pla-banner-image [taller]="true" [carBanner]="'BMW_M2'">
          <div class="page-title">
            <h1>SMART SEARCH</h1>
            <span class="sub">The UKs leading search</span>
          </div>
        </pla-banner-image>
        <div class="search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <pla-option-search
                [optionsEnabled]="false"
                [endpoint]="'search-results'"
                [searchCriteria]="''"
                [plateStyle]="true"
              ></pla-option-search>
              <span class="header">Search a word or registration</span>
            </div>
          </div>
        </div>
      </div>

      <div class="pla_card no-card mobile spacing padding">
        <div class="px-4">
          <h2 class="border-bottom mb-1">The Smarter Search</h2>
          <div class="text-section">
            <div class="text-paragraph">
              <p class="indent">
                Our most comprehensive search powered by our proprietary
                algorithm “PLATO”. In just a few seconds PLATO can review,
                retrieve and rank tailored results from over 68 million
                registrations offered for sale across the market including
                dealers, private owners and the DVLA.
              </p>
            </div>
            <div class="text-paragraph mt-4">
              <p class="indent">
                When possible PLATO will show you the Perfect Plate for your
                searched word. These rare plates are a direct match with no
                additional characters and where any numerical letters are highly
                recognisable, such as the plate J4 MES for James. You can see
                the more Perfect Plates with our unique
                <a href="/plateopedia">Platopedeia</a> feature.
              </p>
            </div>
          </div>

          <div class="text-title heading mt-3">
            <h2 class="border-bottom mb-1">
              <b class="strong">Plate Info</b>
            </h2>
          </div>
          <div class="text-section">
            <div class="text-paragraph">
              <p class="indent">
                By selecting the
                <pla-plate
                  class="d-inline-block me-1"
                  [rawPlate]="'PLA 7E'"
                  [openModal]="false"
                  [useCustom]="true"
                  [customSize]="9"
                  [customFontSize]="17"
                  [inlineText]="true"
                ></pla-plate>
                or
                <button
                  mat-raised-button
                  class="ms-1 cyan-button d-inline-block"
                  style="height: 28px"
                >
                  <div
                    style="
                      color: white !important;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <span style="color: white; line-height: 12px" class="inline"
                      >View</span
                    >
                    <mat-icon class="small">arrow_right</mat-icon>
                  </div>
                </button>
                button you can access more detailed information about the
                registration including the original date of release, the issue
                area, sale price history you can also compare prices to find the
                best deal for that registration.
              </p>
            </div>
          </div>

          <div class="text-title heading mt-3">
            <h2 class="border-bottom mb-1">
              <b class="strong">Alerts</b>
            </h2>
          </div>
          <div class="text-section">
            <div class="text-paragraph">
              <p class="indent">
                After each new search PLATO will offer you the chance to setup
                an '🔔 Alert'. Once activated, we will send you a message when a
                matching registration comes to the market giving you the best
                chance to secure it!
              </p>
            </div>
          </div>

          <div class="text-title heading mt-3">
            <h2 class="border-bottom mb-1">
              <b class="strong">Help Make Plato Even Better</b>
            </h2>
          </div>
          <div class="text-section">
            <div class="text-paragraph">
              <p class="indent">
                PLATO is an autonomous algorithm which continues to learn and
                adapt with every search it undertakes. However you can also help
                PLATO learn to improve the quality of ranking by using vote Up
                or Down icons beneath each result. If you think a registration
                should feature higher up the ranking simpliy click the 👍 and if
                lower the thumbs down 👎. If you have any other comments or
                suggestion how PLATO could be even better we’d love to hear from
                you via the Feedback tab.
              </p>
            </div>
          </div>
        </div>
        <!-- FEATURED LISTING -->
        <div class="featured-container mt-5">
          <div class="px-4">
            <h2 class="border-bottom mb-1">
              <b class="strong">Featured</b>
            </h2>
          </div>
          <div>
            <pla-featured-plates [showTitle]="false"></pla-featured-plates>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
