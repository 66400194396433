<!-- <div class="modal-content-container">
  <h2 class="modal-header" mat-dialog-title>Plate Details</h2>
  <mat-dialog-content class="mat-typography modal-content">
    <pla-plate
      [plate]="plate"
      [openModal]="false"
      [showPrice]="true"
    ></pla-plate>
    <p class="plate-information" *ngIf="plateDetail && plate">
      {{ plate.formattedRegistration }} is a {{ plateDetail.style }} Style
      registration first issued to the
      <strong>{{ plateDetail.issueRegion }}</strong> registration office. The
      marque can be added to vehicles registered from
      <strong>{{ plateDetail.issueMonth }} {{ plateDetail.issueYear }}</strong>
      onwards or held on the retention certificate for investment or until
      mounted on a suitable vehicle.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="warn" mat-button (click)="openPlateCompare()">
      VIEW COMPARE SCORE
    </button>
    <button mat-button mat-dialog-close color="warn">Close</button>
    <button
      mat-raised-button
      mat-dialog-close
      (click)="openPlateDetailPage()"
      cdkFocusInitial
      color="accent"
      style="font-weight: bold"
    >
      Continue
    </button>
  </mat-dialog-actions>
</div> -->

<div class="modal-content-container">
  <div class="pla_card-header">
    <h3>Registration Details</h3>
  </div>
  <div class="plate-container">
    <pla-plate
      [plate]="plate"
      [openModal]="false"
      [showPrice]="true"
    ></pla-plate>
  </div>
  <div class="stats-container" *ngIf="plateDetail">
    <div class="stat">
      <div class="flex-space"></div>
      <div class="stat-detail stat-key">STYLE</div>
      <div class="stat-detail stat-value">{{ plateDetail.style }}</div>
    </div>
    <div class="stat">
      <div class="flex-space"></div>
      <div class="stat-detail stat-key">ISSUE MONTH</div>
      <div class="stat-detail stat-value">{{ plateDetail.issueMonth }}</div>
    </div>
    <div class="stat">
      <div class="flex-space"></div>
      <div class="stat-detail stat-key">ISSUE YEAR</div>
      <div class="stat-detail stat-value">{{ plateDetail.issueYear }}</div>
    </div>
    <div class="stat">
      <div class="flex-space"></div>
      <div class="stat-detail stat-key">ISSUE REGION</div>
      <div class="stat-detail stat-value">{{ plateDetail.issueRegion }}</div>
    </div>
  </div>
  <div class="action-buttons">
    <button color="warn" mat-raised-button (click)="openPlateCompare()">
      VIEW BREAKDOWN
    </button>
    <div class="flex-space"></div>
    <button mat-button mat-dialog-close color="black">Close</button>
    <button
      mat-raised-button
      mat-dialog-close
      (click)="openPlateDetailPage()"
      cdkFocusInitial
      color="accent"
      style="font-weight: bold; color: #333333"
    >
      Continue
    </button>
  </div>
</div>
