<div *ngIf="paymentDetails && paymentDetails.priceBreakdown">
  <h1 *ngIf="showTitle">Price Breakdown</h1>
  <div>
    <div class="info-item">
      <div class="header">
        <span>REGISTRATION PRICE</span>
      </div>
      <div class="value">
        {{ paymentDetails.priceBreakdown.registrationPrice | currency: "GBP" }}
      </div>
    </div>

    <div class="info-item">
      <div class="header">
        <span>VAT @ 20%</span>
      </div>
      <div class="value">
        {{ paymentDetails.priceBreakdown.vat | currency: "GBP" }}
      </div>
    </div>

    <ng-container
      *ngFor="let addedFee of paymentDetails.priceBreakdown.addedFees"
    >
      <div class="info-item" *ngIf="addedFee.price > 0 || showFree">
        <div class="header">
          <span>{{ addedFee.viewName }}</span>
        </div>
        <div class="value">
          <ng-container *ngIf="addedFee.price > 0">{{
            addedFee.price / 100 | currency: "GBP"
          }}</ng-container>
          <ng-container *ngIf="addedFee.price <= 0">Free</ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showExtras">
      <ng-container *ngFor="let optExtra of paymentDetails.optionalExtras">
        <div
          class="info-item"
          *ngIf="optExtra.selected && (optExtra.price_minor > 0 || showFree)"
        >
          <div class="header">
            <span>{{ optExtra.title }}</span>
          </div>
          <div class="value">
            {{ optExtra.price_minor / 100 | currency: "GBP" }}
          </div>
        </div>
      </ng-container>
    </ng-container>

    <div class="price-total">
      <div>
        <span
          >Total:
          <ng-container *ngIf="showExtras">{{
            paymentDetails.priceBreakdown.totalWithExtras | currency: "GBP"
          }}</ng-container>
          <ng-container *ngIf="!showExtras">{{
            paymentDetails.priceBreakdown.total | currency: "GBP"
          }}</ng-container>
        </span>
      </div>
    </div>
  </div>
</div>
