<div
  *ngIf="conversations && conversations.length > 0; else noConversations"
  class="component-container"
>
  <ng-container *ngFor="let conversation of conversations">
    <div
      class="conversation-row"
      [ngClass]="{
        selected: isSelected(conversation)
      }"
      [attr.contextId]="conversation.contextId"
      *ngIf="conversation && conversation.lastMessage"
      (click)="handleConversationClickEvent(conversation)"
    >
      <div class="header">
        <h2 class="title">{{ conversation.name }}</h2>
        <div
          class="status-dot"
          [ngClass]="{
            unread:
              !conversation.lastMessage.userSent &&
              !conversation.lastMessage.seen
          }"
        ></div>
      </div>
      <div>
        <p class="preview">
          <span *ngIf="conversation.lastMessage.userSent" class="starter"
            >You:</span
          ><span [innerHtml]="conversation.lastMessage.message"></span>
        </p>
        <p class="timestamp">
          <span>{{ conversation.lastMessage.timeStamp | date : "short" }}</span>
        </p>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #noConversations>
  <div class="centered-container">
    <h2 style="margin: 0">No conversations.</h2>
  </div>
</ng-template>
