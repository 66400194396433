<!-- SEARCH AREA -->
<!-- <div class="search-input-container">
  <div class="search-area inner">
    <div class="box-container"> -->

<div class="host-container">
  <div class="component-container">
    <div>
      <ng-content></ng-content>
    </div>

    <div class="bg-comp">
      <!-- SEARCH OPTIONS AREA -->
      <div>
        <pla-switch-toggle
          [selectedTabId]="searchType"
          [tabs]="searchTabs"
          (switchChange)="tabClickHandler($event)"
        ></pla-switch-toggle>
      </div>

      <!-- SEARCH AREA -->
      <div class="search-input-container">
        <div class="search-area inner">
          <div class="box-container">
            <ng-container [ngSwitch]="searchType">
              <!-- INITIALS -->
              <ng-container *ngSwitchCase="'INITIALS'">
                <ng-container *ngTemplateOutlet="initialsSearch"></ng-container>
                <span class="header p-1 rounded">Search up to 3 initials</span>
              </ng-container>

              <!-- BYO -->
              <ng-container *ngSwitchCase="'BYO'">
                <ng-container *ngTemplateOutlet="byoSearch"></ng-container>
              </ng-container>

              <!-- SUPER -->
              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="superSearch"></ng-container>
                <span
                  class="header p-1 rounded"
                  *ngIf="header && header != ''"
                  >{{ header }}</span
                >
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #superSearch>
  <pla-option-search
    [endpoint]="'search-results'"
    [optionsEnabled]="false"
    [plateStyle]="true"
  ></pla-option-search>
</ng-template>

<ng-template #initialsSearch>
  <pla-wildcard-character-select
    [boxCount]="3"
    [showMessage]="false"
    [letters]="''"
    [style]="'any_text'"
    [withPadding]="false"
    [loading]="false"
    (inlineSearch)="initialsSearchHandler($event)"
  ></pla-wildcard-character-select>
</ng-template>
<ng-template #byoSearch>
  <pla-byo-search-input
    [padLeft]="false"
    [withPadding]="false"
    (searchOutput)="byoSearchHandler($event)"
  ></pla-byo-search-input>
</ng-template>
