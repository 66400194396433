<div class="page">
  <div class="page-description-container"></div>
  <div class="content-container">
    <div class="center-content-container">
      <div class="pla_card mobile no-card">
        <pla-home-page-search>
          <div class="mt-3 mb-4 mobile-only">
            <div class="mx-auto" style="max-width: 350px">
              <p
                class="leading-text text-white text-center mb-0 fs-4 fw-normal"
              >
                Search More Registrations<br />See Better Results<br />Compare
                Prices
              </p>
            </div>
          </div>

          <div class="mt-3 mb-4 desktop-only" style="padding: 0 10px">
            <div
              class="mx-auto d-block px-1 py-2 rounded"
              style="background: rgba(0, 0, 0, 0.5)"
            >
              <p
                class="leading-text text-white text-center mb-0 fs-4 fw-normal"
              >
                Search More Registrations. See Better Results. Compare Prices
              </p>
            </div>
          </div>
        </pla-home-page-search>
        <pla-banner-image
          [taller]="true"
          [carBanner]="'BUGATTI_STREET'"
          [mobileCarBanner]="'BUGATTI_TRACK'"
          [customAR]="'16/9'"
          [customMobileAR]="'3/5'"
        >
          <div class="banner-content-container">
            <div class="banner-title">
              <!-- PLATE COUNT -->
              <div class="page-banner-title bottom middle text-white">
                <div
                  class="mb-4 mb-md-1 desktop-only py-2 px-1 rounded"
                  style="background: rgba(0, 0, 0, 0.5)"
                >
                  <div>
                    <div
                      class="mx-auto border-bottom border-3 mb-1 border-white w-content"
                    >
                      <h3 class="fs-3"><b>THE PLATE PORTAL</b></h3>
                    </div>
                  </div>
                  <span class="fs-6"
                    >{{ plateCount | number }} Plates available</span
                  >
                </div>

                <div class="mb-4 mb-md-1 mobile-only">
                  <div>
                    <div
                      class="mx-auto border-bottom border-3 mb-1 border-white w-content"
                    >
                      <h3 class="fs-3"><b>THE PLATE PORTAL</b></h3>
                    </div>
                  </div>
                  <span class="fs-6"
                    >{{ plateCount | number }} Plates available</span
                  >
                </div>
              </div>
            </div>
          </div>
        </pla-banner-image>
      </div>

      <!-- FEATURED LISTING -->
      <div class="d-block d-md-none">
        <pla-featured-plates
          [featureType]="[meFeatured]"
          [plateSize]="'x-small'"
          [sortBy]="'price'"
        ></pla-featured-plates>
      </div>

      <div
        class="p-2 bg-platex-light"
        style="
          margin-top: 2px;
          background: linear-gradient(to bottom, #102329, #327b8d);
        "
      >
        <h2 class="fs-2 mb-0 text-center text-white">SPECIALISED SEARCHES</h2>
      </div>
      <!-- ICON ROW 1  -->
      <div class="icon-row" style="margin-top: 2px">
        <a class="icon-image grow" [routerLink]="'/initials-search'">
          <img
            src="https://cdn.platex.co.uk/landing/L5_LAMBORGHINI_DIABLO_SMALL.png"
          />
          <div class="floating">
            <div class="inner">
              <div class="swapable">
                <img
                  class="icon"
                  src="../../../assets/landing/Initials Icon.png"
                />
                <span class="detail"
                  >Try this search to find plates that will best showcase your
                  initials with minimum distractions.</span
                >
              </div>
              <h2 class="title fs-3">Initials</h2>
            </div>
          </div>
        </a>

        <a class="icon-image" [routerLink]="'/dateless-search'">
          <img
            src="https://cdn.platex.co.uk/landing/8UYS_FERRARI_275_SMALL.png"
          />
          <div class="floating">
            <div class="inner">
              <div class="swapable">
                <img
                  class="icon"
                  src="../../../assets/landing/Dateless Icon.png"
                />
                <span class="detail"
                  >Dateless Registrations grouped by length. Find your perfect
                  1/1 or 2/2 plate.
                </span>
              </div>
              <h2 class="title fs-3">Dateless</h2>
            </div>
          </div>
        </a>

        <a class="icon-image" [routerLink]="'/byo-search'">
          <img
            src="https://cdn.platex.co.uk/landing/DE51GNS_RANGEROVER_DEFENDER_SMALL.png"
          />
          <div class="floating">
            <div class="inner">
              <div class="swapable">
                <img class="icon" src="../../../assets/landing/BYO Icon.png" />
                <span class="detail"
                  >Design your own personalised registration - millions of
                  combinations available starting from just £250!
                </span>
              </div>
              <h2 class="title fs-3">Build-Your-Own</h2>
            </div>
          </div>
        </a>
      </div>

      <!-- FEATURED LISTING -->
      <div class="pla_card no-card mobile spacing padding">
        <div class="featured-container">
          <div class="px-4">
            <h2 class="border-bottom mb-1">
              <b class="strong">Featured</b>
            </h2>
          </div>
          <div>
            <pla-featured-plates [showTitle]="false"></pla-featured-plates>
          </div>
        </div>
      </div>

      <!-- ICON ROW 2 -->
      <div class="icon-row" style="margin-bottom: 2px">
        <a class="icon-image grow" [routerLink]="'/compare'">
          <img
            src="https://cdn.platex.co.uk/landing/PAY135S_PORSCHE_TAYCAN_SMALL.png"
          />
          <div class="floating">
            <div class="inner">
              <div class="swapable">
                <img
                  class="icon"
                  src="../../../assets/landing/Compare Icon.png"
                />
                <span class="detail"
                  >Know the registration you want then compare prices to find
                  the best deal!</span
                >
              </div>
              <h2 class="title fs-3">Compare</h2>
            </div>
          </div>
        </a>

        <a class="icon-image" [routerLink]="'/account/notify-me'">
          <img
            src="https://cdn.platex.co.uk/landing/NO71FYU_AUDI_RS3_SMALL.png"
          />
          <div class="floating">
            <div class="inner">
              <div class="swapable">
                <img
                  class="icon"
                  src="../../../assets/landing/Notify Icon.png"
                />
                <span class="detail"
                  >Whether you want to be reuinted with a past registration or
                  locate that perfect number choose Notify.</span
                >
              </div>
              <h2 class="title fs-3">Notify</h2>
            </div>
          </div>
        </a>

        <a class="icon-image" [routerLink]="'/plateopedia'">
          <img
            src="https://cdn.platex.co.uk/landing/HO72ODS_MINI_JCW_SMALL.png"
          />
          <div class="floating">
            <div class="inner">
              <div class="swapable">
                <img
                  class="icon"
                  src="../../../assets/landing/Plateopedia Icon.png"
                />
                <span class="detail"
                  >We've searched deep and found all the unissued complete word
                  plates!</span
                >
              </div>
              <h2 class="title fs-3">Plateopedia</h2>
            </div>
          </div>
        </a>
      </div>

      <!-- SELL -->

      <div class="d-none pill-container">
        <div class="pill-section">
          <div class="image-section">
            <div class="image-text">
              <p class="title fs-3">SELL</p>
              <p class="sub-title">2 Week Free Trial</p>
            </div>
            <img src="https://cdn.platex.co.uk/landing/MINI_LANDING.png" />
          </div>
          <div class="text-section">
            <div class="flex-space"></div>
            <h3>Sell With PlateX</h3>
            <ul>
              <li>We are the UKs dedicated Personal Number Plate Portal.</li>
              <li>
                Our advanced search ensures your registration is seen by the
                right buyers more often.
              </li>
              <li>Commision Free - Keep 100% of the selling price.</li>
              <li>
                List from only £7.50 month / £19.50 for 3 months / £69.50 year.
              </li>
            </ul>
            <div class="flex-space"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="side-container bg-light">
      <div class="">
        <pla-featured-plates
          [featureType]="[meFeatured]"
          [plateSize]="'featured'"
          [priceFontSize]="12"
          [maxCount]="14"
          [sortBy]="'price'"
          [plateClass]="'flat'"
        ></pla-featured-plates>
      </div>

      <div class="border-top">
        <pla-featured-plates
          [featureType]="raFeatured"
          [plateSize]="'featured'"
          [priceFontSize]="12"
          [maxCount]="14"
          [plateClass]="'flat'"
        ></pla-featured-plates>
      </div>

      <div class="border-top">
        <pla-featured-plates
          [featureType]="rsFeatured"
          [plateSize]="'featured'"
          [priceFontSize]="12"
          [priceClass]="'text-muted fst-italic'"
          [maxCount]="14"
          [plateClass]="'flat'"
        ></pla-featured-plates>
      </div>
    </div>
  </div>
</div>
