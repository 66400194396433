import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPCONSTANTS } from '../http/http-constants';
import { LoginAuthenticationResponse } from '../models/loginAuthenticationResponse';
import { RegisterAuthenticationResponse } from '../models/registerAuthenticationResponse';
import { LoginModel } from '../models/loginModel';
import { RegisterModel } from '../models/registerModel';
import { tap } from 'rxjs/operators';
import { uuid4 } from '@sentry/utils';

export enum UserType {
  Admin = 0,
  Standard = 1,
  Dealer = 2,
}

export enum SessionState {
  LOGGEDIN = 0,
  EXPIRED = 1,
  EMPTY = 2,
}

export class Session {
  public state: SessionState = SessionState.EMPTY;
  public token: string;
  public expiry: Date;
  public type: number;
}

class QueudRequest {
  constructor(public url: string, public callback: (data: any) => void) {}
}

@Injectable({ providedIn: 'root' })
export class SessionService {
  private currentSession: Session;
  private localSession: string;

  private requestQueue: QueudRequest[] = [];
  public requestingAccess: boolean = false;
  public sessionStateChange: EventEmitter<void> = new EventEmitter();

  constructor(private http: HttpClient) {}

  public Start(): void {
    this.IdentifyUser();
  }

  private IdentifyUser(): void {
    if (this.isLoggedIn()) {
      this.http
        .get<any>(
          `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/authenticate/identify`
        )
        .subscribe(() => {});
    } else {
      var localSessionId = localStorage.getItem('local_session');
      if (localSessionId == null) {
        localSessionId = uuid4();
        localStorage.setItem('local_session', localSessionId);
      }
    }
  }

  public setSuccessRoute(route: string) {
    localStorage.setItem('loginSuccessRoute', route);
  }

  public getSuccessRoute(): string {
    const successRoute = localStorage.getItem('loginSuccessRoute');
    localStorage.removeItem('loginSuccessRoute');
    if (successRoute == null) return '/account';
    if (successRoute == '/login') return '/account';
    return successRoute;
  }

  public isLoggedIn(): boolean {
    this.validState();
    return this.currentSession.state == SessionState.LOGGEDIN;
  }

  public ClearQueue(): void {
    this.requestQueue = [];
  }

  public AddToQueue(url: string, callback: (data: any) => void): void {
    var queueItem = new QueudRequest(url, callback);
    this.requestQueue.push(queueItem);
  }

  public EnterQueue(callback: () => void): void {
    var request: QueudRequest = this.requestQueue.pop();
    this.RemakeRequestAfterAuth(request, callback);
  }

  private RemakeRequestAfterAuth(
    queueItem: QueudRequest,
    callback: () => void
  ): void {
    if (queueItem == undefined || queueItem == null) {
      this.QueueCleared(callback);
      return;
    }

    this.http.get(queueItem.url).subscribe((_: any) => {
      queueItem.callback(_);

      _ = this.requestQueue.pop();
      if (_ != undefined) this.RemakeRequestAfterAuth(_, callback);
      else this.QueueCleared(callback);
    });
  }

  private QueueCleared(callback: () => void): void {
    this.sessionStateChange.emit();
    callback();
  }

  // ONLY USED LOCALLY, API HANDLES AUTH
  public isAdmin(): boolean {
    if (!this.isLoggedIn()) return false;
    return this.currentSession.type == UserType.Admin;
  }

  public invalidateSession(): void {
    this.validState();
    this.currentSession.state = SessionState.EXPIRED;
    this.saveSession(this.currentSession);
  }

  public RefreshToken(): Observable<any> {
    return this.makeRefreshRequest().pipe(
      tap((resp: LoginAuthenticationResponse) => {
        var session = this.createNewSession(resp);
        this.saveSession(session);
      })
    );
  }

  public login(
    loginModel: LoginModel,
    loginCallback: (resp: boolean, message?: string) => void
  ): void {
    this.makeLoginRequest(loginModel).subscribe(
      (resp: LoginAuthenticationResponse) => {
        loginCallback(true);
        var session = this.createNewSession(resp);
        this.IdentifyUser();
        this.saveSession(session);
      },
      (error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            if (error.error.toLowerCase().startsWith('invalid credentials')) {
              loginCallback(false, HTTPCONSTANTS.incorrectLogin);
            } else {
              loginCallback(false, error.error);
            }
            break;

          default:
            loginCallback(false, HTTPCONSTANTS.serverError);
            break;
        }
      }
    );
  }

  public register(
    registerModel: RegisterModel,
    registerCallback: (
      resp: boolean,
      message?: string,
      errors?: string[]
    ) => void
  ): void {
    this.makeRegisterRequest(registerModel).subscribe(
      (resp: RegisterAuthenticationResponse) => {
        registerCallback(true);
        this.IdentifyUser();
      },
      (httpError: HttpErrorResponse) => {
        const error: RegisterAuthenticationResponse = httpError.error;
        registerCallback(false, error.message, error.errors);
      }
    );
  }

  public signOut(callback: () => void): void {
    localStorage.removeItem('pla-session');
    callback();
  }

  public registerToLogin(registerModel: RegisterModel): LoginModel {
    const loginModel = new LoginModel();
    loginModel.email = registerModel.email;
    loginModel.password = registerModel.password;
    return loginModel;
  }

  public getToken(): string {
    return this.isLoggedIn() ? this.currentSession.token : '';
  }

  private validState(): void {
    this.getCurrentSession();
  }

  private getCurrentSession(): void {
    this.currentSession = this.retrieveSession();
  }

  private createNewSession(
    loginResponse: LoginAuthenticationResponse
  ): Session {
    var newSession = new Session();
    newSession.token = loginResponse.token;
    newSession.expiry = new Date(loginResponse.expiration);
    newSession.state = SessionState.LOGGEDIN;
    newSession.type = loginResponse.type;
    return newSession;
  }

  private saveSession(session: Session): void {
    var string_session = JSON.stringify(session);
    localStorage.setItem('pla-session', string_session);
    this.currentSession = session;
  }

  private retrieveSession(): Session {
    var string_session = localStorage.getItem('pla-session');
    if (string_session != null) {
      var session = JSON.parse(string_session) as Session;
      return session;
    }
    return new Session();
  }

  // Http

  private makeLoginRequest(
    loginModel: LoginModel
  ): Observable<LoginAuthenticationResponse> {
    return this.http.post<LoginAuthenticationResponse>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/authenticate/login`,
      loginModel
    );
  }

  private makeRegisterRequest(
    registerModel: RegisterModel
  ): Observable<RegisterAuthenticationResponse> {
    return this.http.post<RegisterAuthenticationResponse>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/authenticate/register`,
      registerModel
    );
  }

  private makeRefreshRequest(): Observable<LoginAuthenticationResponse> {
    return this.http.get<LoginAuthenticationResponse>(
      `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/authenticate/token`
    );
  }
}
