import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pla-billing-listing-page',
  templateUrl: './billing-listing-page.component.html',
  styleUrls: ['./billing-listing-page.component.scss']
})
export class BillingListingPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
