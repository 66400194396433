import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { OptionalExtras } from 'src/app/models/optionalExtras';
import { PlatePaymentDetails } from 'src/app/models/platePaymentDetails';

import { PaymentMethod } from 'src/app/models/payment/paymentMethod';
import { IProduct } from 'src/app/models/payment/IProduct';
import { UserDetails } from 'src/app/models/userDetails';
import { UserService } from 'src/app/services/user-service';

export var Klarna = window['Klarna'] || {};

@Component({
  selector: 'pla-plate-checkout-steps',
  templateUrl: './plate-checkout-steps.component.html',
  styleUrls: ['./plate-checkout-steps.component.scss'],
})
export class PlateCheckoutStepsComponent implements OnInit {
  public userDetails: UserDetails;
  @Input() public continueCheckout: boolean = false;
  @Input() public paymentDetails: PlatePaymentDetails;
  public paymentOptions: PaymentMethod[] = [];
  public optionsChanged: EventEmitter<IProduct[]> = new EventEmitter<
    IProduct[]
  >();

  constructor(private userService: UserService) {
    this.userService.getUserDetails((u: UserDetails) => {
      this.userDetails = u;
    });
  }

  ngOnInit(): void {
    Klarna = window['Klarna'] || {};

    this.paymentDetails.optionalExtras.map((e: OptionalExtras) => {
      e.description = e.description.replace(
        '|*registration*|',
        this.paymentDetails.formattedRegistration
      );
      return e;
    });
  }

  public optionChange(event: MatCheckboxChange, option: OptionalExtras): void {
    this.paymentDetails.priceBreakdown.totalWithExtras = this.optionalTotal();
    this.optionsChanged.emit(this.getProducts());
  }

  public optionalTotal(): number {
    var optionPrices = 0;
    this.paymentDetails.optionalExtras.forEach((e: OptionalExtras) => {
      if (e.selected) optionPrices += e.price_minor;
    });
    var total = this.paymentDetails.priceBreakdown.total + optionPrices / 100;
    return total;
  }

  public getProducts(): IProduct[] {
    // Add base registration
    var products: IProduct[] = [
      {
        name: this.paymentDetails.registration,
        type: 'registration',
        price: this.paymentDetails.priceBreakdown.total,
        quantity: 1,
      },
    ];

    // Add optional extras
    this.paymentDetails.optionalExtras.forEach((e: OptionalExtras) => {
      if (e.selected)
        products.push({
          name: e.name,
          type: 'extra',
          price: e.price_minor / 100,
          quantity: 1,
        });
    });

    return products;
  }
}
