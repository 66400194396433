import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BYOTermBreakdown } from 'src/app/models/byoTermResponse';
import { SwitchTab } from '../switch-toggle/switch-toggle.component';

export enum SearchType {
  SUPERSEARCH = 'SUPERSEARCH',
  INITIALS = 'INITIALS',
  BYO = 'BYO',
}

class SearchTypeMapper {
  public static toEnum(_: string): SearchType {
    if (_ == SearchType.SUPERSEARCH) return SearchType.SUPERSEARCH;
    if (_ == SearchType.INITIALS) return SearchType.INITIALS;
    if (_ == SearchType.BYO) return SearchType.BYO;
  }
}

@Component({
  selector: 'pla-home-page-search',
  templateUrl: './home-page-search.component.html',
  styleUrls: ['./home-page-search.component.scss'],
})
export class HomePageSearchComponent implements OnInit {
  @Input() public header: string = '';
  @Input() public subHeading: string = '';
  @Input() public searchTabs: SwitchTab[] = [
    new SwitchTab('Smart-Search', SearchType.SUPERSEARCH),
    new SwitchTab('Initials', SearchType.INITIALS),
    new SwitchTab('Build-Your-Own', SearchType.BYO),
  ];

  public searchType: SearchType = SearchType.SUPERSEARCH;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public tabClickHandler(tab: SwitchTab): void {
    if (tab.id == this.searchType) return;
    this.searchType = SearchTypeMapper.toEnum(tab.id);
  }

  public initialsSearchHandler(event: BYOTermBreakdown): void {
    this.router.navigate([`/initials-search/${event.letters.toUpperCase()}`]);
  }

  public byoSearchHandler(event: BYOTermBreakdown): void {
    this.router.navigate([
      `/byo-search/${event.style}/${event.prefix}/${event.numbers}/${event.letters}`,
    ]);
  }
}
