import { EventEmitter, Injectable } from '@angular/core';
import { UserConversation } from '../models/userConversation';
import { MessagingService } from './messaging-service';
import { SessionService } from './session-service';

export class NotificationEvent {
  constructor(
    public notificationCount: number,
    public notificationTitle: string
  ) {}
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private notificationCount = 0;
  private events: NotificationEvent[] = [];

  public notificationEmitter: EventEmitter<NotificationEvent> =
    new EventEmitter<NotificationEvent>();

  constructor(
    private sessionService: SessionService,
    private messagingService: MessagingService
  ) {
    localStorage.removeItem('platex_notifications'); // temporary
  }

  public eventfeed(): NotificationEvent[] {
    return this.events;
  }

  public CalculateEvents(): NotificationService {
    this.notificationCount = 0;
    this.events = [];
    this.FetchAndFireEvent(() => {
      this.GetNotifications();
    });
    return this;
  }

  private SaveAndFireEvent(): void {
    localStorage.setItem(
      'platex_notifications',
      this.notificationCount.toString()
    );
    var event = new NotificationEvent(this.notificationCount, '');
    this.notificationEmitter.emit(event);
  }

  private FetchAndFireEvent(callback: () => void): void {
    var localCount = localStorage.getItem('platex_notifications');
    if (localCount != null)
      this.notificationCount = Number.parseInt(localCount);
    this.SaveAndFireEvent();
    callback();
  }

  private GetNotifications(): void {
    this.GetMessageNotifications((count: number) => {
      this.notificationCount += count;
      this.SaveAndFireEvent();
    });
  }

  private GetMessageNotifications(callback: (count: number) => void): void {
    if (!this.sessionService.isLoggedIn()) return;
    this.messagingService.getConversations(
      (conversations: UserConversation[]) => {
        if (conversations == null) return;
        var notifCount = 0;
        conversations.forEach((con, i) => {
          if (!con.lastMessage.seen && !con.lastMessage.userSent) {
            notifCount++;
            this.events.push(new NotificationEvent(1, 'new-message'));
          }
        });

        callback(notifCount);
      }
    );
  }
}
