<div
  class="pla-page-steps-component-container p-0"
  [ngClass]="{ noMobile: hideOnMobile }"
  [style.--background-color]="backgroundColor"
  [style.--text-color]="textColor"
  [style.--header-color]="headerColor"
  [style.--step-color]="stepColor"
  [style.--step-round-color]="stepRoundColor"
  [style.--step-line-color]="stepLineColor"
  [style.--step-border-color]="stepBorderColor"
>
  <div class="content-container p-0">
    <div class="center-content-container p-0 pla_card no-card">
      <h1 *ngIf="title" class="mb-3 text-center">{{ title }}</h1>
      <div class="steps-container">
        <ng-container
          *ngFor="
            let step of steps;
            let i = index;
            let isLast = last;
            let isFirst = first
          "
        >
          <div class="step" [ngClass]="{ last: isLast }">
            <div class="step-header">
              <div *ngIf="!isFirst" class="mobile-disable step-line"></div>
              <div *ngIf="isFirst" class="step-line-space"></div>

              <div class="step-number">
                <span>{{ i + 1 }}</span>
              </div>

              <div *ngIf="!isLast" class="step-line"></div>
              <div *ngIf="isLast" class="mobile-enable step-line-space"></div>
            </div>

            <div class="step-container px-2 w-100 h-100">
              <span *ngIf="step.header" class="step-title d-block fs-5 mb-3">{{
                step.header
              }}</span>
              <span
                class="step-text fw-light fs-6 mb-0 d-block"
                [innerHtml]="step.step"
              ></span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="swiperOnMobile">
  <div class="d-block d-md-none">
    <!-- CONTENT -->
    <div
      class="pla-page-steps-component-container p-0"
      [style.--background-color]="backgroundColor"
      [style.--text-color]="textColor"
      [style.--header-color]="headerColor"
      [style.--step-color]="stepColor"
      [style.--step-round-color]="stepRoundColor"
      [style.--step-line-color]="stepLineColor"
      [style.--step-border-color]="stepBorderColor"
    >
      <div class="content-container p-0">
        <div class="center-content-container p-0 pla_card no-card">
          <div class="swiper-container steps-container">
            <swiper #swiperRef class="swiper" [config]="swiperConfig">
              <ng-container *ngFor="let step of steps; let i = index">
                <ng-template swiperSlide>
                  <div class="w-100 steps-container mb-4 pb-3">
                    <div
                      class="d-flex align-items-start justify-content-between flex-column step"
                    >
                      <div class="mx-auto step-header">
                        <div class="step-number">
                          <span>{{ i + 1 }}</span>
                        </div>
                      </div>
                      <div class="step-container px-2 w-100 h-100">
                        <span
                          *ngIf="step.header"
                          class="step-title d-block fs-5 mb-3"
                          >{{ step.header }}</span
                        >
                        <span
                          class="step-text fw-light fs-6 mb-0 d-block text-center"
                          [innerHtml]="step.step"
                        ></span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
