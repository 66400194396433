import { CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChartOptions } from 'chart.js';
import * as _adapter from 'chartjs-adapter-moment';
import { BaseChartDirective } from 'ng2-charts';

export class DataPoint {
  constructor(public x: any, public y: any) {}
}

export class ChartData {
  constructor(public datasets: ChartDataSet[]) {}
}

export class ChartDataSet {
  constructor(public data: ChartDataPoint[]) {}
}

export class ChartDataPoint {
  constructor(public x: any, public y: number) {}
}

@Component({
  selector: 'pla-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() minHeight: number = 100;

  @ViewChild(BaseChartDirective, { static: false })
  chartElement: BaseChartDirective;
  public chartData: ChartData;
  public chartOptions: ChartOptions = {
    responsive: true,
    resizeDelay: 200,
    maintainAspectRatio: false,
    layout: {},
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    datasets: {
      line: {
        fill: false,
        borderColor: 'rgba(50,123,141,1)',
        pointBackgroundColor: 'rgba(50,123,141,1)',
        pointBorderColor: 'rgba(50,123,141,1)',
        pointHoverBackgroundColor: 'rgba(0,0,0,1)',
        pointHoverBorderColor: 'rgba(0,0,0,1)',
      },
    },
    scales: {
      x: {
        type: 'time',
        adapters: {
          date: _adapter,
        },
        ticks: {
          font: {
            size: 14,
            // weight: 'bold',
          },
          align: 'start',
          maxRotation: 0,
          minRotation: 0,
          maxTicksLimit: 20,
          callback: (val, index, ticks) => {
            var formattedVal = new Date(val).toLocaleDateString('en-GB', {
              month: 'short',
              year: '2-digit',
            });
            if (formattedVal.toLowerCase().indexOf('invalid') > -1) {
              return val;
            }
            return formattedVal;
          },
        },
        time: {
          unit: 'month',
          displayFormats: {
            quarter: 'MMM YY',
          },
        },
        grid: {
          display: true,
          borderColor: 'rgba(0,0,0,1)',
        },
      },
      y: {
        grid: {
          // color: 'rgba(0,0,0,1)',
          borderColor: 'rgba(0,0,0,1)',
        },
        beginAtZero: true,
        ticks: {
          // stepSize: 1,
          color: 'rgba(0,0,0,1)',
          maxRotation: 0,
          minRotation: 0,
          display: false,
          callback: function (val, index, ticks) {
            return `£${val}`;
          },
        },
      },
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      tooltip: {
        enabled: false,
        // intersect: false,
        displayColors: false,
        callbacks: {
          label: function (context) {
            var point = context.raw as ChartDataPoint;
            var label = `${point.y}`;
            return label;
          },
        },
      },
    },
    transitions: {
      active: {
        animation: {
          duration: 0,
        },
      },
    },
    animations: {
      x: false,
    },
    animation: {
      duration: 0,
      onComplete: () => this.drawDatasetPointsLabels(),
    },
  };

  @Input() public dataPoints: DataPoint[] = [];

  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    window.onresize = (_: any) => {
      if (!this.chartElement) return;
      if (!this.chartElement.chart) return;
      this.chartElement.chart.resize();
    };
    this.createChartData();
    var tempData = [...this.chartData.datasets[0].data];

    var pricePoints = tempData.sort((a, b) => a.x - b.x); // ORDERED BY DATE
    var _price_temp_list = JSON.parse(JSON.stringify(tempData))
      .sort((a, b) => a.y - b.y)
      .reverse(); // ORDERED BY PRICE

    var maxY = _price_temp_list[0].y;
    var minY = _price_temp_list[_price_temp_list.length - 1].y;

    var deltaY = maxY - minY;
    var yMultiplier = 1;

    if (deltaY > 1000) {
      yMultiplier = 1.1;
    }
    if (deltaY > 10000) {
      yMultiplier = 1.2;
    }
    if (deltaY > 100000) {
      yMultiplier = 1.25;
    }

    var set__maxY = maxY * (1.1 * yMultiplier);
    var maxYDiff = set__maxY - maxY;

    this.chartOptions.scales.y.min = minY - maxYDiff;
    this.chartOptions.scales.y.max = set__maxY;

    var xDiff = pricePoints[pricePoints.length - 1].x - pricePoints[0].x;
    var xDiffMonths = xDiff / 2629746000;
    if (xDiff == 0) {
      var todayDate = new Date(pricePoints[0].x);
      var lastMonthDate = new Date(pricePoints[0].x);

      todayDate.setMonth(todayDate.getMonth() + 1);
      var today = todayDate.getTime();

      lastMonthDate.setMonth(lastMonthDate.getMonth() - 2);
      var lastMonth = lastMonthDate.getTime();

      xDiff = today - lastMonth;
      this.chartOptions.scales.x.min = this.calcZeroX(lastMonth, xDiff, false);
      this.chartOptions.scales.x.max = this.calcZeroX(today, xDiff, true);
    } else if (xDiffMonths < 6) {
      var todayDate = new Date(pricePoints[pricePoints.length - 1].x);
      var lastMonthDate = new Date(pricePoints[0].x);

      todayDate.setDate(todayDate.getDate() + 7);
      var today = todayDate.getTime();

      lastMonthDate.setDate(lastMonthDate.getDate() - 7);
      var lastMonth = lastMonthDate.getTime();

      xDiff = today - lastMonth;
      this.chartOptions.scales.x.min = this.calcZeroX(lastMonth, xDiff, false);
      this.chartOptions.scales.x.max = this.calcZeroX(today, xDiff, true);
    } else {
      this.chartOptions.scales.x.max = this.calcMaxX(pricePoints, xDiff);
      this.chartOptions.scales.x.min = this.calcMinX(pricePoints, xDiff);
    }
    if (window.innerWidth < 500) {
      this.chartOptions.scales.x.ticks.maxTicksLimit = 3;
    } else {
      this.chartOptions.scales.x.ticks.maxTicksLimit = this.clamp(
        this.dataPoints.length,
        2,
        5
      );
    }
    this.changeDetectorRef.detectChanges();
    this.drawDatasetPointsLabels();
  }

  private clamp = (num, min, max) => {
    return Math.min(Math.max(num, min), max);
  };

  private calcZeroX(time: number, diff: number, max: boolean): any {
    var x = 0;
    var perc_diff = diff * 0.15;
    if (max) x = time + perc_diff;
    else x = time - perc_diff;
    var y = new Date(x);
    return y;
  }

  private calcMinX(sortedPricePoints: DataPoint[], diff: number): any {
    var firstDataPoint = new Date(sortedPricePoints[0].x);
    var perc_diff = diff * 0.15;
    var x = firstDataPoint.getTime() - perc_diff;
    return new Date(x);
  }

  private calcMaxX(sortedPricePoints: DataPoint[], diff: number): any {
    var lastDataPoint = new Date(
      sortedPricePoints[sortedPricePoints.length - 1].x
    );
    lastDataPoint.setMonth(lastDataPoint.getMonth() + 1); // add padding to ensure current month is filled
    var perc_diff = diff * 0.15;
    var x = lastDataPoint.getTime() + perc_diff;
    return new Date(x);
  }

  private createChartData(): void {
    var chartDataPoints: ChartDataPoint[] = [];
    var pricePoints = this.dataPoints.sort((a, b) => a.x - b.x).reverse();
    pricePoints.forEach((dp: DataPoint) => {
      chartDataPoints.push(new ChartDataPoint(new Date(dp.x), dp.y));
    });

    var dataClass = new ChartDataSet(chartDataPoints);
    this.chartData = new ChartData([dataClass]);
  }

  public drawDatasetPointsLabels() {
    if (!this.chartElement || !this.chartElement.chart) return;
    var ctx = this.chartElement.chart.canvas.getContext('2d');
    var fontSize = window.innerWidth < 500 ? 14 : 16;
    ctx.font = `normal ${fontSize}px sans-serif`;
    var _chart = this.chartElement.chart;
    if (
      !_chart.getDatasetMeta(0) ||
      !_chart.getDatasetMeta(0).dataset ||
      !_chart.getDatasetMeta(0).dataset['points']
    )
      return;
    var points: any[] = [..._chart.getDatasetMeta(0).dataset['points']];
    points = points.reverse();
    var tempData = [...this.dataPoints];
    tempData.reverse();
    points.forEach(
      (
        point: {
          active: boolean;
          $context: { raw: DataPoint };
          x: number;
          y: number;
        },
        i: number
      ) => {
        if (point.$context == null) {
          point.$context = { raw: tempData[i] };
        }
        if (point.x != null && point.$context != null) {
          ctx.textAlign =
            i === 0 ? 'right' : i === points.length - 1 ? 'left' : 'center';
          ctx.textAlign = 'center';
          ctx.fillStyle = point.active ? '#327b8d' : '#000';

          var posX = point.x + (i == 0 ? -10 : 10);
          var posY = point.y;

          // + y axis lowers label
          // - y axis raises label

          var isFirst = i == 0;
          var isLast = i == points.length - 1;

          var pointBefore = 0;
          var currentPoint = point.$context.raw.y;
          var pointAfter = 0;

          if (!isFirst) {
            pointBefore = points[i - 1].$context.raw.y;
          }
          if (!isLast) pointAfter = points[i + 1].$context.raw.y;

          // MIDDLE POINT
          if (!isFirst && !isLast) {
            if (pointBefore < currentPoint && pointAfter > currentPoint)
              posY += 25;
            else posY -= 15;
          } else {
            // FIRST
            if (isFirst) {
              if (pointAfter > currentPoint) posY += 25;
              else posY -= 15;
            }

            // LAST
            if (isLast) {
              if (pointBefore < currentPoint) posY -= 15;
              else posY += 25;
            }
          }

          if (i == 0 || i == points.length - 1 || point.active) {
            ctx.fillText(this.formatYAxis(point.$context.raw.y), posX, posY);
          }
        }
      }
    );
  }

  public formatYAxis(value): any {
    return `${this.currencyPipe.transform(value, 'GBP', true, '1.0-0')}`;
  }
}
