<div class="page">
  <div class="content-container">
    <div class="center-content-container main-content-container">
      <div class="w-75 m-auto my-4">
        <h2 class="fs-2 text-center mb-3">Create your listing</h2>
        <p class="text-center">
          Please complete your listing details below. You'll be able to edit
          your listing at any time even after its been published.
        </p>
      </div>

      <!-- Page steps -->
      <div class="page-steps">
        <div class="pla_card no-card spacing">
          <pla-page-steps [steps]="pageSteps"></pla-page-steps>
        </div>
      </div>

      <!-- END PAGE STEPS -->

      <div>
        <div class="pla_card no-card spacing">
          <h2 style="margin: 10px 0 0; font-weight: 700">
            List Your Registration
          </h2>
          <div class="hr-light small-spacing"></div>
        </div>
        <div class="horizontal-section" *ngIf="userDetails && listing">
          <!-- LISTING DETAILS -->

          <div
            id="listing-details-container"
            class="pla_card no-card mobile padding spacing pt-0"
          >
            <!-- <div class="field-container">
              <span>First Name</span>
              <div class="text-input-container">
                <div class="input-container">
                  <input
                    type="text"
                    spellcheck="false"
                    autocomplete="false"
                    [(ngModel)]="listing.firstName"
                  />
                </div>
              </div>
            </div>
            <div>
              <div class="field-container">
                <span>Last Name</span>
                <div class="text-input-container">
                  <div class="input-container">
                    <input
                      type="text"
                      spellcheck="false"
                      autocomplete="false"
                      [(ngModel)]="listing.lastName"
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <div>
              <div class="field-container">
                <span>Enter your registration</span>
                <div
                  class="text-input-container text-plate-input"
                  [ngClass]="{ error: !plateValid }"
                >
                  <div class="input-container plate-background">
                    <input
                      class="plate-font"
                      type="text"
                      spellcheck="false"
                      autocomplete="false"
                      [(ngModel)]="listing.registration"
                      (blur)="HandleRegistrationBlur($event)"
                    />
                    <div
                      class="loading-overlay backdrop spinner-loader"
                      *ngIf="formattingRegistration"
                    >
                      <mat-progress-spinner
                        class="spinner cyan"
                        mode="indeterminate"
                        [color]="'#327b8d'"
                        [diameter]="20"
                      ></mat-progress-spinner>
                    </div>
                  </div>
                </div>
              </div>
              <p *ngIf="!plateValid" class="warning_message">
                The registration provided is not valid.
              </p>
            </div>
            <div>
              <div class="field-container">
                <span>Enter a search term</span>
                <div class="text-input-container">
                  <div class="input-container">
                    <input
                      class="text-uppercase"
                      type="text"
                      spellcheck="false"
                      autocomplete="false"
                      [(ngModel)]="listing.searchTerm"
                      (blur)="HandleTermInputBlur($event)"
                    />
                  </div>
                </div>
              </div>
              <p>
                The search term is used to increase visibility of your listing
                and help come up with an estimate value.
                <span class="fst-italic text-muted d-block"
                  >Using a non-related keyword will not boost your
                  listing.</span
                >
              </p>
            </div>

            <div style="padding: 20px 0" class="mb-3">
              <button
                [disabled]="!canFetchValue()"
                style="width: fit-content"
                mat-flat-button
                class="turquoise-button me-auto d-block ms-0"
                (click)="FetchValuations()"
              >
                Find comparable registrations
              </button>
            </div>

            <div>
              <div class="field-container mb-3">
                <span
                  >Do you confirm you are the legal owner of this
                  registration</span
                >
                <div class="check-input-container">
                  <div class="input-container">
                    <input type="checkbox" [(ngModel)]="listing.legalOwner" />
                  </div>
                </div>
              </div>

              <div class="field-container mb-1">
                <span>This registration is on a taxed / SORN vehicle</span>
                <div class="check-input-container">
                  <div class="input-container">
                    <input
                      name="reg_status"
                      value="on_vehicle"
                      type="radio"
                      checked
                      [(ngModel)]="listing.taxed_or_sorn"
                    />
                  </div>
                </div>
              </div>

              <div class="field-container mb-3">
                <span>This registration is on a retention certificate</span>
                <div class="check-input-container">
                  <div class="input-container">
                    <input
                      name="reg_status"
                      type="radio"
                      value="on_cert"
                      [(ngModel)]="listing.on_retention"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="field-container">
                <span>Choose your asking price</span>
                <div class="text-input-container">
                  <div class="prefix inclusive-plate-font">£</div>
                  <div class="input-container d-flex">
                    <input
                      class="plate-font prefix"
                      type="number"
                      spellcheck="false"
                      autocomplete="false"
                      [(ngModel)]="listing.price"
                      (blur)="HandleTermInputBlur($event)"
                    />
                  </div>
                </div>
              </div>

              <div *ngIf="compResp">
                <p class="mb-0">
                  Based on the details provided we estimate you could sell your
                  plate for anywhere between:
                  <strong class="fw-bold">{{
                    compResp.calculations.calculation_range[0] / 100
                      | currency : "GBP"
                  }}</strong>
                  and
                  <strong class="fw-bold">{{
                    compResp.calculations.calculation_range[1] / 100
                      | currency : "GBP"
                  }}</strong>
                </p>
                <p class="text-muted fst-italic">
                  This estimate is not a guarantee, dynamic valuations are
                  automated and based on currently available information.
                </p>
              </div>

              <div>
                <div class="field-container">
                  <span style="margin-bottom: 19px"
                    >Listing Subscription Plan</span
                  >
                  <mat-form-field
                    class="no-margin"
                    appearance="outline"
                    [color]="'seconardy'"
                  >
                    <mat-select
                      [(ngModel)]="listing.listingType"
                      name="listingTypes"
                    >
                      <mat-option
                        *ngFor="let plan of listingTypes"
                        [value]="plan.value"
                      >
                        {{ plan.view }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <p class="w-100 text-end me-0 ms-auto d-block">
                  <strong class="fs-5 fw-bold ms-2">{{
                    listingPrice()
                  }}</strong>
                </p>
              </div>

              <div style="padding: 20px 0" class="mb-3">
                <button
                  [disabled]="!canList()"
                  style="width: fit-content"
                  mat-flat-button
                  class="turquoise-button me-auto d-block ms-0"
                  (click)="CreateListing()"
                >
                  <div class="d-flex align-items-center justify-content-center">
                    <span>Create My Listing</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <!-- LISTING DETAILS END-->

          <!-- COMPS-->
          <div
            id="comparables"
            class="grid-option pla_card ds mobile padding spacing"
          >
            <h1 class="mb-2">Comparable Registrations</h1>
            <div *ngIf="!fetchingValuations; else loadingValuations">
              <div *ngIf="compResp; else preComp">
                <p>
                  We have retrieved some comparable registrations that have sold
                  and others currently available to help guide you to the
                  perfect listing price for your registration.
                </p>

                <div style="margin-bottom: 20px">
                  <h2 style="margin: 0">Comparable Registrations Sold</h2>
                  <table>
                    <tr class="hr-table-light">
                      <th></th>
                      <th>Price</th>
                      <th>Sold Date</th>
                    </tr>
                    <ng-container *ngFor="let soldComp of soldValuationPlates">
                      <tr class="comp-row">
                        <td>
                          <div class="plate-cont">
                            <pla-plate
                              [plate]="soldComp"
                              [showPercentage]="false"
                              [smallPlate]="true"
                            ></pla-plate>
                          </div>
                        </td>
                        <td>
                          <span>{{
                            soldComp.price / 100 | currency : "GBP"
                          }}</span>
                        </td>
                        <td>
                          <span *ngIf="soldComp.sold_date">{{
                            soldComp.sold_date | date : "mediumDate"
                          }}</span>
                        </td>
                      </tr>
                    </ng-container>
                  </table>
                </div>
                <div>
                  <h2 style="margin: 0">Comparable Registrations For Sale</h2>
                  <table>
                    <tr class="hr-table-light">
                      <th></th>
                      <th>Price</th>
                      <th></th>
                    </tr>
                    <ng-container
                      *ngFor="let availableComp of availableValuationPlates"
                    >
                      <tr class="comp-row">
                        <td>
                          <div class="plate-cont">
                            <pla-plate
                              [plate]="availableComp"
                              [showPercentage]="false"
                              [smallPlate]="true"
                            ></pla-plate>
                          </div>
                        </td>
                        <td>
                          <span>{{
                            availableComp.price / 100 | currency : "GBP"
                          }}</span>
                        </td>
                        <td></td>
                      </tr>
                    </ng-container>
                  </table>
                </div>
              </div>
              <ng-template #preComp>
                <p class="fw-bold">
                  Enter your registration and a search term to get a list of
                  comparables.
                </p>

                <p class="text-muted">
                  Comparable registrations are a collection of registrations
                  that have sold and others that are currently available for
                  sale.
                </p>

                <p class="text-muted">
                  Our comparable plates are based on the registration you are
                  selling and the search term you think fits best!
                </p>
              </ng-template>
            </div>
            <ng-template #loadingValuations>
              <div class="raw-message-container spinner-loader">
                <p class="">Loading comparables, this may take a minute...</p>
                <mat-progress-spinner
                  class="spinner cyan"
                  mode="indeterminate"
                  [color]="'#327b8d'"
                  [diameter]="60"
                ></mat-progress-spinner>
              </div>
            </ng-template>
          </div>
          <!--  COMPS END -->
        </div>
      </div>

      <!-- END LISTING CONTENT -->
    </div>
  </div>
</div>
