<div *ngIf="!loading; else loadingSpinner">
  <ng-container *ngFor="let favouritePlate of favourites">
    <div class="fav-row">
      <pla-search-result-row
        [plate]="favouritePlate"
        [lastRow]="false"
        [showRating]="false"
        [allowShowMore]="false"
        [whitePlate]="false"
      ></pla-search-result-row>
    </div>
  </ng-container>
</div>

<ng-template #loadingSpinner>
  <div class="full spinner-loader">
    <div class="py-3">
      <mat-progress-spinner
        class="spinner cyan"
        mode="indeterminate"
        [color]="'#327b8d'"
        [diameter]="60"
      ></mat-progress-spinner>
    </div>
    <span>Loading Favourites...</span>
  </div>
</ng-template>
