import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageKeyPair } from 'src/app/services/image-service';

import SwiperCore, { Navigation, Pagination, Swiper, Thumbs } from 'swiper';
import { LargeImageModalComponent } from '../large-image-modal/large-image-modal.component';
SwiperCore.use([Navigation, Thumbs, Pagination]);

@Component({
  selector: 'pla-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
})
export class ImageSliderComponent implements OnInit {
  @Input('defaultImage') public defaultImage: ImageKeyPair = null;
  @Input('imageOptionsChange') public imageOptionsChange: EventEmitter<
    ImageKeyPair[]
  > = new EventEmitter<ImageKeyPair[]>();
  @Input('showShare') public showShare: boolean = false;
  @Input('showFullscreen') public showFullscreen: boolean = false;
  @Input('loop') public loop: boolean = false;
  @Input('dots') public dots: boolean = false;
  @Input('arrows') public arrows: boolean = false;
  @Input('selectionChange') selectionChange: EventEmitter<ImageKeyPair> =
    new EventEmitter<ImageKeyPair>();

  @Output('selectionChanged')
  public selectionChanged: EventEmitter<ImageKeyPair> =
    new EventEmitter<ImageKeyPair>();

  public selectedImageOption: ImageKeyPair;
  public imageOptions: ImageKeyPair[] = [];
  public ready: boolean = false;

  private swiper: Swiper;

  // GETTERS

  public swiperNavigation = {
    nextEl: '.swiper-nav.swiper-next',
    prevEl: '.swiper-nav.swiper-prev',
  };

  // CLASS

  constructor(private dialog: MatDialog, private change: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.ready = true;
    this.registerListeners();
  }

  private registerListeners(): void {
    if (this.imageOptionsChange != null)
      this.imageOptionsChange.subscribe((_) => {
        this.regenerate(_);
      });

    if (this.selectionChange != null)
      this.selectionChange.subscribe((_) => {
        var i = this.imageOptions.findIndex((io) => io.imageId == _.imageId);
        if (this.loop) this.swiper.slideToLoop(i);
        else this.swiper.slideTo(i);
      });
  }

  private regenerate(options: ImageKeyPair[]): void {
    const prevCount = this.imageOptions.length;
    this.imageOptions = options;
    this.swiper.update();
    this.swiper.allowTouchMove =
      this.imageOptions != null && this.imageOptions.length > 1;

    // update UI
    setTimeout(() => {
      if (prevCount > 0) return;
      this.selectedImageOption = options[0];
      this.swiper.slideToLoop(0);
      this.selectionChanged.emit(this.imageOptions[0]);
    }, 100);
  }

  // handlers
  public handleSwiperInit(_e: any): void {
    this.swiper = _e[0];
    this.swiper.on('slideChange', (swiper: SwiperCore) => {
      this.selectionChanged.emit(this.imageOptions[swiper.realIndex]);
    });
  }

  public handleFullscreenClick(): void {
    this.dialog.open(LargeImageModalComponent, {
      data: { b64: this.imageOptions[this.swiper.realIndex].image },
      panelClass: 'no-padding',
      maxWidth: '80vw',
      height: '80vh',
      maxHeight: '80vh',
    });
  }

  public shareEnabled(): boolean {
    const shareData = {
      title: 'PLA',
      text: 'PLATEX',
      url: 'https://www.platex.co.uk',
    };
    if (navigator == null || !navigator.hasOwnProperty('canShare'))
      return false;
    return navigator.canShare(shareData);
  }

  public async handleShareClick(): Promise<void> {
    try {
      const blob = await (
        await fetch(this.imageOptions[this.swiper.realIndex].image)
      ).blob();
      const file = new File([blob], 'platexImage.png', { type: blob.type });
      navigator.share({
        title: 'PLATEX IMAGE',
        files: [file],
      });
    } catch (err) {}
  }
}
