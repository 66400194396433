import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChartOptions, TooltipItem } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import {
  ChartData,
  ChartDataPoint,
  ChartDataSet,
  DataPoint,
} from '../chart/chart.component';
import * as _adapter from 'chartjs-adapter-moment';

declare global {
  interface String {
    toPlural(count: number): string;
  }
}

String.prototype.toPlural = function (this: string, count: number) {
  var s = this;
  if (count == 1) return s;
  return `${s}s`;
};

@Component({
  selector: 'pla-tracking-chart',
  templateUrl: './tracking-chart.component.html',
  styleUrls: ['./tracking-chart.component.scss'],
})
export class TrackingChartComponent implements OnInit, AfterViewInit {
  @ViewChild(BaseChartDirective, { static: true })
  chartElement: BaseChartDirective;
  public gradient: CanvasGradient;

  public chartData: ChartData;
  public chartOptions: ChartOptions;

  @Input() public dataPoints: DataPoint[] = [];
  @Input() public maxY: number = 0;
  @Input() public icon: string;
  @Input() public title: string;
  @Input() public interaction: string;
  @Input() public description: string;

  public today: DataPoint;
  public yesterday: DataPoint;
  public todayAnalytic: number;

  constructor() {}

  ngOnInit(): void {
    window.onresize = (_: any) => {
      if (!this.chartElement) return;
      if (!this.chartElement.chart) return;
      this.chartElement.chart.resize();
    };
  }

  ngAfterViewInit(): void {
    this.createGradient();
    this.buildChartOptions();
    this.createChartData();
  }

  private buildChartOptions(): void {
    var that = this;
    this.chartOptions = {
      responsive: true,
      resizeDelay: 200,
      maintainAspectRatio: false,
      layout: {},
      plugins: {
        tooltip: {
          callbacks: {
            title: function (tooltipItems: TooltipItem<any>[]): string {
              return `${that.title} on ${tooltipItems[0].label.substring(
                0,
                12
              )}`;
            },
            // label: function (context: TooltipItem<any>): string {
            //   let label = context.dataset.label || '';

            //   if (context.parsed.y !== null) {
            //     label = `${context.formattedValue} ${that.interaction}`;
            //   }

            //   return label;
            // },
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'nearest',
        axis: 'xy',
      },
      elements: {
        line: {
          borderWidth: 2,
          tension: 0.25,
        },
      },
      datasets: {
        line: {
          fill: {
            target: 'start',
            above: this.gradient, //'rgba(50,123,141,.1)',
          },
          borderColor: 'rgba(50,123,141,1)',
          pointBackgroundColor: 'rgba(50,123,141,1)',
          pointBorderColor: 'rgba(50,123,141,1)',
          pointHoverBackgroundColor: 'rgba(0,0,0,1)',
          pointHoverBorderColor: 'rgba(0,0,0,1)',
          pointRadius: 0,
        },
      },
      scales: {
        x: {
          display: false,
          type: 'time',
          adapters: {
            date: _adapter,
          },
          ticks: {
            display: false,
            font: {
              size: 14,
            },
            align: 'start',
            maxRotation: 0,
            minRotation: 0,
            maxTicksLimit: 20,
          },
          time: {
            unit: 'day',
            displayFormats: {
              quarter: 'DD MMM',
            },
          },
          grid: {
            display: false,
            borderColor: 'rgba(0,0,0,1)',
          },
        },
        y: {
          min: -1,
          max: this.maxY,
          display: false,
          grid: {
            display: false,
            borderColor: 'rgba(0,0,0,1)',
          },
          beginAtZero: true,
          ticks: {
            display: false,
            stepSize: 1,
            color: 'rgba(0,0,0,1)',
            maxRotation: 0,
            minRotation: 0,
          },
        },
      },
    };
  }

  private createGradient(): void {
    var ctx = this.chartElement.chart.canvas.getContext('2d');
    var gradient = ctx.createLinearGradient(
      0,
      this.chartElement.chart.height,
      0,
      0
    );
    gradient.addColorStop(1, 'rgba(50,123,141,1)');
    gradient.addColorStop(0.1, 'rgba(50,123,141,0.05)');
    gradient.addColorStop(0, 'rgba(50,123,141,0)');
    this.gradient = gradient;
  }

  private createChartData(): void {
    var chartDataPoints: ChartDataPoint[] = [];
    var p = this.dataPoints.sort((a, b) => a.x - b.x).reverse();
    p.forEach((dp: DataPoint) => {
      var _x = new Date(dp.x);
      _x.setHours(0, 0, 0, 0);
      chartDataPoints.push(new ChartDataPoint(_x, dp.y));
    });

    var dataClass = new ChartDataSet(chartDataPoints);
    this.chartData = new ChartData([dataClass]);

    p = [...this.dataPoints].sort((a, b) => a.x - b.x).reverse();
    this.today = p[p.length - 1];
    this.yesterday = p[p.length - 2];
    this.todayAnalytic =
      Math.ceil(((this.today.y - this.yesterday.y) / this.yesterday.y) * 100) ||
      0;
    if (!isFinite(this.todayAnalytic)) this.todayAnalytic = 100;
  }
}
