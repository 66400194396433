<div class="page" *ngIf="listing">
  <div class="content-container">
    <div class="center-content-container main-content-container">
      <div class="m-auto my-4">
        <div
          style="max-width: 900px"
          class="m-auto d-flex align-items-center justify-content-start"
        >
          <button
            mat-flat-button
            class="d-block turquoise-button"
            [routerLink]="'/account/selling'"
          >
            <div class="d-flex justify-content-between align-items-center">
              <mat-icon class="me-1">chevron_left</mat-icon>
              <span>Back to listings</span>
            </div>
          </button>
        </div>
        <div class="w-75 m-auto" style="max-width: 750px; min-width: 300px">
          <h2 class="fs-2 text-center mb-0">Your Listing</h2>
          <div class="my-2">
            <pla-plate [rawPlate]="listing.registration"></pla-plate>
          </div>
          <p class="text-center">
            Below you can edit your listing, view performance metrics and
            upgrade your listing plan. Changes may not be visible straight away
            after updating.
          </p>
        </div>

        <div
          *ngIf="listing && !listing.active"
          class="mb-4 mx-auto"
          style="max-width: 900px"
        >
          <div class="pla_card no-card spacing">
            <h2 style="margin: 10px 0 0; font-weight: 700">Listing Inactive</h2>
            <div class="hr-light small-spacing"></div>
            <span class="w-100 d-block mb-2 mt-2">
              This listing has been removed and is no longer available to view
              or edit. Delete it to remove it from your account entirely.
            </span>
            <div>
              <button
                [disabled]="listing.active"
                class="ms-auto me-0 d-block platex-border-button"
                mat-flat-button
                (click)="DeleteListingHandler()"
              >
                Delete Listing
              </button>
            </div>
          </div>
        </div>

        <!-- METRICS -->

        <div
          *ngIf="listing && listing.active"
          class="mb-4 mx-auto"
          style="max-width: 900px"
        >
          <div class="pla_card no-card spacing">
            <h2 style="margin: 10px 0 0; font-weight: 700">Listing Metrics</h2>
            <div class="hr-light small-spacing"></div>
            <span class="w-100 d-block mb-2 mt-2">
              View your listing metrics to see how your advert is performing in
              real time.
            </span>
            <div>
              <div
                class="d-md-flex justify-content-center align-items-center gap-3 d-block"
              >
                <pla-tracking-chart
                  class="w-100"
                  [icon]="'visibility'"
                  [title]="'Impressions'"
                  [interaction]="'impression'"
                  [description]="
                    'Number of times your listing has been seen in results.'
                  "
                  *ngIf="impressionData.length > 0"
                  [dataPoints]="impressionData"
                  [maxY]="maxY"
                ></pla-tracking-chart>
                <pla-tracking-chart
                  class="w-100"
                  [icon]="'ads_click'"
                  [title]="'Clicks'"
                  [interaction]="'click'"
                  [description]="'Number of clicks your listing has received.'"
                  *ngIf="clickData.length > 0"
                  [dataPoints]="clickData"
                  [maxY]="maxY"
                ></pla-tracking-chart>
              </div>
              <span class="text-muted w-100 d-block text-center fst-italic">
                Your clicks may appear larger than impressions, this can be if
                your listing has been bookmarked or searched directly
              </span>
            </div>
          </div>
        </div>

        <!-- UPDATE DETAILS -->

        <div
          *ngIf="listing && listing.active"
          class="mb-4 mx-auto"
          style="max-width: 900px"
        >
          <div class="pla_card no-card spacing">
            <h2 style="margin: 10px 0 0; font-weight: 700">
              Update Listing Details
            </h2>
            <div class="hr-light small-spacing"></div>
          </div>
          <div class="pla_card no-card spacing">
            <div class="d-flex justify-content-between align-items-center">
              <span>Choose your asking price</span>
              <div class="text-input-container">
                <div class="prefix inclusive-plate-font">£</div>
                <div class="input-container d-flex">
                  <input
                    [disabled]="!listing.active"
                    class="plate-font prefix"
                    type="number"
                    [placeholder]="listing.viewPrice"
                    spellcheck="false"
                    autocomplete="false"
                    [(ngModel)]="listing.price"
                  />
                </div>
              </div>
            </div>

            <div
              class="mt-3 d-flex align-items-center justify-content-end gap-2"
            >
              <button
                *ngIf="listing.active && listing.listingType == 0"
                class="ms-auto me-0 d-block platex-border-button"
                mat-flat-button
                (click)="DeleteListingHandler()"
              >
                Delete Listing
              </button>
              <button
                [disabled]="!listing.active || !hasChanged()"
                class="me-0 d-block turquoise-button"
                mat-flat-button
                (click)="UpdateListingHandler()"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
