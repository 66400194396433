import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Params,
  Router,
  RouterEvent,
  RoutesRecognized,
} from '@angular/router';
import { AppNavigation } from 'src/app/app.component';
import {
  NotificationEvent,
  NotificationService,
} from 'src/app/services/notification-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input('navigation') public navigation: AppNavigation[];
  @Input('showSearchBar') public showSearchBar: boolean = true;
  @Input('showDesktopSearchBar') public showDesktopSearchBar: boolean = true;
  @Input('inputFollowRoute') public inputFollowRoute: boolean = false; // when true, the input will match the search criteria on results page

  @Output()
  public navigationToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public favouriteToggle: EventEmitter<void> = new EventEmitter<void>();

  public searchInput: string = '';
  public showFloatingSearch: boolean = false;

  public notifcationCount: number = 0;
  public w_drop: boolean = false;

  constructor(
    public userService: UserService,
    public router: Router,
    private notificationService: NotificationService
  ) {
    document.addEventListener('scroll', (event) => {
      if (window.scrollY > 0) {
        this.w_drop = true;
      } else {
        this.w_drop = false;
      }
    });

    this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof RoutesRecognized) {
        this.routingEnd(e.state.root.firstChild.params);
      }
      if (e instanceof NavigationStart) {
        this.showFloatingSearch = false;
      }
    });

    this.notificationService.notificationEmitter.subscribe(
      (event: NotificationEvent) => {
        this.notifcationCount = event.notificationCount;
      }
    );
    this.notificationService.CalculateEvents();
  }

  ngOnInit(): void {}

  private routingEnd(params: Params): void {
    if (window.location.pathname.startsWith('/search-results')) {
      this.searchInput = this.inputFollowRoute ? params.searchCriteria : '';
    }
  }

  public toggleNavigation(): void {
    this.navigationToggle.emit();
  }

  public showFavourites(): void {
    this.favouriteToggle.emit();
  }

  public toggleSearch(event: PointerEvent, show: boolean): void {
    this.showFloatingSearch = show;
    setTimeout(() => {
      document.getElementById('blank_focus').focus();
    }, 500);
  }

  public runSearch(): void {
    if (this.searchInput == '' || this.searchInput == null) return; // no input
    this.router.navigate([`/search-results/${this.searchInput}`]);
  }

  public inlineSearch($event: KeyboardEvent, override: boolean): void {
    if (!override && $event.code != 'Enter') return; // something else triggered method
    if (this.searchInput == '' || this.searchInput == null) return; // no input
    this.toggleSearch(null, false);
    this.router.navigate([`/search-results/${this.searchInput}`]);
  }
}
