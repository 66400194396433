import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Registration } from 'src/app/models/registration';
import { SearchReqResponse } from 'src/app/services/search-service';

@Component({
  selector: 'pla-initial-section',
  templateUrl: './initial-section.component.html',
  styleUrls: ['./initial-section.component.css'],
})
export class InitialSectionComponent implements OnInit {
  @Input('header') public header: string;
  @Input('results') public rawresults: Registration[];
  @Input('searching') public searching: boolean = false;
  @Input('resultChange') public resultChange: EventEmitter<Registration[]>;

  public results: SearchReqResponse;

  constructor() {}

  ngOnInit(): void {
    this.results = new SearchReqResponse(this.header, this.rawresults);
  }
}
