<div class="page">
  <div class="content-container">
    <div class="center-content-container">
      <div class="admin-page-container">
        <h1>Admin page</h1>
        <ng-container *ngIf="loaded">
          <p>Admin page opened in new tab.</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
