<div *ngIf="paymentDetails">
  <div class="pla_card padding spacing">
    <h2>Options</h2>
    <!-- content -->
    <ng-container *ngFor="let option of paymentDetails.optionalExtras">
      <div class="optional-extra">
        <div class="header">
          <div>
            <mat-checkbox
              [(ngModel)]="option.selected"
              (change)="optionChange($event, option)"
              id="{{ option.name }}_option"
            >
              <div>
                <span class="title">{{ option.title }}</span>
                <span class="sub">{{ option.subTitle }}</span>
              </div>
            </mat-checkbox>
          </div>
          <div>
            <span class="price">{{
              option.price_minor / 100 | currency : "GBP"
            }}</span>
          </div>
        </div>
        <div class="split"></div>
        <div class="content">
          <p>{{ option.description }}</p>
        </div>
        <div *ngIf="option.loadElement && option.selected">
          <div>
            <pla-plate-option-selection
              [registration]="paymentDetails.formattedRegistration"
              [optionsChanged]="optionsChanged"
            ></pla-plate-option-selection>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!continueCheckout">
    <!-- CONTINUE CHECKOUT -->
    <div class="pla_card no-card spacing">
      <button
        mat-flat-button
        class="turquoise-button"
        style="width: 100%; margin: 10px auto 0"
        (click)="continueCheckout = true"
      >
        Continue To Checkout
      </button>
    </div>
  </div>

  <ng-container *ngIf="continueCheckout">
    <div class="hr-light"></div>

    <div class="pla_card no-card padding spacing split-container">
      <!-- NOMINEE DETAILS -->
      <div *ngIf="userDetails" class="user-details">
        <h2>Nominee Details</h2>
        <!-- FIRST -->
        <div class="field-container">
          <span>First Name</span>
          <div class="text-input-container">
            <div class="input-container">
              <input
                type="text"
                spellcheck="false"
                autocomplete="false"
                [(ngModel)]="userDetails.firstName"
              />
            </div>
          </div>
        </div>
        <!-- LAST -->
        <div class="field-container">
          <span>Last Name</span>
          <div class="text-input-container">
            <div class="input-container">
              <input
                type="text"
                spellcheck="false"
                autocomplete="false"
                [(ngModel)]="userDetails.lastName"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- ORDER SUMAMRY -->
      <div class="order-summary">
        <h2>Order Summary</h2>
        <pla-price-breakdown
          [showTitle]="false"
          [paymentDetails]="paymentDetails"
          [showFree]="true"
          [showExtras]="true"
        ></pla-price-breakdown>
      </div>
    </div>

    <!-- CHECKOUT BUTTONS -->
    <div class="pla_card no-card padding spacing">
      <h2>Payment</h2>
      <pla-payment-checkout
        [optionsChanged]="optionsChanged"
        [products]="getProducts()"
      ></pla-payment-checkout>
    </div>
  </ng-container>
</div>
