import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { SITE_BANNER_IMAGES } from 'src/app/consts/default-image';
import { ImageKeyPair, ImageService } from 'src/app/services/image-service';

@Component({
  selector: 'pla-banner-image',
  templateUrl: './banner-image.component.html',
  styleUrls: ['./banner-image.component.scss'],
})
export class BannerImageComponent implements OnInit {
  @Input('carBanner') public carBanner: string = 'ASTON_DB11';
  @Input('mobileCarBanner') public mobileCarBanner: string = null;
  @Input('showImage') public showImage: boolean = true;
  @Input('registration') public registration: string = 'P14 TEX';
  @Input('showShadow') public showShadow: boolean = true;
  @Input('overlap') public overlap: boolean = false;
  @Input('taller') public taller: boolean = false;
  @Input('fullHeight') public fullHeight: boolean = false;
  @Input('rawImage') public rawImage: boolean = false;
  @Input() public backgroundImage: boolean = false;
  @Input() public backgroundClass: string = '';

  @Input('customAR') public customAR: string = null;
  @Input('customMobileAR') public customMobileAR: string = null;

  // standardBanner 64 / 27
  // desktopTall  16/9
  // mobileTall 3/5

  public calcHeight: number;
  public aspectRatio(mobile: boolean): string {
    if (mobile) {
      if (this.customMobileAR) return this.customMobileAR;
      return '64/27';
    } else {
      if (this.customAR) return this.customAR;
      return '64/27';
    }
  }

  @Input('update') public update: EventEmitter<string> =
    new EventEmitter<string>();

  private imageSource: string = null;

  constructor(private imageService: ImageService) {}

  ngOnInit(): void {
    this.start();
    if (this.update)
      this.update.subscribe((reg: string) => {
        this.registration = reg;
        this.start();
      });
  }

  public generateBackgroundImageStyle(): string {
    return SITE_BANNER_IMAGES[this.carBanner];
  }

  public generateMobileBackgroundImage(): string {
    if (this.mobileCarBanner == null) {
      return SITE_BANNER_IMAGES[this.carBanner];
    }

    return SITE_BANNER_IMAGES[this.mobileCarBanner];
  }

  private start(): void {
    if (this.registration == null) this.registration = ''; // prevent 'undefined'
    if (!this.showImage) return;
    this.imageService.whenReady(() => {
      var config = this.imageService.getConfigById(
        this.imageService.getDefaultImage().id
      );
      if (config == null) return;
      this.imageService.generateImage(
        config,
        this.registration,
        (key: ImageKeyPair) => {
          this.imageSource = key.image;
        }
      );
    });
  }
}
