import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { QuickSignupService } from 'src/app/services/quick-signup-service';
import { SessionService } from 'src/app/services/session-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-signed-up-content',
  templateUrl: './signed-up-content.component.html',
  styleUrls: ['./signed-up-content.component.scss'],
})
export class SignedUpContentComponent implements OnInit {
  @Input('showButton') public showButton: boolean = false;
  @Input('buttonType') public buttonType: 'button' | 'label' = 'label';
  @Input('customTrigger') public customTrigger: EventEmitter<void>;
  @Input('active') public active: boolean = true;
  @Input('requesting') public requesting: string = null;

  public contentLocked: boolean = true;

  // CONFIG
  public loginRequiredMessage: string = 'Login Required';

  constructor(
    private sessionService: SessionService,
    private signupService: QuickSignupService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.detectStatus();

    if (this.customTrigger) {
      this.customTrigger.subscribe(() => {
        if (this.sessionService.isLoggedIn() || !this.active) return;
        this.requestSignupModal();
      });
    }
  }

  private detectStatus(): void {
    if (this.active) {
      this.contentLocked = !this.sessionService.isLoggedIn(); // serverside still gets checked, so can be loose with frontend checks
    } else {
      this.contentLocked = false;
    }
  }

  public requestSignupModal(): void {
    this.signupService.quickSignup(
      this.requesting,
      () => {
        // signed up
      },
      () => {
        this.userService.fetchAllUserData(() => {
          // queue cleared
          this.detectStatus();
        });
      }
    );
  }
}
