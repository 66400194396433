<div class="component-container">
  <div *ngIf="false" class="tabbed-seller-list">
    <div class="tabbed-container-parent">
      <pla-tabbed-section
        [floating]="false"
        [backgroundColour]="'#fff'"
        [showBorder]="true"
        [sections]="tabs"
        (sectionChange)="selectionChangeHandler($event)"
      ></pla-tabbed-section>
    </div>
    <div class="tabbed_seller_row" *ngIf="tabbedSellerRow">
      <div>
        <p class="price-text">
          {{ tabbedSellerRow.seller.totalPrice / 100 | currency: "GBP" }}
        </p>
        <pla-platex-direct
          *ngIf="
            tabbedSellerRow.seller.siteName.toLowerCase() == 'platex';
            else sellerName
          "
        ></pla-platex-direct>
        <ng-template #sellerName>
          <p class="seller-name">
            <span>{{ tabbedSellerRow.seller.siteName }}</span
            ><span class="special-text">{{
              tabbedSellerRow.seller.domainSuffix
            }}</span>
          </p>
        </ng-template>
      </div>

      <button class="table-action-button turquoise-button" mat-raised-button>
        Buy
      </button>
    </div>
  </div>
  <div class="table-container">
    <table>
      <tr>
        <!-- TITLE  -->
        <th></th>

        <!-- Price + Co -->
        <th></th>

        <!--  Action -->
        <th></th>
      </tr>
      <ng-container *ngFor="let sellerRow of sellerRows">
        <tr>
          <!-- Cert -->
          <td class="stretch title">
            {{ sellerRow.title }}
          </td>

          <!-- Price + Co -->
          <td *ngIf="sellerRow.seller" class="stretch">
            <div class="seller-col">
              <pla-platex-direct
                *ngIf="
                  sellerRow.seller.siteName.toLowerCase() == 'platex';
                  else sellerName
                "
              ></pla-platex-direct>
              <ng-template #sellerName>
                <p class="seller-name">
                  <span>{{ sellerRow.seller.siteName }}</span
                  ><span class="special-text">{{
                    sellerRow.seller.domainSuffix
                  }}</span>
                </p>
              </ng-template>

              <p class="price-text">
                {{ sellerRow.seller.totalPrice / 100 | currency: "GBP" }}
              </p>
            </div>
          </td>

          <!-- Action -->
          <td>
            <button
              class="table-action-button turquoise-button"
              mat-raised-button
            >
              Buy
            </button>
          </td>
        </tr>
      </ng-container>
    </table>
  </div>
</div>

<ng-template #tick>
  <div class="tick-container">
    <mat-icon>done</mat-icon>
  </div>
</ng-template>
<ng-template #cross>
  <div class="cross-container">
    <mat-icon>close</mat-icon>
  </div>
</ng-template>
