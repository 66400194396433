import { Injectable } from '@angular/core';
import { SessionService } from './session-service';

declare global {
  interface Window {
    gtag: any;
    LO: any;
  }
}

export enum TRACKING_SEARCH_TYPE {
  MAIN = 'MAIN_SEACH',
  DATELESS = 'DATELESS',
  INITALS = 'INITALS',
  BYO = 'BYO',
}

@Injectable({ providedIn: 'root' })
export class TrackingService {
  constructor(private sessionService: SessionService) {}

  public TrackSearch(
    type: TRACKING_SEARCH_TYPE,
    term: string,
    metaData: any = {}
  ): void {
    this.LuckyOrangeTrack('Platex Search', {
      type: type,
      term: term,
      loggedIn: this.sessionService.isLoggedIn(),
      ...metaData,
    });

    window.gtag('event', 'platex_search', {
      type: type,
      term: term,
      loggedIn: this.sessionService.isLoggedIn(),
      ...metaData,
    });
  }

  public TrackPlateDetail(
    registration: string,
    direct: boolean,
    metaData: any = {}
  ): void {
    this.LuckyOrangeTrack('Plate Detail', {
      registration: registration,
      directSearch: direct,
      loggedIn: this.sessionService.isLoggedIn(),
      ...metaData,
    });
  }

  public TrackAffiliateDetail(
    registration: string,
    dealer: string,
    metaData: any = {}
  ): void {
    this.LuckyOrangeTrack('Affiliate Click', {
      registration: registration,
      dealer: dealer,
      loggedIn: this.sessionService.isLoggedIn(),
      ...metaData,
    });
  }

  public TrackNotify(registration: string, metaData: any = {}): void {
    this.LuckyOrangeTrack('Notify', {
      registration: registration,
      loggedIn: this.sessionService.isLoggedIn(),
      ...metaData,
    });
  }

  public TrackAlert(term: string, metaData: any = {}): void {
    this.LuckyOrangeTrack('Alert', {
      term: term,
      loggedIn: this.sessionService.isLoggedIn(),
      ...metaData,
    });
  }

  public TrackFavourite(registration: string, metaData: any = {}): void {
    this.LuckyOrangeTrack('Favourite', {
      registration: registration,
      loggedIn: this.sessionService.isLoggedIn(),
      ...metaData,
    });
  }

  private LuckyOrangeTrack(eventName: string, data: any): void {
    if (
      window.LO == null ||
      window.LO.events == null ||
      window.LO.events.track == null
    ) {
      setTimeout(() => {
        this.LuckyOrangeTrack(eventName, data);
      }, 2000);
    } else {
      window.LO.events.track(eventName, data);
    }
  }
}
