import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Registration } from '../../models/registration';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { SynonymResult } from 'src/app/models/synonymResult';
import { BYOTermResponse } from 'src/app/models/byoTermResponse';
import { UserService } from 'src/app/services/user-service';
import { LoggerService } from 'src/app/services/logger-service';
import { AltSearchOption } from 'src/app/models/altSearchOption';
import { UserAlert } from 'src/app/models/useralert';
import { QuickSignupService } from 'src/app/services/quick-signup-service';
import { SessionService } from 'src/app/services/session-service';
import {
  TrackingService,
  TRACKING_SEARCH_TYPE,
} from 'src/app/services/tracking-service';
import { SearchType } from 'src/app/components/home-page-search/home-page-search.component';

@Component({
  selector: 'pla-search-results-page',
  templateUrl: './search-results-page.component.html',
  styleUrls: ['./search-results-page.component.scss'],
})
export class SearchResultsPageComponent implements OnInit, AfterViewInit {
  public newlyListedResults: SearchReqResponse;

  public results: SearchReqResponse;
  public perfectResults: SearchReqResponse;
  public wildcardResults: SearchReqResponse;
  public synonymResults: SynonymResult[];
  public perfectSearching: boolean;
  public superSearching: boolean;
  public wildcardSearching: boolean;
  public byoTerm: BYOTermResponse;
  public alternativeOptions: AltSearchOption[];

  public searchCriteria: string;
  public routeFrom: string;
  public newlyListedResultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public perfectResultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public wildcardResultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();

  public bannerRegistration: string = 'S34 RCH';
  public bannerUpdate: EventEmitter<string> = new EventEmitter<string>();
  public fetchedAlerts: boolean = false;
  public shownPopup: boolean = false;

  constructor(
    private searchService: SearchService,
    private userService: UserService,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private loggerService: LoggerService,
    private router: Router,
    private sessionService: SessionService,
    private quickSignupService: QuickSignupService,
    private trackingService: TrackingService
  ) {
    this.searchCriteria = this.route.snapshot.params.searchCriteria;
    this.routeFrom = this.route.snapshot.queryParams['routeFrom'];
    this.trackingService.TrackSearch(
      TRACKING_SEARCH_TYPE.MAIN,
      this.searchCriteria
    );
  }

  ngOnInit(): void {
    this.userService.getUserAlerts(() => {
      this.fetchedAlerts = true;
    });

    if (this.superSearching || this.perfectSearching || this.wildcardSearching)
      return;

    this.search();
    this.searchAltTerms();
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 1);
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 100);
  }

  public generateSynonymHeaderText(synonymResult: SynonymResult): string {
    return `Similar search results to ${this.searchCriteria.toUpperCase()}`;
    return `Alternative search results for ${this.searchCriteria.toUpperCase()} - "${synonymResult.synonym.toUpperCase()}"`;
  }

  public routeToNewSearch(term: AltSearchOption): void {
    this.router.navigate([`/search-results/${term.term}`], {
      queryParams: { routeFrom: this.searchCriteria.toUpperCase() },
    });
  }

  public hasAlert(): boolean {
    return this.userService.hasAlert(this.searchCriteria);
  }

  public addQuickAlert(): void {
    if (!this.searchCriteria || this.searchCriteria == '') return;
    var alert = new UserAlert();
    alert.term = this.searchCriteria;
    this.userService.addAlert(alert, (_alerts: UserAlert[]) => {
      // added
    });
  }

  public search() {
    this.perfectSearching = true;
    this.superSearching = true;
    this.wildcardSearching = true;
    this.userService.getUserData(() => {
      window.scrollTo(0, 1);
      this.searchRaw(() => {
        if (!this.sessionService.isLoggedIn() && !this.shownPopup) {
          this.shownPopup = true;
          this.quickSignupService.signupCapture();
        }
      });
      this.searchSynonym();
      this.searchPerfect(() => {
        this.getBYO();
      });
    });
  }

  private searchAltTerms(): void {
    this.searchService.getAltSearchTerms(
      this.searchCriteria.toUpperCase(),
      (_: AltSearchOption[]) => {
        if (
          this.routeFrom &&
          _.filter((__) => __.term == this.routeFrom).length == 0
        )
          _.push(
            new AltSearchOption(this.routeFrom, this.routeFrom, 'original')
          );
        this.alternativeOptions = _.reverse();
      }
    );
  }

  private searchPerfect(callback: () => void = () => {}): void {
    this.searchService.searchPerfect(
      this.searchCriteria,
      (res: Registration[]) => {
        try {
          this.perfectSearching = false;
          if (res != null) {
            var formattedResults =
              this.registrationService.formatRegistrations(res);
            const tempSearchReq = new SearchReqResponse(
              'perfect_id',
              formattedResults
            );
            this.perfectResultChange.emit(tempSearchReq);
            this.perfectResults = tempSearchReq;
          }
          callback();
        } catch (ex: any) {
          this.loggerService.logException(ex);
          callback();
        }
      }
    );
  }

  private getBYO(callback: () => void = () => {}): void {
    this.searchService.getBYOTerm(
      this.searchCriteria,
      (res: BYOTermResponse) => {
        try {
          this.byoTerm = res;
          callback();
        } catch (ex: any) {
          this.loggerService.logException(ex);
          callback();
        }
      }
    );
  }

  private searchSynonym(callback: () => void = () => {}): void {
    this.searchService.searchSynonym(
      this.searchCriteria,
      (res: SynonymResult[]) => {
        try {
          if (res != null) {
            res.map((_res) => {
              _res.resp.id = 'synonym_id';
              _res.resp.registrations =
                this.registrationService.formatRegistrations(
                  _res.resp.registrations
                );
            });
            if (res.length == 0) return;

            const allResults = res.flatMap((r) => r.resp.registrations);
            allResults.sort((a, b) => b.percentage - a.percentage);
            const x = new SynonymResult();
            x.synonym = this.searchCriteria;
            x.resp = new SearchReqResponse(res[0].resp.id, allResults);

            this.synonymResults = [x];
          }
          callback();
        } catch (ex: any) {
          this.loggerService.logException(ex);
          callback();
        }
      }
    );
  }

  private searchRaw(callback: () => void = () => {}): void {
    this.searchService.searchRaw(
      this.searchCriteria,
      (res: SearchReqResponse) => {
        try {
          this.superSearching = false;
          res.registrations = this.registrationService.formatRegistrations(
            res.registrations
          );
          this.resultChange.emit(res);
          this.results = res;
          this.extractNewlyListedFromResults();
          callback();
        } catch (ex: any) {
          this.loggerService.logException(ex);
          callback();
        }
      }
    );
  }

  private extractNewlyListedFromResults(): void {
    //var auctionPlates: Registration[] = [];
    var newStylePlates: Registration[] = [];
    if (!this.results) return;
    this.results.registrations.forEach((reg: Registration, i: number) => {
      if (reg.style == 'current' && reg.registration.substring(2, 4) == '73')
        newStylePlates.push(reg);
    });

    const tempSearchReq = new SearchReqResponse('newly_listed', [
      ...newStylePlates.slice(0, 2),
    ]);
    this.newlyListedResults = tempSearchReq;
    this.newlyListedResultChange.emit(this.newlyListedResults);
  }
}
