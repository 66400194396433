<div class="component-container">
  <div class="swiper-container" *ngIf="ready">
    <div
      class="swiper-nav swiper-prev"
      [ngStyle]="{ display: imageOptions.length > 1 ? 'block' : 'none' }"
    >
      <mat-icon>chevron_left</mat-icon>
    </div>

    <swiper
      #swiperRef
      (init)="handleSwiperInit($event)"
      class="swiper swiper-border"
      [pagination]="dots && imageOptions && imageOptions.length > 1"
      [navigation]="swiperNavigation"
      [loop]="loop"
      [enabled]="true"
      [centeredSlides]="true"
      [slidesPerView]="'auto'"
      [loopedSlides]="1"
    >
      <ng-container *ngFor="let image of imageOptions; let i = index">
        <ng-template swiperSlide>
          <div class="image-slide" [attr.data-test]="'slide' + i">
            <img [src]="image.image" />
          </div>
        </ng-template>
      </ng-container>
    </swiper>

    <div
      class="swiper-nav swiper-next"
      [ngStyle]="{ display: imageOptions.length > 1 ? 'block' : 'none' }"
    >
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <div class="overlay-container" *ngIf="ready">
    <button
      *ngIf="showFullscreen"
      mat-mini-fab
      id="fullscreen-button"
      class="pla-mini-fab"
      [color]="'white'"
      (click)="handleFullscreenClick()"
    >
      <mat-icon>open_in_full</mat-icon>
    </button>

    <button
      *ngIf="shareEnabled()"
      mat-mini-fab
      id="share-button"
      class="pla-mini-fab"
      [color]="'white'"
      (click)="handleShareClick()"
    >
      <mat-icon>ios_share</mat-icon>
    </button>
  </div>
</div>
