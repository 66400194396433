<div class="page">
  <div class="content-container extended">
    <div class="px-3 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light">
        <pla-featured-plates
          [featureType]="npFeatured"
          [plateSize]="'smaller'"
          [priceFontSize]="16"
          [maxCount]="10"
          [sortBy]="'price'"
          [plateClass]="'flat'"
          [showIndex]="true"
        ></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative"
      >
        <pla-banner-image
          [carBanner]="'FERRARI_812'"
          [rawImage]="true"
          [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column p-4 p-md-4 new-banner-height"
            style="background-color: rgba(6, 34, 41, 0.45)"
          >
            <!-- HEADER TEXT -->
            <div
              class="text-white text-center w-100 align-self-center justify-content-center"
            >
              <p class="h1 mb-1 fw-bold">Names</p>
              <div>
                <p class="fw-light fs-6" style="color: #facf15">
                  The Curated Collection
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>
      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2"
      >
        <div class="px-0 px-md-4">
          <p class="w-100 d-block fw-light text-start">
            Discover the ideal fusion of personalisation and prestige with our
            exclusive name collection of personalised number plates, each one
            flawlessly showcases a name.
          </p>
          <p class="w-100 d-block fw-light text-start">
            Not limited to only English names, choose a country, and explore the
            perfect personalised number plates that represent names from around
            the world.
          </p>
          <p class="w-100 d-block fw-light text-start">
            A name number plate is a fantastic option to show your personality,
            as what's more personal than your name? Personalised name number
            plates remain as one of the most popular options for personalised
            plates
          </p>
        </div>

        <div
          class="px-0 px-md-4 d-flex align-items-center justify-content-between gap-2"
        >
          <div style="max-width: 45%">
            <mat-form-field class="no-margin" appearance="outline">
              <mat-label>Select A Country</mat-label>
              <mat-select
                multiple
                (selectionChange)="updateQueryString($event)"
                (openedChange)="filterChange($event)"
              >
                <ng-container *ngFor="let flagFilter of flagFilters">
                  <mat-option [value]="flagFilter.iso" class="flex-row-reverse">
                    <div
                      class="d-flex align-items-center justify-content-start gap-2"
                    >
                      <div style="width: 45px">
                        <img
                          class="d-block rounded"
                          src="https://flagcdn.com/{{ flagFilter['iso'] }}.svg"
                          style="max-width: 40px"
                          height="20"
                        />
                      </div>
                      <span>{{ flagFilter.name }}</span>
                    </div>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="d-flex align-items-center justify-content-start my-3">
            <div>
              <mat-checkbox [(ngModel)]="includeSold"
                >Include Sold Plates</mat-checkbox
              >
            </div>
          </div>
        </div>
        <ng-container *ngIf="loading">
          <div class="full spinner-loader">
            <div class="py-3">
              <mat-progress-spinner
                class="spinner cyan"
                mode="indeterminate"
                [color]="'#327b8d'"
                [diameter]="60"
              ></mat-progress-spinner>
            </div>
            <span>Loading Name Plates...</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!loading">
          <ng-container *ngFor="let letter of alphabet.split('')">
            <div
              class="mb-5"
              *ngIf="PlatesForLetter(letter).length > 0"
              id="letter-{{ letter }}"
            >
              <div class="border-bottom border-light py-2 mb-3">
                <h2 class="display-6 d-block w-100 text-center mx-auto fw-bold">
                  {{ letter }}
                </h2>
              </div>
              <div
                class="d-flex align-items-center justify-content-evenly justify-content-md-between gap-3 flex-wrap px-0 px-md-3"
              >
                <div *ngFor="let plate of PlatesForLetter(letter)">
                  <div>
                    <pla-plate
                      [plate]="plate"
                      [showPrice]="false"
                      [changeHover]="true"
                      [hoverText]="plate.meta_data['term']"
                      [mobileSmall]="true"
                      [customClass]="'flat fixed'"
                      [smallPlate]="false"
                      [showPercentage]="false"
                      [noMargin]="true"
                      [disabled]="!plate.available"
                      [white]="false"
                      [termFirst]="false"
                      [showIndex]="true"
                      [secondaryText]="plate.formattedRegistration"
                      [showSoldRibbon]="true"
                    ></pla-plate>
                  </div>
                  <p
                    class="d-flex mx-auto text-center w-100 mb-3 align-items-center justify-content-between justify-content-md-between
                    {{ plate.plateOwner == -1 ? 'text-muted fst-italic' : '' }}"
                  >
                    <span class="d-inline text-start me-1">{{
                      plate.term
                    }}</span>
                    <span class="">{{
                      (plate.priceBreakdown
                        ? plate.priceBreakdown.total
                        : plate.price / 100
                      ) | currency : "GBP" : "symbol" : "1.0-0"
                    }}</span>
                  </p>
                </div>

                <pla-plate
                  [blank]="true"
                  [customClass]="'flat fixed'"
                ></pla-plate>
                <pla-plate
                  [blank]="true"
                  [customClass]="'flat fixed'"
                ></pla-plate>
                <pla-plate
                  [blank]="true"
                  [customClass]="'flat fixed'"
                ></pla-plate>
                <pla-plate
                  [blank]="true"
                  [customClass]="'flat fixed'"
                ></pla-plate>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <div>
          <pla-faq [faq]="faq"></pla-faq>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" *ngIf="false">
    <pla-banner-image
      class="w-100"
      [rawImage]="true"
      [backgroundImage]="true"
      [carBanner]="'AVENTADOR_S_FOOTER'"
      [backgroundClass]="'w-100 desktop-mobile-bottom'"
    >
      <div
        class="d-flex align-items-stretch justify-content-end new-banner-height"
      >
        <div
          class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
            style="max-width: 450px"
          >
            <p class="fs-5 text-white">
              With the entire market of registrations on one single portal,
              industry leading optimised search facilities and even price
              comparison it's no wonder buyers are choosing PlateX to find their
              perfect plate.
            </p>

            <div class="align-self-end mt-4">
              <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                Begin Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </pla-banner-image>
  </div>
</div>

<div class="page" *ngIf="false">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container">
    <div class="center-content-container">
      <!-- SEARCH CRITERIA -->
      <div class="pla_card no-card no-left no-right mobile spacing no-top">
        <div>
          <pla-banner-image
            [carBanner]="'FERRARI_812'"
            [rawImage]="true"
            [backgroundImage]="true"
            [backgroundClass]="'desktop-mobile-background'"
          >
            <div
              class="d-flex align-items-center justify-content-between flex-column p-4 p-md-4"
              style="background-color: rgba(5, 71, 88, 0.5); min-height: 200px"
            >
              <!-- HEADER TEXT -->
              <div
                class="text-white w-100 align-self-start justify-content-start ms-0"
                style="max-width: 400px"
              >
                <p class="h1 mb-1 fw-bold">Top Name Plates</p>
                <div>
                  <p class="fw-light fs-6">
                    Discover the best 'name' plates available on the UK's Number
                    Plate Portal
                  </p>
                </div>
              </div>
            </div>
          </pla-banner-image>
        </div>
      </div>

      <!-- RESULTS -->
      <div class="pla_card no-card mobile spacing">
        <div class="py-3 px-1">
          <div
            class="d-block d-md-flex align-items-start justify-content-between"
          >
            <div>
              <h3 class="h2 fw-normal mb-3">UK Name Registration Plates</h3>
              <p class="mb-3 fs-6">
                Thousands of buyers take advantage of PlateX portal whole market
                search and price comparison technology to find their perfect
                plate.
              </p>
              <p class="mb-0 fs-6">
                Introducing the top list of named look-alike registrations
                within the UK format. This curated list reflects common names,
                allowing users to personalize their license plates in a playful
                and memorable manner.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
