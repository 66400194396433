<div class="w-100 pt-3" *ngIf="sectionFeatureType">
  <h2 *ngIf="showTitle" class="text-center fw-bold mb-0 mt-1">
    {{ sectionFeatureType.title }}
  </h2>
  <div *ngIf="!loading; else loadingSpinner" class="pla_card no-card padding">
    <div *ngIf="registrations && registrations.length > 0; else noListings">
      <div class="featured-container" [style.gap]="showPrice ? '15px' : '30px'">
        <ng-container
          *ngFor="let registration of registrations; let index = index"
        >
          <div class="featured-item">
            <pla-plate
              [plateSize]="plateSize"
              [plate]="registration"
              secondaryText="{{ index + 1 | ordinalNumber }}"
              [showIndex]="showIndex"
              [mobileTiny]="true"
              [showPrice]="showPrice"
              [showPence]="false"
              [penceLimit]="10000"
              [priceFontSize]="priceFontSize"
              [priceClass]="priceClass"
              [customClass]="plateClass"
            ></pla-plate>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-template #noListings>
      <div class="featured-container">
        <p style="text-align: center; width: 100%; margin: 20px auto">
          No featured registrations at this time.
        </p>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="full spinner-loader">
    <div class="py-3">
      <mat-progress-spinner
        class="spinner cyan"
        mode="indeterminate"
        [color]="'#327b8d'"
        [diameter]="60"
      ></mat-progress-spinner>
    </div>
    <span>Loading Featured Plates...</span>
  </div>
</ng-template>
