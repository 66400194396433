import { PlateOwner } from './enums/plateOwner';

export class UserAlert {
  public id: number = 0;
  public userId: string = null;
  public term: string = '';
  public dateAdded: Date = new Date();

  public registrationStyle: string = '';
  public budget: string = '';
  public saleType: string;
}
