import { Pipe, PipeTransform } from '@angular/core';
import { GlossaryService } from '../components/glossary/glossary.component';

@Pipe({
  name: 'glossarylink',
})
export class GlossaryLinkPipe implements PipeTransform {
  transform(term: string, text: string): string {
    return `<a class="glossary-link" target="_blank" href="/info/glossary/${term.toLowerCase()}">${text}</a>`;
  }
}

@Pipe({
  name: 'glossarydef',
})
export class GlossaryDefPipe implements PipeTransform {
  constructor(private glossaryService: GlossaryService) {}

  transform(text: string): string {
    var def = this.glossaryService.getGlossaryTerm(text);
    if (def == null) return '';
    return def.definition;
  }
}
