import { ListingStatus } from './enums/listingStatus';
import { ListingType } from './enums/listingType';

export class Listing {
  public firstName: string;
  public lastName: string;

  public id: number;
  public advertId: string;
  public registration: string;
  public style: string;
  public searchTerm: string;
  public price: number;
  public listingType: ListingType = ListingType.Platex_Commision;
  public status: ListingStatus = ListingStatus.Active;

  public legalOwner: boolean;
  public taxed_or_sorn: boolean;
  public on_retention: boolean;

  constructor() {}

  public build(
    _registration: string,
    _price: number,
    _type: ListingType,
    _status: ListingStatus
  ): Listing {
    this.registration = _registration;
    this.price = _price;
    this.listingType = _type;
    this.status = _status;
    return this;
  }

  public listingTypeString(): string {
    if (this.listingType == ListingType.Platex_Commision)
      return 'Platex Commision Plan';
    if (this.listingType == ListingType.Standard_Monthly)
      return 'Standard Monthly Plan';
    if (this.listingType == ListingType.Standard_3_Monthly)
      return 'Standard 3 Month Plan';
    if (this.listingType == ListingType.Standard_Annually)
      return 'Standard Annual Plan';
  }

  public listingStatusString(): string {
    if (this.status == ListingStatus.Active) return 'Active';
    if (this.status == ListingStatus.Suspended) return 'Suspended';
    if (this.status == ListingStatus.Sold) return 'Sold';
  }
}
