<div class="page">
  <div class="content-container extended">
    <div class="side-container mb-3 px-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light">
        <div>
          <pla-featured-plates
            [featureType]="rsFeatured"
            [plateSize]="'featured'"
            [priceFontSize]="12"
            [maxCount]="14"
            [plateClass]="'flat'"
          ></pla-featured-plates>
        </div>

        <div class="border-top">
          <pla-featured-plates
            [featureType]="raFeatured"
            [plateSize]="'featured'"
            [priceFontSize]="12"
            [maxCount]="14"
            [plateClass]="'flat'"
          ></pla-featured-plates>
        </div>
      </div>
    </div>
    <div class="center-content-container">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative"
      >
        <div class="w-100 p-2 m-auto">
          <img
            class="w-auto d-block mx-auto"
            style="max-height: 65vh; aspect-ratio: auto; max-width: 100%"
            *ngIf="selectedImage"
            [src]="selectedImage.image"
          />
        </div>
        <div>
          <div class="m-auto d-flex align-items-center justify-content-center">
            <div class="text-input-container text-plate-input w-content">
              <div
                class="input-container plate-background w-content flex-shrink"
              >
                <input
                  style="width: 10ch"
                  class="plate-font"
                  matInput
                  required
                  [(ngModel)]="registrationString"
                  placeholder="PLATE"
                  type="text"
                />
              </div>
            </div>
            <button
              mat-flat-button
              (click)="UpdateImage()"
              class="platex-button ms-1 h-100"
            >
              Update
            </button>
          </div>

          <div class="text-center mt-3 px-2">
            <h4 class="fs-4">The Curated Collection</h4>
            <p>
              Visulaise your perfect private plate on any a range of exceptional
              vehicles with our plate visualiser
            </p>
          </div>
        </div>
        <div>
          <ng-container *ngFor="let letter of alphabet.split('')">
            <div
              class="mb-5 px-3 mx-auto"
              *ngIf="ImagesForLetter(letter).length > 0"
              style="max-width: 1000px"
            >
              <div class="border-bottom py-2 mb-3">
                <h2 class="display-6 d-block w-100 text-start ms-0 fw-bold">
                  {{ letter }}
                </h2>
              </div>
              <div
                class="m-auto mt-2 mb-5 d-flex justify-content-between align-items-start flex-wrap gap-3"
              >
                <ng-container *ngFor="let config of ImagesForLetter(letter)">
                  <div
                    class="my-2 flex-fill"
                    style="
                      cursor: pointer;
                      min-width: 175px;
                      max-width: 500px;
                      width: 30%;
                    "
                    (click)="updateConfig(config)"
                  >
                    <img
                      class="d-block w-100"
                      [src]="config.builtConfig.config.imageInfo.path"
                    />
                    <p class="m-0 text-center w-100">{{ config.carName }}</p>
                  </div>
                </ng-container>
                <div
                  class="my-2 flex-fill"
                  style="
                    cursor: pointer;
                    min-width: 175px;
                    max-width: 400px;
                    width: 30%;
                  "
                ></div>
                <div
                  class="my-2 flex-fill"
                  style="
                    cursor: pointer;
                    min-width: 175px;
                    max-width: 400px;
                    width: 30%;
                  "
                ></div>
                <div
                  class="my-2 flex-fill"
                  style="
                    cursor: pointer;
                    min-width: 175px;
                    max-width: 400px;
                    width: 30%;
                  "
                ></div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
