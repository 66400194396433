import { ListingType } from './enums/listingType';

export class PlatexListingOrder {
  id: number;
  advertId: string;
  registration: string;
  style: string;
  searchTerm: string;
  score: number;
  userId: string;
  price: number;
  available: boolean;
  active: boolean;
  listingType: ListingType;
  status: number;
  created: string;
  subscription: Subscription[];

  public getActiveSub(): Subscription {
    if (this.subscription == undefined) return null;
    const sub = this.subscription.filter((ls) => ls.active);
    if (sub.length != 1) return null;
    return sub[0];
  }
}

export interface Subscription {
  id: string;
  subscriptionId: string;
  checkoutSessionId: string;
  active: boolean;
  activated: string;
  cancelled: string;
  price: number;
}
