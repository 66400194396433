import { Component, Input, OnInit } from '@angular/core';
import { PlateRatingResponse } from 'src/app/models/plateRatingResponse';
import { AdminService } from 'src/app/services/admin-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { SessionService } from 'src/app/services/session-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-rating-bar',
  templateUrl: './rating-bar.component.html',
  styleUrls: ['./rating-bar.component.scss'],
})
export class RatingBarComponent implements OnInit {
  @Input('registration') public registration: string;
  @Input('term') public term: string;
  @Input('likes') public likes: number = 0;
  @Input('dislikes') public dislikes: number = 0;
  @Input('canVote') public canVote: boolean = false;

  public isAdmin: boolean = false;
  @Input('percentage') public percentage: number;

  public showNumeric: boolean = false;

  constructor(
    private registrationService: RegistrationService,
    private userService: UserService,
    private sessionService: SessionService
  ) {
    this.isAdmin = this.sessionService.isAdmin();
  }

  ngOnInit(): void {}

  public hasRating(): boolean {
    return this.isActive(true) || this.isActive(false);
  }

  public viewDebugInfo(): void {
    window.open(
      `https://81ugpzvec7.execute-api.eu-west-2.amazonaws.com/Prod/search?process=score&term=${this.term}&registration=${this.registration}`,
      '_blank'
    );
  }

  public isActive(ratingStatus: boolean): boolean {
    var rating = this.userService.localRating(this.registration, this.term);
    if (rating == null) return false;
    return rating.rating == ratingStatus;
  }

  public handleRatingClick(rating: boolean): void {
    this.registrationService
      .setPlateRating(this.registration, rating, this.term)
      .subscribe((_: PlateRatingResponse) => {
        // Update view
        this.likes = _.likes;
        this.dislikes = _.dislikes;
        this.userService.updateUserRatings(
          this.registration,
          this.term,
          rating
        );
      });
  }

  public calculatedWidth(): string {
    var total = this.likes + this.dislikes;
    if (total == 0) return '50%';
    if (this.likes == 0) return '0%';
    var perc = (this.likes / total) * 100;
    return `${perc}%`;
  }
}
