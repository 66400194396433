<div
  id="sticky-bby"
  class="position-sticky bg-white"
  style="top: 54px; z-index: 900"
>
  <div
    class="d-flex align-items-center justify-content-start border-bottom gap-2 py-1 px-2 px-md-1"
  >
    <ng-container *ngFor="let link of links">
      <div
        class="px-2 py-1 rounded-2 {{
          link.active ? 'bg-success text-white' : 'text-dark'
        }}"
        style="cursor: pointer"
        (click)="ChangeLink(link)"
      >
        <small class="mb-0">{{ link.viewName }}</small>
      </div>
    </ng-container>
  </div>
</div>
