export class CompareResult {
  public site: string;
  public siteLogo: string;
  public status: string;
  public status_class: string;
  public url: string;
  public price: number;
  public finance: boolean;
  public finance_pretty: string;
  public transferService: number;
  public physicalPlatePrice: number;
  public trustPilotScore: number;
  public trustPilotLink: string;
  public trustPilotRatingCount: number;
  public message: string;
  public viewName: string;
  public domainSuffix: string;
  public paymentMethods: string[];
  public financeMethods: string[];
  public postagePrice: number;

  // CALCULATED
  public calculated: boolean;
  public total: number;
  public platexListing: boolean;

  public New(viewName: string, site: string): CompareResult {
    this.site = site;
    this.viewName = viewName;
    return this;
  }
}
