import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPCONSTANTS } from '../http/http-constants';
import { FormattedPlateResponse } from '../models/formattedPlateResponse';
import { Listing } from '../models/listing';
import { CreateListingResponse } from '../models/createListingResponse';
import { ListingType } from '../models/enums/listingType';
import { ListingPricePoint } from '../models/listingPricePoint';
import { PlatexListingOrder } from '../models/platexListingOrder';
import TrackingMetric from '../models/trackingMetric';

@Injectable({ providedIn: 'root' })
export class ListingService {
  constructor(private http: HttpClient) {}

  public CreateListing(listing: Listing): Observable<CreateListingResponse> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/listing/add`;
    return this.http.post<CreateListingResponse>(url, listing);
  }

  public DeleteListing(advertId: string): Observable<void> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/listing/${advertId}`;
    return this.http.delete<void>(url);
  }

  public UpdateListing(
    listing: PlatexListingOrder
  ): Observable<PlatexListingOrder> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/listing/${listing.advertId}/update`;
    return this.http.post<PlatexListingOrder>(url, listing);
  }

  public GetUserListings(): Observable<PlatexListingOrder[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/listing/user`;
    return this.http.get<PlatexListingOrder[]>(url);
  }

  public GetListing(id: string): Observable<PlatexListingOrder> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/listing/${id}`;
    return this.http.get<PlatexListingOrder>(url);
  }

  public GetListingPrice(
    listingType: ListingType
  ): Observable<ListingPricePoint> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/listing/pricing/${listingType}`;
    return this.http.get<ListingPricePoint>(url);
  }

  public FeaturedListings(): Observable<Listing[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/listing/featured`;
    return this.http.get<Listing[]>(url);
  }

  public GetListingMetrics(registration: string): Observable<TrackingMetric[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/tracking/${registration}/tracking`;
    return this.http.get<TrackingMetric[]>(url);
  }

  public MessageListing(
    registration: string,
    advertId: string
  ): Observable<void> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/listing/${registration}/${advertId}/message`;
    return this.http.get<void>(url);
  }
}
