<div class="page">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container" id="results-section">
    <div class="center-content-container">
      <!-- SEARCH CRITERIA -->
      <div class="pla_card no-card no-left no-right mobile spacing no-top">
        <pla-banner-image [taller]="true" [carBanner]="'FERRARI_275'">
          <div class="page-title">
            <h1>DATELESS</h1>
            <span class="sub">Search by style and length</span>
          </div>
        </pla-banner-image>

        <div class="search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <!-- DEFINE SEARCH CRITERIA BASED ON STYLE AND SIZE -->
              <div
                *ngIf="selectedGroup && selectedOption"
                class="search-selection-container"
              >
                <div class="input-container">
                  <pla-wildcard-character-select
                    (inlineSearch)="searchWithCriteria($event)"
                    (newFormat)="newSearchFormat($event)"
                    [emitOnChange]="true"
                    [prefix]="prefix"
                    [numbers]="numbers"
                    [letters]="letters"
                    [style]="'dateless'"
                    [length]="selectedOption.value"
                    [any_character]="any_character"
                    [no_character]="no_character"
                    [withPadding]="false"
                    [forceSearchButton]="false"
                    [padLeft]="selectedOption.value.length - 1 < 7"
                    [fullWidth]="true"
                    [regenerate]="recalculateInput"
                    [loading]="searching || selecting"
                    [animateOnRecalc]="true"
                  ></pla-wildcard-character-select>
                </div>
              </div>
              <!-- <span class="header">Choose Dateless Style</span> -->
              <div style="margin: 10px auto"></div>
            </div>
          </div>
        </div>

        <div class="header" style="padding: 0 10px 10px">
          <pla-switch-toggle
            [selectedTabId]="activeSwitch"
            [tabs]="tabs"
            (switchChange)="SwitchChange($event)"
          ></pla-switch-toggle>
          <span
            style="
              display: block;
              margin: auto;
              text-align: center;
              margin-top: 10px;
            "
            >Choose Dateless Style</span
          >
        </div>

        <!-- SWIPER LENGTH OPTIONS -->
        <div class="length-options-container">
          <div class="swiper-container">
            <div class="swiper-nav swiper-prev" (click)="slideDir(false)">
              <mat-icon>chevron_left</mat-icon>
            </div>

            <!-- [navigation]="swiperNavigation" -->
            <swiper
              [pagination]="false"
              [loop]="true"
              [centeredSlides]="true"
              [slidesPerView]="3"
              [allowTouchMove]="!searching && !selecting"
              [enabled]="!searching && !selecting"
              [breakpoints]="swiperBreakpoints"
              #swiperRef
              (init)="swiperInit($event)"
              (slideChange)="onSwipe()"
              class="swiper swiper-border"
            >
              <ng-container *ngFor="let option of optionGroups">
                <ng-template swiperSlide>
                  <div
                    class="length-option swiper-child"
                    [ngClass]="{ selected: selectedGroup.name == option.name }"
                    (click)="slideTo(option)"
                  >
                    <div
                      class="plate-option-container"
                      [ngClass]="{
                        active:
                          letterFirst ||
                          showBoth ||
                          (selectedGroup && selectedGroup.options.length == 1)
                      }"
                    >
                      <pla-plate
                        *ngIf="plateSizeBreakpoint(); else desktopFirstPlate"
                        [rawPlate]="option.options[0]['viewValue']"
                        [xSmallPlate]="true"
                        [mobileSmall]="false"
                        [white]="true"
                        [openModal]="false"
                      ></pla-plate>
                      <ng-template #desktopFirstPlate>
                        <pla-plate
                          [rawPlate]="option.options[0]['viewValue']"
                          [mobileSmall]="false"
                          [smallPlate]="true"
                          [white]="true"
                          [openModal]="false"
                        ></pla-plate>
                      </ng-template>
                    </div>
                    <div
                      class="plate-option-container"
                      [ngClass]="{
                        active:
                          !letterFirst ||
                          showBoth ||
                          (selectedGroup && selectedGroup.options.length == 1)
                      }"
                    >
                      <div
                        *ngIf="option.options.length > 1; else blankPlateSpace"
                      >
                        <pla-plate
                          *ngIf="plateSizeBreakpoint(); else desktopSecondPlate"
                          [rawPlate]="option.options[1]['viewValue']"
                          [mobileSmall]="false"
                          [xSmallPlate]="true"
                          [openModal]="false"
                        ></pla-plate>
                        <ng-template #desktopSecondPlate>
                          <pla-plate
                            [rawPlate]="option.options[1]['viewValue']"
                            [mobileSmall]="false"
                            [smallPlate]="true"
                            [openModal]="false"
                          ></pla-plate>
                        </ng-template>
                      </div>
                    </div>
                    <ng-template #blankPlateSpace>
                      <pla-plate
                        *ngIf="
                          plateSizeBreakpoint();
                          else blankDesktopPlateSpace
                        "
                        [rawPlate]="'_'"
                        style="opacity: 0"
                        [mobileSmall]="false"
                        [xSmallPlate]="true"
                        [openModal]="false"
                      ></pla-plate>
                    </ng-template>
                    <ng-template #blankDesktopPlateSpace>
                      <pla-plate
                        [rawPlate]="'_'"
                        style="opacity: 0"
                        [mobileSmall]="false"
                        [smallPlate]="true"
                        [openModal]="false"
                      ></pla-plate>
                    </ng-template>

                    <div
                      class="label"
                      [ngClass]="{
                        active: false && selectedGroup.name == option.name
                      }"
                    >
                      <span
                        *ngIf="
                          true || selectedGroup.name != option.name;
                          else labelSearchButton
                        "
                        >{{
                          letterFirst || showBoth
                            ? option.viewName
                            : option.reverseViewName
                        }}</span
                      >
                    </div>
                  </div>
                </ng-template>

                <ng-template #labelSearchButton>
                  <span
                    (click)="forceSearch()"
                    class="option-search-button"
                    [ngClass]="{ disabled: searching || selecting }"
                    >{{ searching || selecting ? "SEARCHING" : "SEARCH" }}</span
                  >
                </ng-template>
              </ng-container>
            </swiper>
            <div class="swiper-nav swiper-next" (click)="slideDir(true)">
              <mat-icon>chevron_right</mat-icon>
            </div>
          </div>
          <span style="display: block; margin: auto; text-align: center"
            >Number of Characters</span
          >
        </div>
        <div class="p-2 text-center">
          <button
            mat-raised-button
            class="my-2 px-4 py-0 fs-5 platex-button mx-auto"
            (click)="forceSearch()"
          >
            Search
          </button>
        </div>
      </div>

      <div
        *ngIf="canSearch; else infoSection"
        class="pla_card no-card mobile spacing"
      >
        <div *ngIf="!searching || !selecting; else loadingSpinner">
          <!-- SEARCH RESULTS -->
          <div
            *ngIf="
              !showBoth && selectedGroup && selectedGroup.options.length > 1
            "
          >
            <pla-search-result-table
              [searching]="searching || selecting"
              [searchTitle]="searchTitle('dateless')"
              [results]="results.main"
              [resultChange]="main_resultChange"
              [paginationPageSize]="50"
            ></pla-search-result-table>
          </div>

          <!-- SEARCH RESULTS -->
          <div
            *ngIf="
              showBoth || (selectedGroup && selectedGroup.options.length == 1)
            "
          >
            <pla-search-result-table
              [searching]="searching || selecting"
              [searchTitle]="searchTitle('dateless combined')"
              [results]="allResults"
              [resultChange]="all_resultChange"
              [paginationPageSize]="50"
            ></pla-search-result-table>
          </div>
        </div>
      </div>

      <ng-template #loadingSpinner>
        <div class="full spinner-loader">
          <div>
            <mat-progress-spinner
              class="spinner cyan"
              mode="indeterminate"
              [color]="'#327b8d'"
              [diameter]="60"
            ></mat-progress-spinner>
          </div>
        </div>
      </ng-template>

      <ng-template #infoSection>
        <div class="pla_card no-card mobile spacing padding">
          <div class="px-4">
            <div>
              <div class="text-title heading">
                <h2 class="border-bottom mb-1">
                  <b class="strong">Dateless-By-Size</b>
                </h2>
              </div>
              <div class="text-section">
                <div class="text-paragraph">
                  <p>
                    This useful tool searches all the
                    <pla-glossary-term-word
                      [term]="'Dateless Registration'"
                      [text]="'Dateless'"
                    ></pla-glossary-term-word>
                    number plates specifically by the number of characters.
                    Enter letters or numbers into the plate search box to refine
                    the results further or leave blank to review all plates
                    available in that series.
                  </p>
                </div>
              </div>

              <div class="text-title heading mt-3">
                <h2 class="border-bottom mb-1">
                  <b class="strong">Did you know...</b>
                </h2>
              </div>
              <div class="text-section">
                <div class="text-paragraph">
                  <p class="indent">
                    Single/Single or 1/1 registrations are the most highly
                    sought-after registrations and command incredible prices.
                    The pinnacle of these are the number ‘1’ registration of
                    which there are only 46 combinations available. The most
                    expensive 1/1 registration currently available is
                    <pla-plate
                      class="d-inline-block"
                      [rawPlate]="'F 1'"
                      [useCustom]="true"
                      [customSize]="9"
                      [customFontSize]="17"
                      [inlineText]="true"
                    ></pla-plate>
                  </p>
                </div>
              </div>

              <div class="text-section mt-3">
                <div class="text-paragraph">
                  <div class="faq-item">
                    <mat-icon>help_outline</mat-icon>
                    <h4>Can I put a Dateless registration on my vehicle?</h4>
                  </div>
                  <p class="indent">
                    You are not allowed to make a vehicle appear younger by
                    transferring a more recent registration. However,
                    <pla-glossary-term-word
                      [term]="'Dateless Registration'"
                      [text]="'Dateless'"
                    ></pla-glossary-term-word>
                    registrations have no date indicator so they can be
                    transferred to any age vehicle.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- FEATURED LISTING -->
          <div class="featured-container mt-5">
            <div class="px-4">
              <h2 class="border-bottom mb-1">Featured Dateless</h2>
            </div>
            <div>
              <pla-featured-plates
                [featureType]="datelessFeatured"
                [showTitle]="false"
              ></pla-featured-plates>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
