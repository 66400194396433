import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pla-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.css']
})
export class AuctionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
