export const HTTPCONSTANTS = {
  plato_search_protocol: '[HTTP-PLATO-SEARCH-PROTOCOL]',
  plato_search_base: '[HTTP-PLATO-SEARCH-BASE]',
  platoProtocol: '[HTTP-PLATO-PROTOCOL]',
  protocol: '[HTTP-API-PROTOCOL]',
  apiBaseAddress: '[HTTP-API-BASE-ADDRESS]',
  platoBaseAddress: '[HTTP-PLATO-BASE-ADDRESS]',
  serverError: 'Something Went Wrong',
  incorrectLogin: 'Incorrect details, please try again.',
};
