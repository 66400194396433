<div *ngIf="(results && resultChange) || searching">
  <pla-search-result-table
    [headerText]="header"
    [results]="results"
    [searching]="searching"
    [showFilter]="false"
    [showSort]="false"
    [showToggle]="true"
    [showFullSeries]="true"
    [paginationPageSize]="10"
    [resultChange]="resultChange"
  ></pla-search-result-table>
</div>
