import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SwitchTab } from '../components/switch-toggle/switch-toggle.component';
import { BYOTermBreakdown, BYOTermResponse } from '../models/byoTermResponse';

@Component({
  selector: 'pla-byo-search-input',
  templateUrl: './byo-search-input.component.html',
  styleUrls: ['./byo-search-input.component.scss'],
})
export class ByoSearchInputComponent implements OnInit {
  public tabs: SwitchTab[] = [
    new SwitchTab('Current', 'current'),
    new SwitchTab('Prefix', 'prefix'),
    new SwitchTab('Suffix', 'suffix'),
  ];
  public activeSwitch: string = 'current';

  @Input() public padLeft: boolean = true;
  @Input('breakdowns') public inputBreakdowns: BYOTermResponse = null; // FROM API ####TGE / ####TGE / TGE####
  @Input('inlineSearch') public inlineSearch: boolean = false;
  @Input('defaultStyle') public style: string = 'current';
  @Input() public withPadding: boolean = true;

  @Input('prefix') public prefix: string = '';
  @Input('numbers') public numbers: string = '';
  @Input('letters') public letters: string = '';
  @Output('searchOutput') public searchOutput: EventEmitter<BYOTermBreakdown> =
    new EventEmitter<BYOTermBreakdown>();

  public regenerateInput: EventEmitter<void> = new EventEmitter<void>();
  public regenerateWithBreakdowns: EventEmitter<BYOTermBreakdown> =
    new EventEmitter<BYOTermBreakdown>();
  public no_character: string = '﹣';
  public any_character: string = '?';

  constructor() {}

  ngOnInit(): void {
    this.calcActiveIndex();
  }

  private calcActiveIndex(): void {
    this.activeSwitch = this.tabs.find((t) => t.id == this.style).id;
  }

  public SwitchChange(tab: SwitchTab): void {
    this.activeSwitch = tab.id;
    this.style = tab.id;
    setTimeout(() => {
      if (this.inputBreakdowns) {
        this.regenerateWithBreakdowns.emit(this.inputBreakdowns[this.style]);
      } else {
        this.regenerateInput.emit();
      }
    }, 100);
  }

  public doInlineSearch(term: BYOTermBreakdown): void {
    this.searchOutput.emit(term);
  }
}
