import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginModel } from 'src/app/models/loginModel';
import { RegisterModel } from 'src/app/models/registerModel';
import { SessionService } from 'src/app/services/session-service';

@Component({
  selector: 'pla-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  public registerModel: RegisterModel = new RegisterModel();
  public registerErrors: string[] = [];

  public registerForm = new FormGroup({
    email: new FormControl(this.registerModel.email, [
      Validators.required,
      Validators.email,
    ]),
    firstName: new FormControl(this.registerModel.firstName, [
      Validators.required,
    ]),
    lastName: new FormControl(this.registerModel.lastName, [
      Validators.required,
    ]),
    password: new FormControl(this.registerModel.password, [
      Validators.required,
    ]),
    passwordRepeat: new FormControl(this.registerModel.passwordRepeat, [
      Validators.required,
    ]),
  });

  constructor(
    private sessionService: SessionService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
    if (sessionService.isLoggedIn()) {
      this.router.navigate(['/account']);
    }
  }

  ngOnInit(): void {}

  public register(): void {
    if (this.registerForm.invalid) return;
    this.registerModel = (this.registerModel.email =
      this.registerForm.getRawValue()) as RegisterModel;
    this.registerModel.signupReason = 'standard';
    this.makeRegisterRequest(this.registerModel);
  }

  public login(): void {
    this.router.navigate(['/login']);
  }

  private makeRegisterRequest(registerDetails: RegisterModel): void {
    this.registerErrors = [];
    this.sessionService.register(
      registerDetails,
      (success: boolean, message: string, errors: string[]) => {
        if (success) {
          this.router.navigate(['/login']).then(() => {
            this.snackbar.open('Sign up complete!', 'Close', {
              duration: 3000,
            });
          });
          this.makeLoginRequest(
            this.sessionService.registerToLogin(registerDetails)
          );
        } else {
          if (errors.length > 0) {
            this.registerErrors = errors.slice(0, 1);
            this.snackbar.open(message, 'Close', { duration: 5000 });
          } else {
            this.snackbar.open('Unknown error occured', 'Close', {
              duration: 5000,
            });
          }
        }
      }
    );
  }

  private makeLoginRequest(loginDetails: LoginModel): void {
    this.sessionService.login(
      loginDetails,
      (success: boolean, message: string) => {
        if (!success) {
          this.snackbar.open(message, 'Close', { duration: 5000 });
        } else {
          this.router.navigate(['/account']);
        }
      }
    );
  }
}
