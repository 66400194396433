export default class TrackingMetric {
  registration: string;
  impressionCount: number = 0;
  comparedCount: number = 0;
  clickCount: number = 0;
  day: Date;

  public AddRegistration(reg: string): TrackingMetric {
    this.registration = reg;
    return this;
  }

  public AddDate(date: Date): TrackingMetric {
    this.day = date;
    return this;
  }
}
