<div
  class="page"
  [ngClass]="{ 'component-container': inlineSearch }"
  [style.min-height]="inlineSearch ? '0' : '100vh'"
>
  <div class="page-description-container"></div>
  <div
    class="main-content-container"
    [ngClass]="{ 'no-ds': inlineSearch, 'content-container': !inlineSearch }"
    [style.min-height]="inlineSearch ? '0' : '100vh'"
    id="results-section"
  >
    <div class="center-content-container">
      <!-- SEARCH CRITERIA -->
      <div
        [ngClass]="{
          'pla_card mobile no-card no-left no-right spacing no-top overlap':
            !inlineSearch
        }"
      >
        <div *ngIf="!inlineSearch">
          <pla-banner-image [carBanner]="'RR_DEFENDER'">
            <div class="page-title">
              <h1>BUILD-YOUR-OWN</h1>
              <span class="sub">Starting from £250</span>
            </div>
          </pla-banner-image>
          <div class="search-input-container">
            <div class="search-area inner">
              <div class="box-container">
                <pla-byo-search-input
                  [defaultStyle]="style"
                  [prefix]="activeBreakdown().prefix"
                  [numbers]="activeBreakdown().numbers"
                  [letters]="activeBreakdown().letters"
                  (searchOutput)="doInlineSearch($event)"
                  [padLeft]="false"
                  [withPadding]="false"
                ></pla-byo-search-input>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="inlineSearch">
          <div class="-search-input-container">
            <div class="search-area inner">
              <div class="box-container">
                <pla-byo-search-input
                  [defaultStyle]="style"
                  [prefix]="activeBreakdown().prefix"
                  [numbers]="activeBreakdown().numbers"
                  [letters]="activeBreakdown().letters"
                  [breakdowns]="styleBreakdowns"
                  (searchOutput)="doInlineSearch($event)"
                  [padLeft]="false"
                  [withPadding]="false"
                ></pla-byo-search-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- SEARCH RESULTS -->
      <div *ngIf="inlineSearch || hasSearchCriteria; else infoSection">
        <div
          [ngClass]="{
            'pla_card no-card mobile spacing padding': !inlineSearch
          }"
        >
          <pla-search-result-table
            [searching]="loading"
            [searchTitle]="searchTerm"
            [searchType]="'plates'"
            [resultType]="''"
            [pluralResultType]="''"
            [showFilter]="false"
            [results]="results"
            [resultChange]="resultChange"
          ></pla-search-result-table>
        </div>
      </div>

      <ng-template #infoSection>
        <div class="pla_card no-card mobile spacing padding">
          <div class="px-4">
            <div class="text-section">
              <div class="text-title heading">
                <h2>Build-Your-Own</h2>
              </div>
              <div class="text-paragraph">
                <p>
                  Build-Your-Own registration from either the
                  <pla-glossary-term-word
                    [term]="'Current or New Style Registration'"
                    [text]="'Current'"
                  ></pla-glossary-term-word>
                  style (AA12 ABC),
                  <pla-glossary-term-word
                    [term]="'Prefix Style Registration Plate'"
                    [text]="'Prefix'"
                  ></pla-glossary-term-word>
                  (A123 ABC) or
                  <pla-glossary-term-word
                    [term]="'Suffix Style Registration Plate'"
                    [text]="'Suffix'"
                  ></pla-glossary-term-word>
                  (ABC 123A) registration.
                </p>
              </div>
            </div>

            <div class="text-title heading mt-3">
              <h2 class="border-bottom mb-1">
                <b class="strong">How to...</b>
              </h2>
            </div>
            <div class="text-section">
              <div class="text-paragraph">
                <div class="indent">
                  <p class="mb-1">
                    1. Use the tabs to pick a registration series.
                  </p>
                  <p class="mb-0">
                    2. Select the letters from the drop-down to make your word.
                  </p>
                  <p class="mb-0">
                    Note: Each series of registration must have numbers in set
                    locations, these are;
                  </p>
                  <p class="mb-0 ms-3">- 3rd and 4th for Current Style,</p>
                  <p class="mb-0 ms-3">
                    - Only 2nd, 2nd and 3rd or 2nd, 3rd and 4th for Prefix,
                  </p>
                  <p class="mb-0 ms-3">
                    - Only 4th, 4th and 5th / 4th, 5th and 6th for Suffix.
                  </p>
                </div>
                <div class="indent mt-1">
                  <p>
                    In many instances a number can be used to represent the
                    letters such as 5 for S, 4 for A and 7 for T.
                  </p>
                  <p>
                    Also note that when building a Current Series plate the
                    number represent a year code so availablity will depend on
                    whether the combination has been released.
                  </p>
                  <p>
                    For eaxmple if you wished to make the perfect plate 'BA77
                    ERY' this would require the number 77 which will not be
                    released until September 2027! However by entering 'Battery'
                    info our smart-search you can find other perfect plates from
                    other series such as the Prefix plate 'B477 ERY' and Suffix
                    plate 'BAT 732Y'.
                  </p>
                </div>
              </div>
            </div>

            <div class="text-section mt-3">
              <div class="text-paragraph">
                <div class="faq-item">
                  <mat-icon>help_outline</mat-icon>
                  <h4>The registration I tried to build is not there?</h4>
                </div>
                <p class="indent">
                  This could mean that the registration was not part of the
                  series or has already been sold. You can choose Notify in the
                  main menu and should it ever come back to the market you can
                  be informed!
                </p>

                <div class="faq-item">
                  <mat-icon>help_outline</mat-icon>
                  <h4>Can I put any of these registrations on my vehicle?</h4>
                </div>
                <p class="indent">
                  You are not allowed to make a vehicle appear younger by
                  transferring a more recent registration. Simply click on the
                  registration to see the minimum age of a vehicle needed for
                  that registration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
