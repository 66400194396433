import { AfterViewInit, Component, Input } from '@angular/core';
import { SaleType, SoldPlate } from 'src/app/models/soldPlate';

declare var bootstrap: any;

@Component({
  selector: 'pla-sold-data-table',
  templateUrl: './sold-data-table.component.html',
  styleUrls: ['./sold-data-table.component.scss'],
})
export class SoldDataTableComponent implements AfterViewInit {
  @Input('data') public data: SoldPlate[] = [];

  constructor() {}

  ngAfterViewInit(): void {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  public tooltipPos(): string {
    if (window.innerWidth >= 850) {
      return 'left';
    }
    return 'top';
  }

  public saleTypeMapper(data: SoldPlate, saleType: SaleType): string {
    switch (saleType) {
      case SaleType.DVLA_AUTION:
        return 'DVLA Auction';

      case SaleType.DVLA:
        return 'DVLA';

      case SaleType.PLATEX:
        return 'Platex';

      case SaleType.THIRD_PARTY:
        return data.seller == null || data.seller == ''
          ? 'Sold / Withdrawn'
          : data.seller;

      case SaleType.CURRENT_LISTING:
        return 'Listed -';

      case SaleType.INITIAL_PRICE:
        return 'Listed -';

      case SaleType.DVLA_AUCTION_IMPORT:
        return 'DVLA Auction';

      case SaleType.THIRD_PARTY_AUCTION_IMPORT:
        return data.seller;

      case SaleType.PRICE_CHANGE:
        return 'Price Change';

      case SaleType.CUSTOM:
        return data.seller;

      default:
        return 'Unknown';
    }
  }
}
