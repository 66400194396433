<div
  *ngIf="!plateStyle; else plateStyleInput"
  class="new-option-search-container"
  [ngClass]="{
    inline: inlineComponent,
    'search-input-sizing': defaultSizing,
    'mobile-search-area': smaller
  }"
>
  <div class="input-container">
    <input
      class="plate-font"
      (keydown)="EnterSubmit($event)"
      type="text"
      spellcheck="false"
      [(ngModel)]="searchCriteria"
      placeholder="Search"
      (click)="HandleFocus()"
      (focus)="HandleFocus()"
      (blur)="HandleFocus()"
    />
  </div>
  <div class="search-button-container" (click)="SubmitForm()">
    <div class="spinner-icon-button">
      <mat-icon *ngIf="!loading">search</mat-icon>
      <ng-container *ngIf="loading">
        <mat-progress-spinner
          class="spinner black"
          mode="indeterminate"
          [color]="'#000'"
          [diameter]="30"
        ></mat-progress-spinner>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #plateStyleInput>
  <div
    class="new-option-search-container plate plate-surround"
    [ngClass]="{
      inline: inlineComponent,
      'search-input-sizing': defaultSizing
    }"
  >
    <div class="input-container">
      <input
        class="plate-font"
        (keydown)="EnterSubmit($event)"
        type="text"
        spellcheck="false"
        [(ngModel)]="searchCriteria"
        placeholder="SEARCH HERE"
        (click)="HandleFocus()"
        (focus)="HandleFocus()"
        (blur)="HandleFocus()"
      />
    </div>
    <div class="spinner-icon-button searchIcon">
      <mat-icon *ngIf="!loading" matSuffix (click)="SubmitForm()"
        >search</mat-icon
      >
      <ng-container *ngIf="loading">
        <mat-progress-spinner
          class="spinner black"
          mode="indeterminate"
          [color]="'#000'"
          [diameter]="30"
        ></mat-progress-spinner>
      </ng-container>
    </div>

    <div
      class="drop-down-options"
      *ngIf="predictiveOptions && predictiveOptions.length > 0"
    >
      <div
        class="option plate-background"
        *ngFor="let predOption of predictiveOptions"
        (click)="GotoPlateDetail(predOption.registration)"
      >
        <div class="flex-space"></div>
        <span class="inclusive-plate-font"
          >{{ predOption.registration | uppercase }}
        </span>
        <div class="flex-space"></div>
        <mat-icon class="large"> east </mat-icon>
      </div>
    </div>
  </div>
</ng-template>
