import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserConversation } from 'src/app/models/userConversation';

@Component({
  selector: 'pla-messenger-list',
  templateUrl: './messenger-list.component.html',
  styleUrls: ['./messenger-list.component.scss'],
})
export class MessengerListComponent implements OnInit {
  @Input() public conversations: UserConversation[] = [];
  @Input() public selectedConversation: UserConversation = null;
  @Output() public selectCovnersation: EventEmitter<UserConversation> =
    new EventEmitter<UserConversation>();

  constructor() {}

  ngOnInit(): void {}

  public handleConversationClickEvent(convo: UserConversation): void {
    if (convo == null) return;
    if (
      this.selectedConversation != null &&
      this.selectedConversation.contextId == convo.contextId
    )
      return;
    this.selectedConversation = convo;
    this.selectCovnersation.emit(this.selectedConversation);
  }

  public isSelected(convo: UserConversation): boolean {
    if (convo == null) return false;
    if (this.selectedConversation == null) return false;
    if (this.selectedConversation.contextId != convo.contextId) return false;
    return true;
  }
}
