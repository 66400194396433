export class PlateCarConfig {
  public name: string;
  public config: {
    iamgeInfo: {
      path: string;
    };
  };
}

export class PlateViewerConfig {
  public id: number;
  public carName: string;
  public config: string;
  public imageName: string;
  public groupId: string;
  public image: string;

  public builtConfig: PlateCarConfig;

  constructor(_?: {
    id: number;
    carName: string;
    config: string;
    imageName: string;
    groupId: string;
  }) {
    if (_ == null) return;
    this.id = _.id;
    this.carName = _.carName;
    this.config = _.config;
    this.imageName = _.imageName;
    this.groupId = _.groupId;

    this.builtConfig = this.getConfig() as PlateCarConfig;
  }

  public getConfig(): any {
    return JSON.parse(this.config);
  }
}
