import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginModel } from 'src/app/models/loginModel';
import { RegisterModel } from 'src/app/models/registerModel';
import { SessionService } from 'src/app/services/session-service';

@Component({
  selector: 'pla-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent implements OnInit {
  public loginModel: LoginModel = new LoginModel();
  public registerModel: RegisterModel = new RegisterModel();
  public loading: boolean = false;

  constructor(
    private sessionService: SessionService,
    private snackbar: MatSnackBar,
    private router: Router
  ) {
    if (sessionService.isLoggedIn()) {
      this.router.navigate([this.sessionService.getSuccessRoute()]);
    }
  }

  ngOnInit(): void {}

  public login(): void {
    this.makeLoginRequest(this.loginModel);
  }

  public signUp(): void {
    this.router.navigate(['/register']);
  }

  private makeLoginRequest(loginDetails: LoginModel): void {
    this.loading = true;
    this.sessionService.login(
      loginDetails,
      (success: boolean, message: string) => {
        if (!success) {
          this.snackbar.open(message, 'Close', { duration: 5000 });
          this.loading = false;
        } else {
          var route = this.sessionService.getSuccessRoute();
          setTimeout(() => {
            this.router.navigate([route]);
          }, 500);
        }
      }
    );
  }
}
