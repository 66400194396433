import { environment } from 'src/environments/environment';

export enum ListingType {
  Platex_Commision = 0,
  Standard_Monthly = 1,
  Standard_3_Monthly = 2,
  Standard_Annually = 3,
}

function ListingKey(listingType: ListingType): string {
  if (listingType == ListingType.Platex_Commision) return 'platex_commission';
  if (listingType == ListingType.Standard_Monthly) return 'monthly_listing';
  if (listingType == ListingType.Standard_3_Monthly) return '3_month_listing';
  if (listingType == ListingType.Standard_Annually) return 'yearly_listing';
}

export function ListingTypeMapper(listingType: ListingType): {
  price: number;
  period: string;
} {
  if (listingType == ListingType.Platex_Commision)
    return { price: 0, period: 'until after sale' };
  if (listingType == ListingType.Standard_Monthly)
    return { price: 7.5, period: 'per month' };
  if (listingType == ListingType.Standard_3_Monthly)
    return { price: 18, period: 'every 3 months' };
  if (listingType == ListingType.Standard_Annually)
    return { price: 54, period: 'per year' };
}

export function GetStripePaymentLink(listingType: ListingType): string {
  return environment.stripeProducts[ListingKey(listingType)];
}
