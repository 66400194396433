<div class="component-container">
  <p style="margin-top: 10px">
    Be alerted when new registrations that match your keyword are added.
  </p>
  <div>
    <h2>Add Alert</h2>
    <div *ngIf="newAlert">
      <mat-form-field appearance="outline">
        <input
          matInput
          type="text"
          [(ngModel)]="newAlert.term"
          placeholder="Enter a keyword"
        />
      </mat-form-field>

      <div class="add-select-container">
        <mat-form-field appearance="outline">
          <mat-label>Registration Styles</mat-label>
          <mat-select [formControl]="regStyleControl" multiple>
            <mat-option [value]="'all_styles'">All Styles</mat-option>
            <mat-option [value]="'current'">Current</mat-option>
            <mat-option [value]="'prefix'">Prefix</mat-option>
            <mat-option [value]="'suffix'">Suffix</mat-option>
            <mat-option [value]="'dateless'">Dateless</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="false">
          <mat-label>Budget</mat-label>
          <mat-select [formControl]="budgetControl" multiple>
            <mat-option [value]="'0-500'">£0-£500</mat-option>
            <mat-option [value]="'500-1500'">£500-£1500</mat-option>
            <mat-option [value]="'1500-5000'">£1500-£5000</mat-option>
            <mat-option [value]="'5000+'">£5000+</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="false">
          <mat-label>Sale Type</mat-label>
          <mat-select [formControl]="saleTypeControl">
            <mat-option [value]="'any'">Any</mat-option>
            <mat-option [value]="'auction'">Auction</mat-option>
            <mat-option [value]="'non_auction'">Free to Buy</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <button
          mat-raised-button
          class="platex-button"
          (click)="addNewAlertHandler()"
        >
          Create Alert
        </button>
      </div>
    </div>
  </div>
  <div style="margin-top: 50px">
    <h2>My Alerts</h2>
    <div *ngIf="!loading; else loadingSpinner">
      <ng-container *ngIf="alerts && alerts.length > 0; else noAlerts">
        <ng-container *ngFor="let alert of alerts">
          <div class="alert-row">
            <div>
              <pla-plate
                [rawPlate]="alert.term"
                [white]="true"
                [mobileSmall]="true"
              ></pla-plate>
              <span
                style="display: block; margin-top: 5px; text-align: center"
                >{{ alert.registrationStyle }}</span
              >
            </div>
            <span style="display: block; text-align: center; padding: 0 2px"
              >Alert requested {{ alert.dateAdded | date : "shortDate" }}</span
            >
            <button
              style="min-width: 80px"
              mat-raised-button
              class="platex-border-button"
              (click)="deleteAlert(alert)"
            >
              Cancel
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #noAlerts>
      <p>No alerts have been configured</p>
    </ng-template>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="full spinner-loader">
    <div class="py-3">
      <mat-progress-spinner
        class="spinner cyan"
        mode="indeterminate"
        [color]="'#327b8d'"
        [diameter]="60"
      ></mat-progress-spinner>
    </div>
    <span>Loading Alerts...</span>
  </div>
</ng-template>
