<div
  class="result-row-container"
  [ngClass]="{ last: lastRow }"
  *ngIf="
    !loading &&
      ((plate.term &&
        (plate['meta_data'] || plate['filters'].indexOf('name') > -1)) ||
        isAdmin);
    else noResult
  "
>
  <tr class="result-row" *ngIf="!loading; else skeletonRow">
    <td>
      <div style="display: flex; align-items: center">
        <pla-plate
          [plate]="plate"
          [showPrice]="false"
          [mobileSmall]="true"
          [smallPlate]="false"
          [showPercentage]="false"
          [noMargin]="false"
          [white]="whitePlate"
          [disabled]="!plate.available"
        ></pla-plate>

        <button
          *ngIf="customButton && isAdmin"
          mat-icon-button
          (click)="customButton.emit(plate)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </td>
    <div class="flex-space"></div>
    <td class="no-padding">
      <div class="price-container" (click)="gotoCompareLink(plate)">
        <div *ngIf="plate.priceBreakdown; else rawPrice">
          <div *ngIf="!isPOA(plate); else poa">
            <p class="price-field desktop-only">
              {{ plate.priceBreakdown.total | currency : "GBP" }}
            </p>
            <p class="price-field mobile-only">
              <ng-container
                *ngIf="plate.priceBreakdown.total < 100000; else showPence"
              >
                {{ plate.priceBreakdown.total | currency : "GBP" }}
              </ng-container>
              <ng-template #showPence>
                {{
                  plate.priceBreakdown.total
                    | currency : "GBP" : "symbol" : "1.0-0"
                }}
              </ng-template>
            </p>
          </div>
          <ng-template #poa>
            <p class="price-field">POA</p>
          </ng-template>
        </div>
        <ng-template #rawPrice>
          <ng-container *ngIf="plate.price >= 0; else notAvailable">
            <p class="price-field">
              {{ plate.price / 100 | currency : "GBP" }}
            </p>
          </ng-container>
          <ng-template #notAvailable>
            <p class="price-field">Not Available</p>
          </ng-template>
        </ng-template>

        <div *ngIf="plate.price >= 0">
          <p class="seller-name" style="text-align: center">
            {{ plate.seller }}
          </p>
        </div>
      </div>
    </td>
    <div class="desktop-only flex-space"></div>
    <td class="actions-field flex-field">
      <!-- MOBILE FAVOURITE -->
      <div
        class="dynamic action-button-width mobile-only"
        *ngIf="plate.price >= 0"
      >
        <div class="btn-container-full-width">
          <pla-signed-up-content
            *ngIf="plate"
            [requesting]="'favourite plate ' + plate?.registration"
          >
            <button lockedContent mat-icon-button>
              <mat-icon>bookmark_border</mat-icon>
            </button>
            <button
              unlockedContent
              mat-icon-button
              (click)="favouriteRegistration(plate)"
            >
              <ng-container *ngIf="plate.favourite; else notFavourite">
                <mat-icon style="color: #01c4c7">bookmark_added</mat-icon>
              </ng-container>
            </button>
          </pla-signed-up-content>
        </div>
      </div>

      <!-- DESKTOP FAVOURITE -->
      <div class="action-button-width desktop-only">
        <div class="btn-container-full-width">
          <pla-signed-up-content
            *ngIf="plate"
            [requesting]="'favourite plate ' + plate?.registration"
          >
            <button mat-raised-button lockedContent>
              <mat-icon>bookmark_border</mat-icon>
              <span style="margin-left: 5px">Favourite</span>
            </button>
            <button
              unlockedContent
              mat-raised-button
              (click)="favouriteRegistration(plate)"
            >
              <ng-container *ngIf="plate.favourite; else notFavourite"
                ><mat-icon style="color: #01c4c7"
                  >bookmark_added</mat-icon
                ></ng-container
              >
              <span style="margin-left: 5px">Favourite</span>
            </button>
          </pla-signed-up-content>
        </div>
      </div>

      <ng-template #notFavourite>
        <mat-icon>bookmark_border</mat-icon>
      </ng-template>

      <!-- <div class="btn-container-padding">
        <button mat-icon-button (click)="favouriteRegistration(plate)">
          <ng-container *ngIf="plate.favourite; else notFavourite">
            <mat-icon style="color: #01c4c7">bookmark_added</mat-icon>
          </ng-container>
          <ng-template #notFavourite>
            <mat-icon>bookmark_border</mat-icon>
          </ng-template>
        </button>
      </div> -->
      <div class="btn-container-full-width desktop-only">
        <pla-signed-up-content
          *ngIf="plate"
          [requesting]="'see deals ' + plate?.registration"
        >
          <button lockedContent class="cyan-button" mat-raised-button>
            See Deals
          </button>
          <button
            unlockedContent
            class="turquoise-button"
            (click)="gotoCompareLink(plate)"
            mat-raised-button
          >
            See Deals
          </button>
        </pla-signed-up-content>
      </div>
      <div class="btn-container-full-width">
        <button
          class="cyan-button"
          (click)="openPlateDetail(plate)"
          mat-raised-button
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span class="inline">View</span>
            <mat-icon class="small">arrow_right</mat-icon>
          </div>
        </button>
      </div>
    </td>
  </tr>

  <div>
    <div *ngIf="plate">
      <div
        class="term-data"
        *ngIf="
          (showTerm && (plate.term || plate['meta_data'])) ||
          (showTerm && plate.term != '' && isAdmin)
        "
      >
        <p
          class="definition-container"
          *ngIf="plate['meta_data']; else namePlate"
        >
          <span>Definition - </span>
          <span class="definition"
            >{{ plate.meta_data }} - {{ plate.term | uppercase }}</span
          >
        </p>
        <ng-template #namePlate>
          <p class="definition-container" *ngIf="plate['filters'] == 'name'">
            <span class="definition">Name - {{ plate.term }}</span>
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div
    *ngIf="allowShowMore && plate && plate.term && !plate['meta_data']"
    style="margin-bottom: 5px; padding: 0 5px"
  >
    <pla-see-more-button
      [align]="'left'"
      [term]="plate.term"
    ></pla-see-more-button>
  </div>

  <!-- SKELETON ROW -->
  <ng-template #skeletonRow>
    <div class="result-row skeleton">
      <td>
        <div class="plate-size skeleton-item"></div>
      </td>
      <div class="flex-space desktop-only"></div>
      <td class="no-padding price-container flex-space">
        <p class="price-size skeleton-item"></p>
        <p class="price-size skeleton-item"></p>
      </td>
      <div class="desktop-only flex-space"></div>
      <td class="actions-field flex-field">
        <div class="action-button-width desktop-only">
          <div class="btn-container-full-width">
            <div class="action-button-size skeleton-item"></div>
          </div>
        </div>

        <div class="action-button-width btn-container-full-width desktop-only">
          <div class="action-button-size skeleton-item"></div>
        </div>

        <div class="dynamic action-button-width mobile-only ng-star-inserted">
          <div class="btn-container-full-width">
            <div class="icon-button skeleton-item"></div>
          </div>
        </div>

        <div
          class="action-button-width btn-container-no-right-padding row-action"
        >
          <div class="btn-container-full-width">
            <div class="action-button-size skeleton-item"></div>
          </div>
        </div>
      </td>
    </div>
  </ng-template>
</div>

<ng-template #noResult>
  <p style="display: none">{{ plate.term }} / {{ plate["meta_data"] }}</p>
</ng-template>
