import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchRoute } from './components/search-variant-bar/search-variant-bar.component';
import { UserDetails } from './models/userDetails';
import { RouteDataService } from './services/route-data-service';
import { SessionService } from './services/session-service';
import { UserService } from './services/user-service';

export class AppNavigationMenu {
  constructor(
    public action: () => void = () => {},
    public viewRoute: string,
    public iconName: string,
    public icon: boolean,
    public active: () => boolean = () => {
      return true;
    },
    public subMenu?: AppNavigationMenu[]
  ) {}
}

export class AppNavigation {
  constructor(
    public route: string,
    public viewRoute: string,
    public iconName: string,
    public icon: boolean,
    public active: () => boolean = () => {
      return true;
    },
    public showOnMobile: boolean = true,
    public showOnDesktop: boolean = true,
    public menu?: AppNavigationMenu[]
  ) {}
}

declare global {
  interface Window {
    gtag: any;
    va: any;
  }
}

@Component({
  selector: 'pla-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('navDrawer', { static: true }) public navDrawer: MatSidenav;
  @ViewChild('favouriteDrawer', { static: true })
  public favouriteDrawer: MatSidenav;
  private loggedInOnly: boolean = false; // true -> MUST BE LOGGED IN TO PROGRESS PASS LOGIN/REGISTER PAGE

  public navigation: AppNavigation[] = this.getNavigation();
  public notPlateDetailPage: boolean = true;

  public options: SearchRoute[] = [
    new SearchRoute('/buy', 'Smart-Search'),
    new SearchRoute('/initials-search', 'Initials'),
    // new SearchRoute('/categories/jobs', 'Categories'),
    new SearchRoute('/byo-search', 'Build-Your-Own'),
    new SearchRoute('/dateless-search', 'Dateless'),
    new SearchRoute('/plateopedia', 'Plateopedia'),
    // new SearchRoute('/name-search', 'Names'),
    // new SearchRoute('/dvla-auction', 'DVLA Auction'),
    // new SearchRoute('/landing', 'Acrylic Plates'),
  ];

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private userService: UserService,
    private routeDataService: RouteDataService
  ) {
    this.sessionService.sessionStateChange.subscribe(() => {
      this.refreshNavigation();
    });

    this.router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationStart) {
        this.navDrawer.close();
        this.ScrollToTop();

        if (e.url.indexOf('plate-detail') > -1) this.notPlateDetailPage = false;
        else this.notPlateDetailPage = true;

        if (
          this.loggedInOnly &&
          !this.sessionService.isLoggedIn() &&
          e.url != '/login' &&
          e.url != '/register'
        ) {
          this.router.navigate(['/login']);
        }
      } else if (e instanceof NavigationEnd) {
        this.routeDataService.get(); // sets title

        window.gtag('set', 'user_properties', {
          logged_in: this.sessionService.isLoggedIn(),
        });
        if (this.sessionService.isLoggedIn()) {
          setTimeout(() => {
            this.userService.getUserDetails((details: UserDetails) => {
              if (
                window.LO.visitor == null ||
                window.LO.visitor.identify == null
              )
                return;
              window.LO.visitor.identify(details.id, {
                email: details.email,
                name: `${details.firstName} ${details.lastName}`.trim(),
                version: environment.versionNo,
              });
            });
          }, 2000);
        }

        this.refreshNavigation();
        this.sessionService.ClearQueue();
        window.scrollTo({ behavior: 'auto', top: 0 }); // after navigation we should always be at the top of the page
      }
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  public ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.sessionService.Start();
  }

  private ScrollToTop(): void {
    setTimeout(() => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    }, 10);
  }

  private refreshNavigation(): void {
    this.navigation = this.getNavigation();
  }

  public isSearchPage(): boolean {
    var url = window.location.pathname;
    var isSearchRoute =
      this.options.filter((o) =>
        url.toLowerCase().startsWith(o.route.toLowerCase())
      ).length > 0;

    // Overrides
    if (url.startsWith('/search-results')) isSearchRoute = true;
    if (url.startsWith('/plate-detail')) isSearchRoute = true;
    if (url.startsWith('/landing')) isSearchRoute = true;
    if (url.startsWith('/buy')) isSearchRoute = true;

    return isSearchRoute;
  }

  public isFullScreenPage() {
    var url = window.location.pathname;
    if (url.startsWith('/message-hub')) return true;
    if (this.navDrawer && (this.navDrawer as any)._opened) return true;
    return false;
  }

  public closeNav(): void {
    this.navDrawer.close();
    this.ScrollToTop();
  }

  private getNavigation(): AppNavigation[] {
    var allNavigation = [
      // MOBILE
      new AppNavigation(
        '/buy',
        'SEARCH',
        null,
        false,
        () => true,
        true,
        false,
        [
          new AppNavigationMenu(
            () => {
              this.closeNav();
              this.router.navigate(['/buy']).then(() => {
                this.closeNav();
              });
            },
            'Smart-Search',
            null,
            false,
            () => true
          ),
          new AppNavigationMenu(
            () => {
              this.router.navigate(['/initials-search']).then(() => {
                this.closeNav();
              });
            },
            'Initials',
            null,
            false
          ),
          // new AppNavigationMenu(
          //   () => {
          //     this.router.navigate(['/name-search']).then(() => {
          //       this.closeNav();
          //     });
          //   },
          //   'Names',
          //   null,
          //   false
          // ),
          new AppNavigationMenu(
            () => {
              this.router.navigate(['/dateless-search']).then(() => {
                this.closeNav();
              });
            },
            'Dateless',
            null,
            false
          ),
          new AppNavigationMenu(
            () => {
              this.router.navigate(['/byo-search']).then(() => {
                this.closeNav();
              });
            },
            'Build-Your-Own',
            null,
            false
          ),
          new AppNavigationMenu(
            () => {
              this.router.navigate(['/plateopedia']).then(() => {
                this.closeNav();
              });
            },
            'Plateopedia',
            null,
            false
          ),
          // new AppNavigationMenu(
          //   () => {
          //     this.router.navigate(['/dvla-auction']).then(() => {
          //       this.closeNav();
          //     });
          //   },
          //   'DVLA Auction',
          //   null,
          //   false
          // ),
        ]
      ),
      // DESKTOP
      new AppNavigation('/buy', 'BUY', null, false, () => true, false),
      new AppNavigation('/selling', 'SELL', null, false, () =>
        this.sessionService.isAdmin()
      ),
      new AppNavigation('/compare', 'PRICE COMPARISON', null, false),
      // new AppNavigation('/info', 'INFO', null, false),
      new AppNavigation(
        '/plate-viewer',
        'PLATE VIEWER',
        null,
        false,
        () => true,
        true
      ),
      new AppNavigation(
        '/account',
        'Account',
        'person',
        true,
        () => this.sessionService.isLoggedIn(),
        true,
        true,
        this.accountNavMenu()
      ),
      new AppNavigation(
        '/login',
        'Sign In',
        'person_add',
        true,
        () => !this.sessionService.isLoggedIn()
      ),
    ];

    // if (this.sessionService.isAdmin()) {
    //   allNavigation.push(new AppNavigation('/admin', 'Admin', false));
    // }
    return allNavigation.filter((n) => n.active());
  }

  private accountNavMenu(): AppNavigationMenu[] {
    var menu = [
      new AppNavigationMenu(
        () => {
          this.viewAccount();
        },
        'View Account',
        null,
        false
      ),
      new AppNavigationMenu(
        () => {
          this.signOut();
        },
        'Sign out',
        null,
        false
      ),
    ];
    if (this.sessionService.isAdmin()) {
      menu.push(
        new AppNavigationMenu(
          () => {
            this.router.navigate(['/admin']).then(() => {
              this.closeNav();
            });
          },
          'Admin',
          null,
          false
        )
      );
    }
    return menu;
  }

  private adminNavigation(): AppNavigationMenu {
    if (!this.sessionService.isLoggedIn()) return;
    if (!this.sessionService.isAdmin()) return;
    return new AppNavigationMenu(
      () => {
        this.router.navigate(['/admin']).then(() => {
          this.closeNav();
        });
      },
      'Admin',
      null,
      false
    );
  }

  private signOut(): void {
    this.sessionService.signOut(() => {
      this.sessionService.sessionStateChange.emit();
      this.router.navigate(['/login']);
    });
  }

  private viewAccount(): void {
    this.router.navigate(['/account']).then(() => {
      this.closeNav();
    });
  }
}
