import { PlateViewerConfig } from '../models/plateViewerConfig';

export const PORSCHE_GT3_BANNER = {
  id: 199,
  url: 'https://cdn.platex.co.uk/large/porsche_gt3.png',
};
export const DEFAULT_IMAGE = {
  id: 100,
  url: 'https://cdn.platex.co.uk/large/DEFAULT_IMAGE.png',
};

export const BANNER_IMAGES = [DEFAULT_IMAGE, PORSCHE_GT3_BANNER];

export const DEFAULT_IMAGE_ID = 100;

const stored_configs: PlateViewerConfig[] = [
  new PlateViewerConfig({
    carName: 'Lamborghini Huracan Evo',
    config: JSON.stringify({
      name: 'NEW-CONFIG',
      config: {
        textInfo: {
          color: '#1b1616',
          scale: {
            x: 0.1355,
            y: 0.1355,
            z: 0.1355,
          },
          position: {
            x: 21.955318,
            y: -5.440256,
            z: 0,
          },
          rotation: {
            _x: -0.078016214,
            _y: 0.38501963,
            _z: -0.013439035,
            _order: 'XYZ',
          },
        },
        imageInfo: {
          path: 'https://cdn.platex.co.uk/plate-viewer/EVO_reduced.png',
          _width: 1920,
          _height: 1200,
        },
      },
    }),
    groupId: '265',
    id: 265,
    imageName: '6eade87d-ba0c-4fa0-9685-e67a97766060',
  }),
  new PlateViewerConfig({
    id: 369,
    carName: 'Audi R8 V10 RWD',
    config:
      '{"name": "NEW-CONFIG", "config": {"textInfo": {"color": "#0d0c0c", "scale": {"x": 0.098, "y": 0.098, "z": 0.098}, "position": {"x": 17.680832, "y": -11.113666, "z": 0.0}, "rotation": {"_x": -0.059515726, "_y": 0.4654793, "_z": 0.009424778, "_order": "XYZ"}}, "imageInfo": {"path": "https://cdn.platex.co.uk/plate-viewer/R8_Reduced.png", "_width": 1920.0, "_height": 1200.0}}}',
    imageName: '6cd3f1c4-f4fa-40da-b306-04e8a117bd7a',
    groupId: 'reduced',
  }),
  new PlateViewerConfig({
    id: 368,
    carName: 'Jaguar F-Type R',
    config:
      '{"name": "NEW-CONFIG", "config": {"textInfo": {"color": "#110f0f", "scale": {"x": 0.09, "y": 0.09, "z": 0.09}, "position": {"x": 13.1149025, "y": -17.02023, "z": 0.0}, "rotation": {"_x": 0.0, "_y": 0.40561453, "_z": 0.0, "_order": "XYZ"}}, "imageInfo": {"path": "https://cdn.platex.co.uk/plate-viewer/FTYPE_Reduced.png", "_width": 1920.0, "_height": 1200.0}}}',
    imageName: '41526600-5b16-490e-ae62-70500d140513',
    groupId: 'reduced',
  }),
  new PlateViewerConfig({
    id: 300,
    carName: 'Mini Cooper S',
    config:
      '{"name": "NEW-CONFIG", "config": {"textInfo": {"color": "#100f0f", "scale": {"x": 0.138, "y": 0.138, "z": 0.138}, "position": {"x": 19.818075, "y": -9.947897, "z": 0.0}, "rotation": {"_x": -0.12548918, "_y": 0.38885936, "_z": -0.03769911, "_order": "XYZ"}}, "imageInfo": {"path": "https://cdn.platex.co.uk/plate-viewer/JCW_reduced.png", "_width": 1920.0, "_height": 1200.0}}}',
    imageName: '2ac16ca7-7810-439c-8710-90a6a682c32a',
    groupId: 'reduced_cooper',
  }),
];

// QUICKLOAD CONFIG - PLATE VIEWER
const configId = () => {
  const randomId = Math.floor(Math.random() * stored_configs.length);
  return randomId;
};
export const quick_config: PlateViewerConfig = stored_configs[configId()];

//"https://cdn.platex.co.uk/plate-viewer/NoPath---Copy-(16).jpg"

export const SITE_BANNER_IMAGES = {
  ASTON_DB11:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/S34RCH_ASTON_DB11.png',
  FERRARI_F40:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/S3LLS_FERRARI_F40.png',
  FERRARI_275:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/8UYS_FERRARI_275.png',
  RR_DEFENDER:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/DE51GNS_RANGEROVER_DEFENDER.png',
  LAMBORGHINI_DIABLO:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/L5_LAMBORGHINI_DIABLO.png',
  PORSCHE_TAYCAN:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/PAY135S_PORSCHE_TAYCAN.png',
  MINI_JCW:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/HO72ODS_MINI_JCW.png',
  BMW_M2:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/8UYS_BMW_M2.png',
  PORSCHE_GT3:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/AUC710N_PORSCHE_GT3.png',
  BUGATTI_STREET:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/BUGATTI_CHIRON_STREET.jpg',
  BUGATTI_TRACK:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/BUGATTI_CHIRON_TRACK.jpg',
  //
  _BUGGATI:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/BUGATTI_IMAGE.jpg',
  _GT3RS: 'https://cdn.platex.co.uk/large/porsche%20gt3%20rs.jpg',
  _FERRARI:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/FERRARI_TDF.jpg',
  COUNTACH:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/Sell-Countach.png',
  AVENTADOR_S:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/AVENTADOR_S.png',
  PORSCHE_918:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/large/PORSCHE_918.png',

  AVENTADOR_S_FOOTER:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/pages/AVS_FOOTER.png',
  SELLING_F40:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/pages/sell-landing/FERRARI_S3LLS.png',
  PORSCHE_918_S:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/pages/blog-landing/PORSCHE_918_SPYDER.png',
  FERRARI_812:
    'https://platex-static-assets.s3.eu-west-2.amazonaws.com/pages/name-search/812_FERRARI.png',
};
