<div>
  <pla-wildcard-character-select
    [padLeft]="padLeft"
    [style]="style"
    [any_character]="any_character"
    [no_character]="no_character"
    [regenerateWithBreakdowns]="regenerateWithBreakdowns"
    [regenerate]="regenerateInput"
    (inlineSearch)="doInlineSearch($event)"
    [prefix]="prefix"
    [numbers]="numbers"
    [letters]="letters"
    [withPadding]="withPadding"
  ></pla-wildcard-character-select>
</div>
<div class="tabbed-button-section secondary search-input-sizing">
  <pla-switch-toggle
    [selectedTabId]="activeSwitch"
    [tabs]="tabs"
    (switchChange)="SwitchChange($event)"
  ></pla-switch-toggle>
</div>
