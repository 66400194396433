import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginModel } from 'src/app/models/loginModel';
import { RegisterModel } from 'src/app/models/registerModel';
import { SessionService } from 'src/app/services/session-service';

export class SignUpCallback {
  constructor() {}
}

@Component({
  selector: 'pla-quick-sign-up-modal',
  templateUrl: './quick-sign-up-modal.component.html',
  styleUrls: ['./quick-sign-up-modal.component.scss'],
})
export class QuickSignUpModalComponent implements OnInit {
  public registerScreen: boolean = true;

  // REGISTER
  public registerModel: RegisterModel = new RegisterModel();
  public registerErrors: string[] = [];
  public registerForm = new FormGroup({
    email: new FormControl(this.registerModel.email, [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl(this.registerModel.password, [
      Validators.required,
    ]),
    passwordRepeat: new FormControl(this.registerModel.passwordRepeat, [
      Validators.required,
    ]),
  });

  // LOGIN
  public loginModel: LoginModel = new LoginModel();
  public loginErrors: string[] = [];
  public loginForm = new FormGroup({
    email: new FormControl(this.loginModel.email, [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl(this.loginModel.password, [Validators.required]),
  });

  constructor(
    private dialogRef: MatDialogRef<QuickSignUpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { reason: string },
    private sessionService: SessionService
  ) {}

  ngOnInit(): void {}

  public setLoginPage(loginPage: boolean): void {
    this.registerScreen = !loginPage;
  }

  public login(): void {
    if (this.loginForm.invalid) return;
    this.loginModel = this.loginForm.getRawValue() as LoginModel;

    this.sessionService.login(this.loginModel, (resp: boolean) => {
      if (resp) this.dialogRef.close(true);
    });
  }

  public register(): void {
    if (this.registerForm.invalid) return;
    this.registerModel = this.registerForm.getRawValue() as RegisterModel;

    if (this.data && this.data.reason)
      this.registerModel.signupReason = this.data.reason;

    this.sessionService.register(
      this.registerModel,
      (success: boolean, message: string, errors: string[]) => {
        if (success) this.loginAfterRegister();
        else this.registerErrors = errors.slice(0, 1);
      }
    );
  }

  private loginAfterRegister(): void {
    this.sessionService.login(
      this.sessionService.registerToLogin(this.registerModel),
      (success: boolean, message: string) => {
        if (success) this.dialogRef.close(success);
        else console.error(message);
      }
    );
  }

  public closeWithFail(): void {
    this.dialogRef.close(false); // no account created
  }
}
