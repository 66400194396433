<div class="rating-container">
  <div class="button-container" *ngIf="canVote">
    <button
      mat-icon-button
      class="positive"
      [ngClass]="{ active: isActive(true) }"
      (click)="handleRatingClick(true)"
    >
      <mat-icon>thumb_up</mat-icon>
    </button>
    <span *ngIf="showNumeric">{{ likes }}</span>
  </div>

  <div class="rating-view-container">
    <div
      class="rating-view-bar"
      [ngClass]="{ active: hasRating() }"
      *ngIf="!isAdmin && percentage; else showPercentage"
    >
      <div
        class="rating-inner"
        [ngClass]="{ active: hasRating() }"
        [style.width]="calculatedWidth()"
      ></div>
    </div>

    <ng-template #showPercentage>
      <div (click)="viewDebugInfo()">
        <span style="display: block; width: 100%; text-align: center"
          >{{ percentage }}%</span
        >
      </div>
    </ng-template>
  </div>

  <div class="button-container" *ngIf="canVote">
    <button
      mat-icon-button
      class="negative"
      [ngClass]="{ active: isActive(false) }"
      (click)="handleRatingClick(false)"
    >
      <mat-icon>thumb_down</mat-icon>
    </button>
    <span *ngIf="showNumeric">{{ dislikes }}</span>
  </div>
</div>
