import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BYOTermBreakdown,
  BYOTermResponse,
} from 'src/app/models/byoTermResponse';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import {
  TrackingService,
  TRACKING_SEARCH_TYPE,
} from 'src/app/services/tracking-service';

@Component({
  selector: 'pla-byo-search-page',
  templateUrl: './byo-search-page.component.html',
  styleUrls: ['./byo-search-page.component.scss'],
})
export class BYOSearchPageComponent implements OnInit {
  public styleBreakdowns: BYOTermResponse = null;

  public style: string = 'current';
  @Input('breakdowns') public inputBreakdowns: BYOTermResponse = null; // FROM API ####TGE / ####TGE / TGE####
  @Input('searchDefault') public searchDefault: boolean = true;
  @Input('inlineSearch') public inlineSearch: boolean = false;

  public results: SearchReqResponse;
  public resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();

  public searchTerm: string = '';
  public loading: boolean = false;
  public hasSearchCriteria: boolean = false;

  public no_character: string = '﹣';
  public any_character: string = '?';

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private registrationService: RegistrationService,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    // inline = inside search page
    if (this.inlineSearch) {
      this.styleBreakdowns = this.inputBreakdowns;
      if (this.searchDefault) this.search();
      return;
    }

    this.styleBreakdowns = new BYOTermResponse().construct();
    this.style = this.activatedRoute.snapshot.paramMap.get('style');
    if (this.style == null) {
      // blank slate
      this.style = 'current';
    } else {
      // existing values from route
      var _routeActive = this.styleBreakdowns[this.style] as BYOTermBreakdown;
      _routeActive.construct(
        this.style,
        this.activatedRoute.snapshot.paramMap.get('prefix'),
        this.activatedRoute.snapshot.paramMap.get('numbers'),
        this.activatedRoute.snapshot.paramMap.get('letters')
      );

      this.trackingService.TrackSearch(
        TRACKING_SEARCH_TYPE.BYO,
        `${_routeActive.prefix}${_routeActive.numbers}${_routeActive.letters}`,
        { style: this.style }
      );
    }

    if (!this.searchDefault) return;
    this.search();
  }

  public activeBreakdown(): BYOTermBreakdown {
    if (this.style == null || this.styleBreakdowns == null) return null;
    var _ = this.styleBreakdowns[this.style] as BYOTermBreakdown;
    _.style = this.style; // force style
    return _;
  }

  public doInlineSearch(term: BYOTermBreakdown): void {
    // update model behind UI
    this.style = term.style;
    this.styleBreakdowns[this.style] = term;

    if (this.inlineSearch) {
      this.search();
    } else {
      // update route
      this.location.go(
        `/byo-search/${this.style}/${term.prefix}/${term.numbers}/${term.letters}`
      );
      // search without refreshing
      this.search();
    }
  }

  private search(): void {
    if (
      this.activeBreakdown().prefix == '' &&
      this.activeBreakdown().numbers == '' &&
      this.activeBreakdown().letters == ''
    ) {
      this.hasSearchCriteria = false;
      return;
    }

    this.hasSearchCriteria = true;

    this.searchTerm = `${this.activeBreakdown().prefix}${
      this.activeBreakdown().numbers
    }${this.activeBreakdown().letters}`.replace(/-/gi, '?');

    this.loading = true;
    this.searchService.searchBYO(
      this.style,
      this.activeBreakdown().prefix,
      this.activeBreakdown().numbers,
      this.activeBreakdown().letters,
      (_: Registration[]) => {
        // search exact
        this.searchService.searchExact(
          encodeURIComponent(this.searchTerm),
          (exact_results: Registration[]) => {
            this.loading = false;
            this.handleResults([...exact_results, ..._]);
          }
        );
      }
    );
  }

  public handleResults(registrations: Registration[]): void {
    registrations = this.registrationService.formatRegistrations(registrations);
    this.results = new SearchReqResponse('byo_id', registrations);
    this.resultChange.emit(this.results);
  }
}
