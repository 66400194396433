import {
  HttpClient,
  HttpErrorResponse,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuickSignUpModalComponent } from '../components/quick-sign-up-modal/quick-sign-up-modal.component';
import { QuickSignupService } from '../services/quick-signup-service';
import { SessionService } from '../services/session-service';

@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private sessionService: SessionService,
    private signupService: QuickSignupService
  ) {}

  // GET REQUESTS ONLY
  public RequestWithSignUpRequest<T>(
    url: string,
    callback: (data: T) => void,
    errCallback: (_: any) => void = (_: any) => {}
  ): void {
    this.http.get<T>(url).subscribe(
      (data: T) => {
        callback(data);
      },
      (error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            this.CheckLoginStatus<T>(url, callback);
            break;
        }

        errCallback(error.status);
      }
    );
  }

  private CheckLoginStatus<T>(url: string, callback: (data: T) => void): void {
    if (this.sessionService.isLoggedIn()) return; // handled by token-interceptor
    this.ShowSignupModal<T>(url, callback);
  }

  private ShowSignupModal<T>(url: string, callback: (data: T) => void): void {
    this.sessionService.AddToQueue(url, callback);
  }
}
