<div class="footer">
  <div class="socials">
    <div class="social-flex d-flex justify-content-center gap-2">
      <a
        href="https://www.facebook.com/profile.php?id=100093000570664"
        class="d-block"
        target="_blank"
        ><object
          class="d-block mx-auto"
          style="pointer-events: none"
          data="./../../../assets/facebook.svg"
        ></object>
        <span>Facebook</span></a
      >

      <a href="https://www.instagram.com/plates" class="d-block" target="_blank"
        ><object
          class="d-block mx-auto"
          style="pointer-events: none"
          data="./../../../assets/instagram.svg"
        ></object>
        <span>Instagram</span></a
      >

      <a
        href="https://www.twitter.com/platex_uk"
        class="d-block"
        target="_blank"
        ><object
          class="d-block mx-auto"
          style="pointer-events: none"
          data="./../../../assets/twitter.svg"
        ></object>
        <span>Twitter</span></a
      >
    </div>
  </div>

  <div class="details">
    <div>
      <p class="d t l">
        DVLA is a registered trademark of the Driver & Vehicle Licensing Agency.
        PlateX is not affiliated to the DVLA or DVLA Personalised Registrations.
        PlateX is a recognised reseller of registrations. Unissued registrations
        are subject to VAT and a DVLA transfer fee of £80.
      </p>
      <p class="d c l">
        PlateX is registered with the DVLA to supply physical number plates
        only. I.e. the actual plate.
      </p>
    </div>
    <div class="logo-detail-section">
      <pla-platex-direct
        class="platex-logo"
        [colour]="'white'"
        [direct]="false"
        [platePortal]="true"
      ></pla-platex-direct>

      <p class="d c">Site Design & Build by PlateX</p>
      <p class="d c">Copyright 2020 - {{ currYear }} PlateX</p>
      <p class="d c">{{ versionNo }}</p>
    </div>
    <div>
      <p class="d t r">
        Where PlateX provides price information from other vendors we cannot
        guarantee the complete accuracy, nor the completeness of the information
        provided. As a result, and due to the nature of PlateX activities, in
        the event of discrepancies between the information displayed on the
        PlateX portal and the one displayed on the dealers website, the latter
        will prevail.
      </p>
    </div>
  </div>

  <div class="details">
    <a
      class="d-block text-white text-decoration-none text-center m-auto mb-1"
      href="mailto:info@platex.co.uk"
      >Contact Us</a
    >
  </div>
  <div class="details">
    <!-- TrustBox widget - Micro Review Count -->
    <div
      class="trustpilot-widget"
      data-locale="en-GB"
      data-template-id="5419b6a8b0d04a076446a9ad"
      data-businessunit-id="6452393fa3356eee81b25470"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="dark"
      data-min-review-count="50"
      data-style-alignment="center"
    >
      <a
        href="https://uk.trustpilot.com/review/platex.co.uk"
        target="_blank"
        rel="noopener"
        class="text-decoration-none"
        >Trustpilot</a
      >
    </div>
    <!-- End TrustBox widget -->
  </div>

  <div class="details">
    <!-- CARD ICONS -->
    <div class="card-logos">
      <img class="logo" src="./../../../assets/payment/pay-visa.svg" />
      <img class="logo" src="./../../../assets/payment/pay-mcard.svg" />
      <img class="logo" src="./../../../assets/payment/pay-apple.svg" />
      <img class="logo" src="./../../../assets/payment/pay-google.svg" />
      <img class="logo" src="./../../../assets/payment/pay-klarna.svg" />
    </div>
  </div>
</div>
