<div class="bg-light p-3 text-dark mb-4">
  <p class="fw-bolder fs-5">Search + Buy</p>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/buy"
    >Smart Search</a
  >
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/names"
    >Names</a
  >
  <a
    class="fs-6 text-decoration-none text-dark d-block mb-2"
    href="/initials-search"
    >Initials</a
  >
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/byo-search"
    >Build-Your-Own</a
  >
  <a
    class="fs-6 text-decoration-none text-dark d-block mb-2"
    href="/dateless-search"
    >Dateless</a
  >
  <a
    class="fs-6 text-decoration-none text-dark d-block mb-2"
    href="/plateopedia"
    >Plateopedia</a
  >
</div>
<div class="bg-light p-3 text-dark mb-4" *ngIf="false">
  <p class="fw-bolder fs-5">Price Comparison</p>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/compare"
    >How to save money</a
  >
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/compare"
    >Plate Compare</a
  >
</div>

<div class="bg-light p-3 text-dark mb-4" *ngIf="false">
  <p class="fw-bolder fs-5">Sell Your Number Plate</p>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/"
    >What is my plate worth</a
  >
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/"
    >Free instant valuation</a
  >
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/"
    >Sell your number plate</a
  >
</div>

<div class="bg-light p-3 text-dark mb-4" *ngIf="false">
  <p class="fw-bolder fs-5">Information</p>
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/info"
    >FAQs</a
  >
  <a class="fs-6 text-decoration-none text-dark d-block mb-2" href="/"
    >UK Number Plate Guide</a
  >
  <a
    class="fs-6 text-decoration-none text-dark d-block mb-2"
    href="/blog/most-expensive-plates-jan-2024"
    >The Most Expensive Plates</a
  >
  <a
    class="fs-6 text-decoration-none text-dark d-block mb-2"
    href="mailto:info@platex.co.uk"
    >Contact Us</a
  >
</div>
