import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPCONSTANTS } from '../http/http-constants';
import { Listing } from '../models/listing';
import { Registration } from '../models/registration';

@Injectable({ providedIn: 'root' })
export class DynamicPlacementService {
  constructor(private http: HttpClient) {}

  public FeaturedListings(
    type: string = 'default'
  ): Observable<Registration[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/placement/featured/${type}`;
    return this.http.get<Registration[]>(url);
  }
}
