<div>
  <div
    class="px-0 px-md-4 mx-auto"
    style="max-width: 850px"
    *ngIf="loading || !resultsCanShow"
  >
    <pla-compare-loader
      [almostComplete]="searchAlmostComplete"
      (complete)="CompleteLoad()"
    ></pla-compare-loader>
  </div>
  <div
    class="px-0 px-md-4 mx-auto"
    style="max-width: 850px"
    *ngIf="!loading && resultsCanShow"
  >
    <div
      class="d-flex align-items-center justify-content-between justify-content-md-end gap-3 flex-wrap flex-md-nowrap"
    >
      <div class="d-none d-md-block">
        <p class="mb-0">
          {{ viewResults !== undefined ? viewResults.length : "0" }} Results
        </p>
      </div>
      <div class="d-none d-md-block flex-fill"></div>

      <div class="d-flex align-items-center justify-content-between gap-3">
        <!-- <div>
          <mat-slide-toggle
            (change)="CreateLists()"
            [(ngModel)]="withTransfer"
            [labelPosition]="'before'"
          >
            <small class="text-muted"
              >Include managed transfer fee</small
            ></mat-slide-toggle
          >
        </div> -->

        <div>
          <mat-slide-toggle
            (change)="CreateLists()"
            [(ngModel)]="transferOnly"
            [labelPosition]="'before'"
          >
            <small class="text-muted"
              >Offers managed transfer</small
            ></mat-slide-toggle
          >
        </div>

        <div>
          <mat-slide-toggle
            (change)="CreateLists()"
            [(ngModel)]="financeOnly"
            [labelPosition]="'before'"
          >
            <small class="text-muted">Offers Finance</small></mat-slide-toggle
          >
        </div>
      </div>

      <div class="d-block d-md-none">
        <p class="mb-0">
          {{ viewResults !== undefined ? viewResults.length : "0" }} Results
        </p>
      </div>

      <div>
        <!-- SORT -->
        <mat-form-field
          class="no-margin short w-md-100"
          appearance="outline"
          style="max-width: 175px"
        >
          <mat-select
            class="bg-white"
            (selectionChange)="changeSort($event)"
            (openedChange)="sortChange($event)"
            [(ngModel)]="sort"
          >
            <ng-container *ngFor="let sortOption of availableSorts">
              <mat-option [value]="sortOption.id" class="flex-row-reverse">
                <div
                  class="d-flex align-items-center justify-content-start gap-2"
                >
                  <span>{{ sortOption.name }}</span>
                </div>
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="accordion accordion-flush" id="tp-seller-accordion">
      <ng-container *ngFor="let compareResult of viewResults; let i = index">
        <div *ngIf="i < 9 || (i >= 9 && seeAll)" class="position-relative">
          <div
            class="accordion-item my-3 border overflow-hidden shadow-sm"
            style="border-radius: 8px"
          >
            <!-- BUTTON START -->
            <h2 class="accordion-header border-0" id="{{ compareResult.site }}">
              <button
                class="ps-2 ps-md-3 accordion-button border-bottom-0 overflow-hidden collapsed pe-0 py-0 {{
                  compareResult.total <= 0 ? 'opacity-25' : ''
                }}"
                type="button"
                [disabled]="compareResult.total <= 0"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#' + compareResult.site + 'Collapse'"
                aria-expanded="true"
                [attr.aria-controls]="compareResult.site + 'Collapse'"
              >
                <div
                  class="w-100 flex-fill d-flex align-items-center justify-content-between"
                >
                  <div
                    class="ms-2 ms-md-3 border-start d-flex align-items-center justify-content-start gap-3 py-2 py-md-0 ps-3 w-100"
                  >
                    <div
                      class="d-flex align-items-center justify-content-start gap-3 w-100"
                    >
                      <p class="fs-6 mb-0">
                        <span class="text-dark fw-bold">{{
                          compareResult.viewName
                        }}</span>
                      </p>
                      <div class="flex-fill"></div>
                      <div>
                        <p class="mb-0 fs-6 text-dark pe-md-0 pe-3">
                          {{
                            compareResult.total
                              | currency
                                : "GBP"
                                : "symbol"
                                : (compareResult.total < 10000 ? "" : "1.0-0")
                          }}
                        </p>
                      </div>
                      <div
                        style="background-color: #007850; min-width: 150px"
                        class="p-3 border-start d-none d-md-block"
                        (click)="openCompareSiteHandler(compareResult)"
                      >
                        <p class="mb-0 fs-6 text-white text-center">
                          {{
                            compareResult.total <= 0
                              ? "Unavailable"
                              : "Go to Dealer"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </h2>
            <!-- BUTTON END -->

            <!-- BODY -->
            <div
              id="{{ compareResult.site }}Collapse"
              class="accordion-collapse collapse {{
                compareResult.platexListing ? 'show' : null
              }}"
              [attr.aria-labelledby]="compareResult.site"
            >
              <div class="inset-shadow-sm accordion-body bg-white px-3 py-3">
                <!-- MOBILE -->
                <div class="d-block d-md-none">
                  <div class="container-fluid">
                    <div class="row">
                      <!-- LHS -->
                      <div class="col-6 ps-0">
                        <div class="d-block text-start mb-3">
                          <span class="fw-bold mb-1 d-block">Reviews</span>
                          <div class="ms-2" (click)="openTPLink(compareResult)">
                            <img
                              style="max-width: 90px"
                              src="{{ trustPilotScoreImage(compareResult) }}"
                              class="d-block ms-0 me-auto"
                            />
                            <small class="text-decoration-underline d-block"
                              >{{
                                compareResult.trustPilotRatingCount
                                  | number : "1.0-0"
                              }}
                              reviews</small
                            >
                          </div>
                        </div>

                        <div class="d-block text-start flex-fill mb-3">
                          <span class="fw-bold mb-1 d-block"
                            >Payment Methods</span
                          >
                          <div
                            class="d-flex align-items-center justify-content-start gap-1 flex-wrap mx-auto mx-md-0 ms-2"
                            style="max-width: 200px"
                          >
                            <ng-container
                              *ngFor="let pm of compareResult.paymentMethods"
                            >
                              <div
                                style="
                                  max-height: 20px;
                                  max-width: 60px;
                                  width: 30%;
                                "
                              >
                                <img
                                  src="https://cdn.platex.co.uk/assets/card-icons/flat-rounded/{{
                                    pm
                                  }}.svg"
                                  style="max-height: 20px"
                                  class="d-block h-100 ms-0"
                                />
                              </div>
                            </ng-container>
                          </div>
                        </div>

                        <div class="d-block text-start flex-fill mb-2">
                          <span class="fw-bold mb-1 d-block">Finance</span>

                          <small class="ms-2" *ngIf="!compareResult.finance"
                            >Not Offered</small
                          >
                          <small
                            class="ms-2"
                            *ngIf="
                              compareResult.finance &&
                              (compareResult.financeMethods == null ||
                                compareResult.financeMethods.length <= 0)
                            "
                          >
                            Bespoke
                          </small>
                          <div
                            *ngIf="
                              compareResult.finance &&
                              compareResult.financeMethods != null &&
                              compareResult.financeMethods.length > 0
                            "
                            class="d-flex align-items-center justify-content-start gap-1 flex-wrap mx-auto mx-md-0 ms-2"
                            style="max-width: 200px"
                          >
                            <ng-container
                              *ngFor="let pm of compareResult.financeMethods"
                            >
                              <div
                                style="
                                  max-height: 20px;
                                  max-width: 60px;
                                  width: 30%;
                                "
                              >
                                <img
                                  src="https://cdn.platex.co.uk/assets/card-icons/flat-rounded/{{
                                    pm
                                  }}.svg"
                                  style="max-height: 20px"
                                  class="d-block h-100 ms-0"
                                />
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>

                      <!-- RHS -->
                      <div class="col-6 border-start pe-0">
                        <div class="d-block text-start flex-fill mb-3">
                          <span class="fw-bold mb-1 d-block"
                            >Transfer Service</span
                          >
                          <small class="text-end d-block">
                            {{
                              compareResult.transferService > -1
                                ? (compareResult.transferService / 100
                                  | currency : "GBP")
                                : "Not Offered"
                            }}
                          </small>
                        </div>

                        <div class="d-block text-start flex-fill mb-3">
                          <span class="fw-bold mb-1 d-block"
                            >Standard Plates</span
                          >
                          <small class="text-end d-block">
                            {{
                              compareResult.physicalPlatePrice > -1
                                ? (compareResult.physicalPlatePrice / 100
                                  | currency : "GBP")
                                : "Not Offered"
                            }}
                          </small>
                        </div>

                        <div class="d-block text-start flex-fill mb-3">
                          <span class="fw-bold mb-1 d-block">Postage</span>
                          <small class="text-end d-block">
                            {{
                              compareResult.postagePrice
                                ? (compareResult.postagePrice / 100
                                  | currency : "GBP")
                                : "£0.00"
                            }}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-12 border-top px-0">
                        <small class="mb-0 pt-2 d-block lh-sm">
                          Established in 1987, are a trusted source for unique
                          vehicle registrations. Recognised by DVLA and
                          accredited by top trade bodies. Rated 'Excellent' on
                          Trustpilot.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- DESKTOP -->
                <div class="d-none d-md-block">
                  <div
                    class="d-flex align-items-stretch justify-content-between gap-3 flex-wrap"
                  >
                    <div
                      class="d-flex flex-column justify-content-between align-items-start flex-fill border-end py-2"
                    >
                      <div>
                        <span class="fw-bold mb-1 d-block">Reviews</span>
                        <img
                          style="max-width: 90px"
                          src="{{ trustPilotScoreImage(compareResult) }}"
                          class="d-block ms-2 me-auto"
                        />
                        <small class="text-decoration-underline d-block ms-2"
                          >{{
                            compareResult.trustPilotRatingCount
                              | number : "1.0-0"
                          }}
                          reviews on Trustpilot</small
                        >
                      </div>

                      <div>
                        <strong>DVLA Recognised</strong>
                      </div>
                    </div>

                    <div
                      class="d-flex flex-column justify-content-between align-items-start flex-fill border-end py-2"
                    >
                      <div class="d-block text-start flex-fill mb-2">
                        <span class="fw-bold mb-1 d-block"
                          >Payment Methods</span
                        >
                        <div
                          class="d-flex align-items-center justify-content-center gap-1 flex-wrap mx-auto mx-md-0"
                          style="max-width: 200px"
                        >
                          <ng-container
                            *ngFor="let pm of compareResult.paymentMethods"
                          >
                            <img
                              style="
                                max-height: 20px;
                                max-width: 60px;
                                width: 30%;
                              "
                              src="https://cdn.platex.co.uk/assets/card-icons/flat-rounded/{{
                                pm
                              }}.svg"
                              class="d-block"
                            />
                          </ng-container>
                        </div>
                      </div>

                      <div class="d-block text-start flex-fill">
                        <span class="fw-bold mb-1 d-block">Finance</span>

                        <small class="ms-2" *ngIf="!compareResult.finance"
                          >Not Offered</small
                        >
                        <small
                          class="ms-2"
                          *ngIf="
                            compareResult.finance &&
                            (compareResult.financeMethods == null ||
                              compareResult.financeMethods.length <= 0)
                          "
                        >
                          Bespoke
                        </small>
                        <div
                          *ngIf="
                            compareResult.finance &&
                            compareResult.financeMethods != null &&
                            compareResult.financeMethods.length > 0
                          "
                          class="d-flex align-items-center justify-content-start gap-1 flex-wrap mx-auto mx-md-0 ms-2"
                          style="max-width: 200px"
                        >
                          <ng-container
                            *ngFor="let pm of compareResult.financeMethods"
                          >
                            <div
                              style="
                                max-height: 20px;
                                max-width: 60px;
                                width: 30%;
                              "
                            >
                              <img
                                src="https://cdn.platex.co.uk/assets/card-icons/flat-rounded/{{
                                  pm
                                }}.svg"
                                style="max-height: 20px"
                                class="d-block h-100 ms-0"
                              />
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <div
                      class="d-flex flex-column justify-content-between align-items-start flex-fill py-2"
                    >
                      <div class="d-block text-start flex-fill w-100">
                        <span class="fw-bold mb-1 d-block"
                          >Transfer Service</span
                        >
                        <p class="text-end w-100">
                          {{
                            compareResult.transferService > -1
                              ? (compareResult.transferService / 100
                                | currency : "GBP")
                              : "Not Offered"
                          }}
                        </p>
                      </div>

                      <div class="d-block text-startf flex-fill w-100">
                        <span class="fw-bold mb-1 d-block"
                          >Pair of Standard Platts</span
                        >
                        <p class="text-end w-100">
                          {{
                            compareResult.physicalPlatePrice > -1
                              ? (compareResult.physicalPlatePrice / 100
                                | currency : "GBP")
                              : "Not Offered"
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-block d-md-none">
                    <div
                      style="background-color: #1b9b8a"
                      class="p-3 border-start w-100 rounded-3"
                      (click)="openCompareSiteHandler(compareResult)"
                    >
                      <p class="mb-0 fs-6 text-white text-center">
                        Go to Dealer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- BODY END -->

            <!-- MOBILE 2ND ROW -->
            <div class="border-top d-block d-md-none px-3 py-2 py-md-0">
              <div
                class="d-flex justify-content-between align-items-center {{
                  compareResult.total <= 0 ? 'opacity-25' : ''
                }}"
              >
                <div>
                  <p
                    (click)="openCompareSiteHandler(compareResult)"
                    class="mb-0 inclusive-plate-font"
                    style="color: #1d76c9"
                  >
                    {{ registration }}
                  </p>
                </div>
                <div class="flex-fill"></div>
                <div
                  (click)="openCompareSiteHandler(compareResult)"
                  class="rounded-pill ps-2 pe-1 py-1"
                  style="background-color: #007850"
                >
                  <small class="fw-bold text-white me-2">
                    {{
                      compareResult.total <= 0 ? "Unavailable" : "Visit Dealer"
                    }}</small
                  >
                  <mat-icon
                    style="color: #007850"
                    class="bg-white rounded-circle small"
                    >chevron_right</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- SEE MORE OVERLAY -->
    <div
      *ngIf="!seeAll"
      class="position-absolute w-100 gradient-fade start-0 end-0 bottom-0 d-flex align-items-center justify-content-end flex-column"
      style="
        z-index: 50;
        min-height: 100px;
        user-select: none;
        pointer-events: none;
      "
    >
      <div
        class="border border-dark mx-auto d-flex align-items-center justify-content-center gap-2 bg-white rounded-pill px-3 py-1 shadow-sm mb-3"
        style="
          width: fit-content;
          user-select: auto;
          pointer-events: all;
          cursor: pointer;
        "
        (click)="SelectAllHandler()"
      >
        <span>See More</span>
        <!-- <mat-icon class="large d-block align-self-end">expand_more</mat-icon> -->
      </div>
    </div>
    <!-- SEE MORE END -->
  </div>
</div>
