<div class="page">
  <div class="page-description-container"></div>
  <div class="content-container">
    <div class="center-content-container main-content-container">
      <div class="pla_card mobile spacing no-top no-card no-left no-right">
        <pla-banner-image [taller]="true"></pla-banner-image>
        <!-- below banner in card -->
      </div>

      <!-- out of card floating header -->
      <div class="header">
        <h1>Sellers Hub</h1>
      </div>

      <!-- Horizontal Container -->
      <div class="grid-options-container">
        <!-- User listings -->
        <div class="grid-option pla_card mobile padding spacing">
          <h1>Your listings</h1>

          <!-- MAIN CONTENT -->
          <div
            *ngIf="
              sellerListings && !sellerListings.loading;
              else loadingContent
            "
          >
            <div
              *ngIf="sellerListings.data; else noListings"
              class="list-container"
            >
              <ng-container *ngFor="let listing of sellerListings.data">
                <div class="list-item" (click)="handleListingClick(listing)">
                  <!-- listed plate -->
                  <pla-plate
                    [rawPlate]="listing.registration"
                    [white]="false"
                    [smallPlate]="true"
                  ></pla-plate>

                  <!-- listing plan -->
                  <div class="flex-space listing-type">
                    <span>{{ listing.listingTypeString() }}</span>
                  </div>

                  <!-- listing status -->
                  <div class="status-container">
                    <div
                      class="status-icon {{
                        mapStatusToColour(listing.status)
                      }}"
                    >
                      <span>{{ listing.listingStatusString() }}</span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

          <!-- ACTION CONTENT -->
          <div>
            <button
              mat-flat-button
              class="turquoise-button section-end-button"
              (click)="handleNewListingClick()"
            >
              Create new listing
            </button>
          </div>

          <!-- EMPTY CONTENT -->
          <ng-template #noListings>
            <div class="raw-message-container">
              <p>No listings to show</p>
            </div>
          </ng-template>
        </div>

        <!-- Analytics -->
        <div class="grid-option pla_card mobile padding spacing">
          <h1>Listing Analytics</h1>

          <div *ngIf="sellerAnalytics; else noAnalytics" class="list-container">
            <ng-container *ngFor="let analytic of sellerAnalytics">
              <div class="list-item" (click)="handleAnalyticClick(analytic)">
                <!-- analytic name -->
                <div>
                  <h2>{{ analytic.name }}</h2>
                </div>

                <!-- analytic value -->
                <div>
                  <span>{{ analytic.value }}</span>
                </div>

                <!-- analytic status -->
                <div class="status-container">
                  <div class="status-icon {{ analytic.status }}">
                    <mat-icon>{{ mapAnalyticToIcon(analytic) }}</mat-icon>
                    <span>{{ analytic.change | percent }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- EMPTY CONTENT -->
          <ng-template #noAnalytics>
            <div class="raw-message-container">
              <p>No analytics to show</p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingContent>
  <div class="raw-message-container spinner-loader">
    <mat-progress-spinner
      class="spinner cyan"
      mode="indeterminate"
      [color]="'#327b8d'"
      [diameter]="60"
    ></mat-progress-spinner>
  </div>
</ng-template>
