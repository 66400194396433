import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BYOTermBreakdown } from 'src/app/models/byoTermResponse';
import { Registration } from 'src/app/models/registration';

class Character {
  constructor(
    public index: number,
    public section: string,
    public characterOptions: string[],
    public nullable: boolean = false,
    public spaceBefore: boolean = false,
    public selectedCharacter: string = ''
  ) {
    this.selectedCharacter = characterOptions[0];
  }
}

@Component({
  selector: 'pla-wildcard-character-select',
  templateUrl: './wildcard-character-select.component.html',
  styleUrls: ['./wildcard-character-select.component.scss'],
})
export class WildcardCharacterSelectComponent implements OnInit {
  @Input('prefix') public prefix: string;
  @Input('numbers') public numbers: string;
  @Input('letters') public letters: string;

  @Input() public padLeft: boolean = true;
  @Input() public style: string;
  @Input() public length: string = null; // used for dateless eg: *x0 = A1 / 00x*** = 11AAA
  @Input() public boxCount: number = 0;
  @Input() public emitOnChange: boolean = false;
  @Input() public showMessage: boolean = true;
  @Input() public message: string = null;
  @Input() public forceSearchButton: boolean = false;
  @Input() public withPadding: boolean = true;
  @Input() public fullWidth: boolean = false;
  @Input() public searchButtonColour: 'white' | 'black' = 'black';
  @Input() public loading: boolean = false;
  @Input() public animateOnRecalc: boolean = false;
  @Input() public validationRegex: string = null;
  @Input() public regenerate: EventEmitter<void> = new EventEmitter<void>();
  @Input() public regenerateWithBreakdowns: EventEmitter<BYOTermBreakdown> =
    new EventEmitter<BYOTermBreakdown>();
  @Output() public newFormat: EventEmitter<BYOTermBreakdown> =
    new EventEmitter<BYOTermBreakdown>();
  @Output() public inlineSearch: EventEmitter<BYOTermBreakdown> =
    new EventEmitter<BYOTermBreakdown>();

  @Input('no_character') public no_character: string = '﹣';
  @Input('any_character') public any_character: string = '?';
  @Input('wildcard_character') public wildcard_character: string = '±';

  public any_numeric_character: string;
  public any_alpha_character: string;

  private letter = (
    index: number,
    section: string,
    spaceBefore: boolean = false
  ) =>
    new Character(
      index,
      section,
      `${this.any_alpha_character}ABCDEFGHJKLMNOPQRSTUVWXYZ`.split(''),
      false,
      spaceBefore
    );
  private specific_letter = (
    index: number,
    section: string,
    specific_number: string,
    spaceBefore: boolean = false
  ) =>
    new Character(
      index,
      section,
      `${this.any_alpha_character}${specific_number}`.split(''),
      false,
      spaceBefore
    );
  private number = (
    index: number,
    section: string,
    nullable: boolean = false,
    spaceBefore: boolean = false
  ) =>
    new Character(
      index,
      section,
      `${this.any_numeric_character}0123456789`.split(''),
      nullable,
      spaceBefore
    );
  private specific_number = (
    index: number,
    section: string,
    specific_number: string,
    nullable: boolean = false,
    spaceBefore: boolean = false
  ) =>
    new Character(
      index,
      section,
      `${this.any_numeric_character}${specific_number}`.split(''),
      nullable,
      spaceBefore
    );
  private null_number = (
    index: number,
    section: string,
    nullable: boolean = false,
    spaceBefore: boolean = false
  ) =>
    new Character(
      index,
      section,
      `${
        this.no_character ? this.wildcard_character + this.no_character : null
      }${this.any_numeric_character}0123456789`.split(''),
      nullable,
      spaceBefore
    );
  private null_letter = (
    index: number,
    section: string,
    spaceBefore: boolean = false
  ) =>
    new Character(
      index,
      section,
      `ABCDEFGHJKLMNOPQRSTUVWXYZ`.split(''),
      true,
      spaceBefore
    );

  public characterEntry: Character[];
  private formats = {};

  constructor(private router: Router, public platform: Platform) {}

  ngOnInit(): void {
    this.regenerate.subscribe(() => {
      this.generate();
      this.newFormat.emit(this.genTermbreakdown());
    });

    this.regenerateWithBreakdowns.subscribe((bd: BYOTermBreakdown) => {
      this.prefix = bd.prefix;
      this.numbers = bd.numbers;
      this.letters = bd.letters;
      this.generate();
      this.newFormat.emit(this.genTermbreakdown());
    });

    this.generate();
    this.newFormat.emit(this.genTermbreakdown());
  }

  private generate(): void {
    this.any_alpha_character = this.any_character;
    this.any_numeric_character = this.any_alpha_character;

    this.formats = {
      current: [
        this.letter(0, 'prefix'),
        this.letter(1, 'prefix'),
        this.specific_number(0, 'numbers', '012567'),
        this.number(1, 'numbers'),
        this.letter(0, 'letters', true),
        this.letter(1, 'letters'),
        this.letter(2, 'letters'),
      ],
      prefix: [
        this.specific_letter(0, 'prefix', 'ABCDEFGHJKLMNOPRSTVWXYZ'),
        this.null_number(0, 'numbers', true),
        this.null_number(1, 'numbers', true),
        this.null_number(2, 'numbers', true),
        this.letter(0, 'letters', true),
        this.letter(1, 'letters'),
        this.letter(2, 'letters'),
      ],
      suffix: [
        this.specific_letter(0, 'prefix', 'ABCDEFGHJKLMNOPRSTVWXYZ'),
        this.letter(1, 'prefix'),
        this.letter(2, 'prefix'),
        this.null_number(0, 'numbers', true, true),
        this.null_number(1, 'numbers', true),
        this.null_number(2, 'numbers', true),
        this.letter(0, 'letters'),
      ],
    };

    // generate dateless format based on length
    if (this.style == 'dateless' && this.length != null) {
      this.createDatelessStyle();
    }

    if (this.style == 'any_text' && this.boxCount > 0) {
      this.createAnyStyle();
    }

    this.generateCharacters(this.style);
    this.fillInput();
  }

  private createDatelessStyle(): void {
    var datelessFormat = [];
    var i = 0;
    var letterFirst = this.length[0] == '*';
    var lastWasBreaker = false;
    this.length.split('').forEach((char: string, index: number) => {
      if (char == 'x') {
        i = 0;
        lastWasBreaker = true;
        return; // breaker character
      }
      if (char == '*') {
        // alpha
        datelessFormat.push(
          this.letter(
            i,
            letterFirst ? 'prefix' : 'letters',
            !letterFirst && lastWasBreaker
          )
        );
      } else {
        // numeric
        datelessFormat.push(
          this.number(i, 'numbers', false, letterFirst && lastWasBreaker)
        );
      }

      lastWasBreaker = false;
      i++;
    });
    this.formats['dateless'] = datelessFormat;
  }

  private createAnyStyle(): void {
    var anyFormat = [];
    for (var x = 0; x < this.boxCount; x++) {
      anyFormat.push(this.null_letter(x, 'letters', x > 0));
    }
    this.formats['any_text'] = anyFormat;
  }

  private fillInput(): void {
    var input = {
      prefix: this.prefix,
      numbers: this.numbers,
      letters: this.letters,
    };
    this.characterEntry.map((c: Character) => {
      if (
        this.style == 'any_text' &&
        (input[c.section] == null || c.index >= input[c.section].length)
      ) {
        c.selectedCharacter = '';
      }
      var x: string = input[c.section];
      if (x && x.length > c.index) {
        var char = x[c.index]
          .replace('-', this.any_character)
          .replace('*', this.no_character)
          .replace('$', this.wildcard_character);

        if (c.characterOptions.indexOf(char) > -1) c.selectedCharacter = char;
      }
    });
  }

  private CreateInput(style: string): Character[] {
    return this.formats[style];
  }

  private genTermbreakdown(): BYOTermBreakdown {
    var input = {
      prefix: '',
      numbers: '',
      letters: '',
    };
    this.characterEntry.map((c: Character) => {
      if (
        this.style == 'any_text' &&
        (c.selectedCharacter == '' || c.selectedCharacter == null)
      ) {
        input[c.section] += '';
      } else {
        input[c.section] += c.selectedCharacter
          .replace(this.no_character, '*')
          .replace(this.any_character, '-')[0]
          .replace(this.wildcard_character, '$');
      }
    });

    var breakdown = new BYOTermBreakdown();
    breakdown.style = this.style;
    breakdown.prefix = input.prefix;
    breakdown.numbers = input.numbers;
    breakdown.letters = input.letters;
    return breakdown;
  }

  public search(): void {
    this.inlineSearch.emit(this.genTermbreakdown());
  }

  public EnterSubmit(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      event.stopImmediatePropagation();
      this.search();
    }
  }

  public textInputHandler($event: KeyboardEvent, index): void {
    var isDelete = $event.code.toLowerCase() == 'backspace';

    if (!isDelete && this.validationRegex) {
      const r = new RegExp(this.validationRegex);
      const test = r.test(this.characterEntry[index].selectedCharacter);
      if (!test) {
        this.characterEntry[index].selectedCharacter = null;
        return;
      }
    }

    if (isDelete && index == 0) return;
    if (!isDelete && index == this.boxCount - 1) return;

    var indexToNav = index + (isDelete ? -1 : 1);
    var el = document.getElementById(`text_input_${indexToNav}`) as any;
    el.focus();
    el.select();
  }

  public eventChangeHandler(): void {
    if (!this.emitOnChange) return;
    this.search();
  }

  public generateCharacters(style: string): void {
    switch (style) {
      case 'current':
        this.characterEntry = this.CreateInput(style);
        break;

      case 'prefix':
        this.characterEntry = this.CreateInput(style);
        break;

      case 'suffix':
        this.characterEntry = this.CreateInput(style);
        break;

      case 'dateless':
        this.characterEntry = this.CreateInput(style);
        break;

      case 'any_text':
        this.characterEntry = this.CreateInput(style);
        break;
    }
  }
}
