import { AddedFee } from './registration';

export class PriceBreakdown {
  public registrationPrice: number;
  public vat: number;
  public vatIncluded: boolean;
  public addedFees: AddedFee[];
  public total: number;
  public totalWithExtras: number;
}
