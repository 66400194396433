import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { SwitchTab } from 'src/app/components/switch-toggle/switch-toggle.component';
import { BYOTermBreakdown } from 'src/app/models/byoTermResponse';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import {
  TrackingService,
  TRACKING_SEARCH_TYPE,
} from 'src/app/services/tracking-service';
import SwiperCore, { Navigation, Pagination, Swiper, Thumbs } from 'swiper';

SwiperCore.use([Navigation, Thumbs, Pagination]);

export class DatelessSearchResponse {
  constructor(
    public style: string,
    public layout: string,
    public main: Registration[],
    public reversed: Registration[]
  ) {}
}

export class DatelessStyleOptionGroup {
  constructor(
    public name: string,
    public viewName: string,
    public reverseViewName: string,
    public options: DatelessLengthOption[]
  ) {}
}

export class DatelessLengthOption {
  constructor(public viewValue: string, public value: string) {}
}

export class DatelessSearchRequestItem {
  constructor(
    public size: string,
    public search: string,
    public callback: (res: DatelessSearchResponse) => void,
    public canReturn: boolean = true
  ) {}

  public pop(): void {
    this.canReturn = false;
  }
}

@Component({
  selector: 'pla-dateless-search-page',
  templateUrl: './dateless-search-page.component.html',
  styleUrls: ['./dateless-search-page.component.scss'],
})
export class DatelessSearchPageComponent implements OnInit {
  public datelessFeatured = new FeatureType('Featured Dateless', 'dateless');
  public activeSwitch = 'letter';
  public tabs: SwitchTab[] = [
    new SwitchTab('Letters First', 'letter'),
    new SwitchTab('Numbers First', 'number'),
    new SwitchTab('Both', 'both'),
  ];

  public SwitchChange(tab: SwitchTab): void {
    if (tab.id == 'both') this.toggleBoth();
    else this.toggleMode(tab.id);
  }

  private requestQueue: DatelessSearchRequestItem[] = [];

  public swiper: Swiper;
  public swiperNavigation = {
    nextEl: '.swiper-nav.swiper-next',
    prevEl: '.swiper-nav.swiper-prev',
  };
  public swiperBreakpoints = {
    1200: {
      slidesPerView: 5,
    },
    1100: {
      slidesPerView: 5,
    },
    900: {
      slidesPerView: 4,
    },
    850: {
      slidesPerView: 3,
    },
    600: {
      slidesPerView: 4,
    },
    500: {
      slidesPerView: 3,
    },
  };

  public plateSizeBreakpoint(): boolean {
    return window.innerWidth <= 1200;
  }

  public results: DatelessSearchResponse = new DatelessSearchResponse(
    '',
    '',
    [],
    []
  );
  public allResults: SearchReqResponse;
  public searching: boolean = false;
  public selecting: boolean = false;
  public searchCriteria: string = null;

  public selectedGroup: DatelessStyleOptionGroup = null;
  public selectedOption: DatelessLengthOption = null;
  public letterFirst: boolean = true;
  public showBoth: boolean = false;

  private lastSearchKey: { length: string; search: string } = null;

  public recalculateInput: EventEmitter<void> = new EventEmitter<void>();
  public main_resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public all_resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public prefix: string = '';
  public numbers: string = '';
  public letters: string = '';
  public any_character: string = '?';
  public no_character: string = '﹣';
  public canSearch: boolean = false;

  public lastSearchTime: number = 0;
  public selectionCooldown: number = 1500;
  public queuedDebounce: boolean = false;

  public optionGroups: DatelessStyleOptionGroup[] = [
    // 1x
    new DatelessStyleOptionGroup('1x1', 'ONE X ONE', 'ONE X ONE', [
      new DatelessLengthOption('A 1', '*x-'),
      new DatelessLengthOption('1 A', '-x*'),
    ]),
    new DatelessStyleOptionGroup('1x2', 'ONE X TWO', 'TWO X ONE', [
      new DatelessLengthOption('A 12', '*x--'),
      new DatelessLengthOption('12 A', '--x*'),
    ]),
    new DatelessStyleOptionGroup('1x3', 'ONE X THREE', 'THREE X ONE', [
      new DatelessLengthOption('A 123', '*x---'),
      new DatelessLengthOption('123 A', '---x*'),
    ]),
    new DatelessStyleOptionGroup('1x4', 'ONE X FOUR', 'FOUR X ONE', [
      new DatelessLengthOption('A 1234', '*x----'),
      new DatelessLengthOption('1234 A', '----x*'),
    ]),

    // 2x
    new DatelessStyleOptionGroup('2x1', 'TWO X ONE', 'ONE X TWO', [
      new DatelessLengthOption('AB 1', '**x-'),
      new DatelessLengthOption('1 AB', '-x**'),
    ]),
    new DatelessStyleOptionGroup('2x2', 'TWO X TWO', 'TWO X TWO', [
      new DatelessLengthOption('AB 12', '**x--'),
      new DatelessLengthOption('12 AB', '--x**'),
    ]),
    new DatelessStyleOptionGroup('2x3', 'TWO X THREE', 'THREE X TWO', [
      new DatelessLengthOption('AB 123', '**x---'),
      new DatelessLengthOption('123 AB', '---x**'),
    ]),
    new DatelessStyleOptionGroup('2x4', 'TWO X FOUR', 'FOUR X TWO', [
      new DatelessLengthOption('AB 1234', '**x----'),
      new DatelessLengthOption('1234 AB', '----x**'),
    ]),

    // 3x
    new DatelessStyleOptionGroup('3x1', 'THREE X ONE', 'ONE X THREE', [
      new DatelessLengthOption('ABC 1', '***x-'),
      new DatelessLengthOption('1 ABC', '-x***'),
    ]),
    new DatelessStyleOptionGroup('3x2', 'THREE X TWO', 'TWO X THREE', [
      new DatelessLengthOption('ABC 12', '***x--'),
      new DatelessLengthOption('12 ABC', '--x***'),
    ]),
    new DatelessStyleOptionGroup('3x3', 'THREE X THREE', 'THREE X THREE', [
      new DatelessLengthOption('ABC 123', '***x---'),
      new DatelessLengthOption('123 ABC', '---x***'),
    ]),
    new DatelessStyleOptionGroup('3x4', 'THREE X FOUR', 'THREE X FOUR', [
      new DatelessLengthOption('ABC 1234', '***x----'),
      // new DatelessLengthOption('1234 ABC', '----x***'),
    ]),
  ];

  /*
    pageStyle = 3x1 = STYLE OF PLATE
    searchCriteria = -*- = PLATE FILTER
    startingWith = letter/number = STARTING WITH LETTER OR NUMBER
  */

  constructor(
    private searchService: SearchService,
    private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private ref: ChangeDetectorRef,
    private trackingService: TrackingService
  ) {
    var pageStyle = this.route.snapshot.params.length;
    var searchCriteria = this.route.snapshot.params.searchCriteria;

    if (searchCriteria == null || searchCriteria == '') {
      this.setSelected(pageStyle, false); // will default to first option if not filled
      this.canSearch = false;
      return; // nothing selected
    }

    this.letterFirst = this.route.snapshot.params.startingWith != 'number';

    this.trackingService.TrackSearch(
      TRACKING_SEARCH_TYPE.DATELESS,
      searchCriteria,
      {
        size: pageStyle,
      }
    );

    this.setSelected(pageStyle);

    this.calcBreadown(searchCriteria);
    this.canSearch = true;
  }

  ngOnInit(): void {
    this.search();
  }

  /* 
    MAIN = NUM FIRST
    REV  = LET FIRST
  */
  private presentData(): void {
    this.main_resultChange.emit(
      new SearchReqResponse('dateless_id', this.results.main)
    );
    this.all_resultChange.emit(this.allResults);

    setTimeout(() => {
      this.searching = false;
    }, 100);
    this.refreshUI();
  }

  public geSlideDataIndex(swiper: SwiperCore) {
    return swiper.realIndex;
  }

  public swiperInit(_e: any): void {
    this.swiper = _e[0];
    var i = this.optionGroups.findIndex(
      (og) => og.name == this.selectedGroup.name
    );
    if (i < 0 || i == this.geSlideDataIndex(this.swiper)) return;
    this.swiper.slideToLoop(i);
  }

  public onSwipe(): void {
    if (this.swiper == null) return;
    var index = this.geSlideDataIndex(this.swiper);
    if (index < 0 || index > this.optionGroups.length) return;
    var option = this.optionGroups[index];
    if (option == null) return;
    this.setSelected(option.name, true);
    this.refreshUI();
    setTimeout(() => {
      this.setSelected(option.name);
    }, 10);
  }

  public slideDir(swipeRight: boolean): void {
    if (this.searching || this.selecting) return; // cant swipe while searching
    if (swipeRight) this.swiper.slideNext();
    else this.swiper.slidePrev();

    var index = this.geSlideDataIndex(this.swiper);
    if (index < 0 || index > this.optionGroups.length) return;
    var option = this.optionGroups[index];
    if (option == null) return;
    this.setSelected(option.name, true);
  }

  public slideTo(optionGroup: DatelessStyleOptionGroup): void {
    if (this.searching || this.selecting) return; // cant swipe while searching
    var i = this.optionGroups.findIndex((og) => og.name == optionGroup.name);
    if (i < 0 || i == this.geSlideDataIndex(this.swiper)) return;
    this.swiper.slideToLoop(i, 0);
  }

  private calcBreadown(criteria: string): void {
    this.searchCriteria = criteria;
    var searchBreakdown = this.searchCriteria.split('*');
    if (searchBreakdown.length != 2) return;
    this.prefix = this.letterFirst ? searchBreakdown[0] : null;
    this.numbers = this.letterFirst ? searchBreakdown[1] : searchBreakdown[0];
    this.letters = this.letterFirst ? null : searchBreakdown[1];
  }

  // take in style ie: 3x1
  public setSelected(style: string, emit: boolean = true) {
    var filtered = this.optionGroups.filter((og) => og.name == style);
    if (filtered == null || filtered.length == 0)
      this.selectedGroup = this.optionGroups[0];
    else this.selectedGroup = filtered[0];

    if (this.selectedGroup.options.length >= 2)
      this.selectedOption =
        this.selectedGroup.options[this.letterFirst ? 0 : 1];
    else this.selectedOption = this.selectedGroup.options[0];

    // this.refreshUI();
    if (!emit) return;
    setTimeout(() => {
      this.recalculateInput.emit();
    }, 10);
  }

  public toggleBoth(): void {
    this.showBoth = !this.showBoth;
  }

  public toggleMode(mode: string): void {
    if (this.selectedGroup.options.length == 1) return;

    this.showBoth = false;
    var setTo = mode != 'number';
    if (setTo == this.letterFirst) return; // dont redo
    this.letterFirst = setTo;

    // switch values
    if (this.letterFirst) {
      this.prefix = this.letters;
      this.letters = null;
    } else {
      this.letters = this.prefix;
      this.prefix = null;
    }

    this.setSelected(this.selectedGroup.name);

    if (this.results == null || this.results.style == '') return;

    // swap results in view
    var temp = this.results.reversed;
    this.results.reversed = this.results.main;
    this.results.main = temp;
    this.presentData();
  }

  private createUrl(): string {
    var _ = this.letterFirst
      ? this.prefix + '*' + this.numbers
      : this.numbers + '*' + this.letters;

    this.searchCriteria = _;

    return `dateless-search/${this.selectedGroup.name}/${this.searchCriteria}/${
      this.letterFirst ? 'letter' : 'number'
    }`;
  }

  public newSearchFormat(event: BYOTermBreakdown): void {
    if (!this.canSearch) return;
    if (this.selectedGroup == null) return;
    this.prefix = event.prefix;
    this.numbers = event.numbers;
    this.letters = event.letters;
    this.location.go(this.createUrl());
    // this.search();
  }

  public searchWithCriteria(event: BYOTermBreakdown): void {
    if (this.selectedGroup == null) return;
    this.canSearch = true;
    this.prefix = event.prefix;
    this.numbers = event.numbers;
    this.letters = event.letters;
    this.location.go(this.createUrl());
    this.search();
  }

  private refreshUI(): void {
    // setTimeout(() => {
    //   console.log('is searching', this.searching);
    //   this.ref.detectChanges();
    // }, 0);
  }

  public forceSearch(): void {
    if (this.searching || this.selecting) return; // cant stack requests! :)
    this.canSearch = true;
    this.recalculateInput.emit();
    this.location.go(this.createUrl());
    this.search();
  }

  private search(): void {
    if (!this.canSearch) return;
    if (this.selectedGroup == null) return;
    if (this.searchCriteria == null) this.searchCriteria = '';

    // dont research
    var curKey = {
      length: this.selectedOption.value,
      search: this.searchCriteria,
    };

    if (this.lastSearchKey != null) {
      var reversedLength = curKey.length.split('').reverse().join('');
      var reversedSearch = curKey.search.split('*').reverse().join('*');

      var matchesLength =
        this.lastSearchKey.length == reversedLength ||
        this.lastSearchKey.length == curKey.length;

      var matchesSearch =
        this.lastSearchKey.search == reversedSearch ||
        this.lastSearchKey.search == curKey.search;

      if (matchesLength && matchesSearch) {
        return;
      }
    }
    this.lastSearchKey = curKey;
    // end dont research

    this.searching = true;
    this.refreshUI();

    var lastRequest = this.requestQueue[0]; // remove first // fifo
    if (lastRequest != null) lastRequest.pop();

    this.makeSearchRequest();
  }

  private formatRespData(res: DatelessSearchResponse): void {
    if (res != null) {
      res.main = this.registrationService.formatRegistrations(res.main);
      res.reversed = this.registrationService.formatRegistrations(res.reversed);
      this.results = res;

      this.allResults = new SearchReqResponse('dateless_id', [
        ...res.main,
        ...res.reversed,
      ]);

      this.presentData();
    } else {
      this.searching = false;
      this.selecting = false;

      // because of the swiper we need this
      this.refreshUI();
    }
  }

  private makeSearchRequest(): void {
    var queueItem: DatelessSearchRequestItem = new DatelessSearchRequestItem(
      this.selectedOption.value,
      this.searchCriteria || '',
      (res: DatelessSearchResponse) => {
        if (queueItem.canReturn) this.formatRespData(res);
      }
    );

    this.requestQueue.push(queueItem);
    this.searchService.searchDatelessLength(
      this.selectedOption.value,
      this.searchCriteria || '',
      (res: DatelessSearchResponse) => {
        queueItem.callback(res);
      }
    );
  }

  public searchTitle(header: string): string {
    return `${this.results.style}  (${header})`;
  }
}
