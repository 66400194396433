import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { HTTPCONSTANTS } from '../http/http-constants';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  constructor(private http: HttpClient) {}

  public logException(exception: any): void {
    Sentry.captureException(exception);
  }

  public logMessage(message: string): void {
    //Sentry.captureMessage(message);
  }
}
