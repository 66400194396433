<div class="form-group _{{ tabs.length }}_items">
  <div class="switch-toggle-pip">
    <ng-container *ngFor="let option of tabs; let i = index">
      <div
        class="toggle_option_slider"
        [ngClass]="{
          selected: selectedTabId == option.id,
          hidden: i > selectedTabIndex
        }"
      ></div>
    </ng-container>
    <div class="toggle_option_slider_pip"></div>
    <ng-container *ngFor="let option of tabs; let i = index">
      <div
        class="toggle_option_slider"
        [ngClass]="{
          selected: selectedTabId == option.id,
          hidden: i < selectedTabIndex
        }"
      ></div>
    </ng-container>
  </div>
  <div class="switch-toggle">
    <ng-container *ngFor="let option of tabs; let i = index">
      <input
        type="radio"
        [name]="comp_id + '_state-d'"
        [id]="comp_id + '_state-d' + option.id"
        [checked]="selectedTabId == option.id"
        [value]="option.id"
      />
      <label (click)="onSelectionChange(option, i)" for="{{ option.name }}"
        >{{ option.name }}
      </label>
    </ng-container>
  </div>
</div>
