<div class="page" *ngIf="newPage">
  <div class="content-container extended">
    <div class="side-container p-3 pt-0 position-relative">
      <div class="position-sticky">
        <pla-side-nav></pla-side-nav>
        <div class="bg-light">
          <div class="">
            <pla-featured-plates
              [featureType]="rsFeatured"
              [plateClass]="'flat'"
              [plateSize]="'featured'"
              [priceFontSize]="12"
              [priceClass]="'text-muted fst-italic'"
              [maxCount]="14"
            ></pla-featured-plates>
          </div>
          <div class="border-top">
            <pla-featured-plates
              [featureType]="raFeatured"
              [plateClass]="'flat'"
              [plateSize]="'featured'"
              [priceFontSize]="12"
              [maxCount]="14"
            ></pla-featured-plates>
          </div>
        </div>
      </div>
    </div>
    <div class="center-content-container">
      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative"
      >
        <pla-banner-image
          *ngIf="!registration"
          [carBanner]="'PORSCHE_918_S'"
          [rawImage]="true"
          [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column p-4 p-md-4 new-banner-height"
            style="background-color: rgba(6, 34, 41, 0.45)"
          >
            <!-- HEADER TEXT -->
            <div
              class="text-white text-center w-100 align-self-center justify-content-center"
            >
              <p class="h1 mb-1 fw-bold">PlateX Compare</p>
              <div>
                <p class="fw-light fs-6" style="color: #facf15">
                  Find the best number plate for the best price
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>

        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-top border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 py-2"
            >
              <a
                style="color: #327b8d"
                class="d-block text-decoration-none"
                href="/"
                >Home</a
              >
              <span>/</span>
              <a
                style="color: #327b8d; white-space: pre"
                class="text-decoration-none d-block"
                href="/compare"
                >Compare</a
              >
              <span *ngIf="registration">/</span>
              <a
                *ngIf="registration"
                style="color: #327b8d; white-space: pre"
                class="text-decoration-none d-block"
                href="#"
                >{{ registration }}</a
              >
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="registration"
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative py-0"
      >
        <div class="px-2 px-md-1 mx-auto" style="max-width: 850px">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-6 order-2 order-md-1">
                <div
                  class="w-100 h-100 p-3 text-center d-flex flex-column justify-content-center align-items-center"
                >
                  <div>
                    <pla-plate
                      [rawPlate]="registration"
                      [white]="false"
                      [mobileSmall]="false"
                      [openModal]="false"
                      [showPrice]="false"
                      [useCustom]="true"
                      [customSize]="9"
                      [customFontSize]="40"
                    ></pla-plate>
                  </div>

                  <div *ngIf="detail">
                    <p class="text-center mt-3 mb-0" *ngIf="detail.min !== 0">
                      Prices From
                    </p>
                    <p class="mb-0" *ngIf="detail.min !== 0">
                      <strong>{{ detail.min | currency : "GBP" }}</strong> to
                      <strong>{{ detail.max | currency : "GBP" }}</strong>
                    </p>
                    <small class="text-muted"
                      >Details last updated on
                      {{ detail.lastUpdate | date : "dd/MM/yy" }}</small
                    >
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 order-1 order-md-2">
                <div
                  class="overflow-hidden bg-light"
                  style="border-radius: 25px"
                >
                  <img
                    class="w-100 d-block m-auto"
                    style="max-height: 6000px"
                    *ngIf="selectedImage && selectedImage.image"
                    [src]="selectedImage.image"
                  />
                </div>
              </div>
            </div>

            <!-- PLATE INFO -->
          </div>
        </div>

        <pla-sticky-tabs [config]="stickyLinkConfig"></pla-sticky-tabs>
        <div style="background-color: #fcfeff">
          <div class="pt-3 pb-3 w-100 px-2 px-md-1 position-relative">
            <div
              class="position-absolute"
              style="top: -92px"
              id="compare-deals"
            ></div>
            <pla-tp-sellers
              *ngIf="registrationLoaded"
              (PriceRangeChange)="PriceRangeChange($event)"
              (resultsChange)="CompareResultsChange()"
              [registration]="registration"
            ></pla-tp-sellers>
          </div>

          <div
            class="pt-3 pb-3 w-100 px-2 px-md-1 position-relative border-top"
          >
            <div
              class="position-absolute"
              style="top: -92px"
              id="history"
            ></div>
            <div class="px-0 px-md-4 mx-auto" style="max-width: 700px">
              <div>
                <p class="mb-2 fs-5">Price History</p>
                <p>
                  The price graph shows price changes over time including both
                  price changes and verified sales.
                </p>
              </div>
              <div>
                <table
                  *ngIf="detail.plateDetail"
                  class="text-start w-100 my-3 ms-0 me-auto"
                  style="max-width: 350px"
                >
                  <tr>
                    <td class="fs-6 fw-bolder pe-2">Registration Style</td>
                    <td class="fs-6">{{ detail.plateDetail.style }}</td>
                  </tr>
                  <tr>
                    <td class="fs-6 fw-bolder pe-2">First Issued</td>
                    <td class="fs-6">
                      {{ detail.plateDetail.issueMonth }}
                      {{ detail.plateDetail.issueYear }}
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6 fw-bolder pe-2">Region</td>
                    <td class="fs-6">{{ detail.plateDetail.issueRegion }}</td>
                  </tr>
                </table>
              </div>
              <div class="bg-white p-3 shadow-sm rounded-3">
                <pla-price-history
                  *ngIf="registrationLoaded"
                  [registration]="registration"
                ></pla-price-history>
              </div>
            </div>
          </div>

          <div class="pt-3 pb-3 w-100 px-2 px-md-1 position-relative">
            <div
              class="position-absolute"
              style="top: -92px"
              id="market-insight"
            ></div>
          </div>

          <div class="pt-3 pb-3 w-100 px-2 px-md-1 position-relative">
            <div class="position-absolute" style="top: -92px" id="faq"></div>
            <pla-faq [faq]="faq"></pla-faq>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" *ngIf="false">
    <pla-banner-image
      class="w-100"
      [rawImage]="true"
      [backgroundImage]="true"
      [carBanner]="'AVENTADOR_S_FOOTER'"
      [backgroundClass]="'w-100 desktop-mobile-bottom'"
    >
      <div
        class="d-flex align-items-stretch justify-content-end new-banner-height"
      >
        <div
          class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient"
        >
          <div
            class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
            style="max-width: 450px"
          >
            <p class="fs-5 text-white">
              With the entire market of registrations on one single portal,
              industry leading optimised search facilities and even price
              comparison it's no wonder buyers are choosing PlateX to find their
              perfect plate.
            </p>

            <div class="align-self-end mt-4">
              <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                Begin Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </pla-banner-image>
  </div>
</div>

<div class="page" *ngIf="!newPage">
  <div class="main-content-container content-container no-ds">
    <div class="center-content-container">
      <div
        class="pla_card mobile spacing no-top no-ds no-card no-left no-right"
      >
        <pla-banner-image [showShadow]="false" [carBanner]="'PORSCHE_TAYCAN'">
          <div class="page-title">
            <h1>PRICE COMPARISON</h1>
            <span class="sub">Search and Save</span>
          </div>
        </pla-banner-image>

        <div class="search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <pla-option-search
                [endpoint]="'compare'"
                [optionsEnabled]="false"
                [showHeaderText]="false"
                [searchCriteria]="registration"
                [plateStyle]="true"
                [gotoProductPage]="false"
              ></pla-option-search>
              <span class="header"
                >Enter the registration for price comparison</span
              >
            </div>
          </div>
        </div>

        <!-- RESULTS -->
        <div class="pla_card no-card mobile spacing padding no-left no-right">
          <ng-container *ngIf="registrationLoaded; else noRegistration">
            <ng-container *ngIf="validRegistration">
              <pla-compare-results-table
                *ngIf="registration; else noRegistration"
                [registration]="registration"
              ></pla-compare-results-table>
            </ng-container>
            <ng-container *ngIf="!validRegistration">
              <div class="text-section">
                <div class="text-title heading">
                  <h2>Invalid Registration</h2>
                </div>
                <div class="text-paragraph">
                  <p>Please enter a valid registration to continue.</p>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noRegistration>
  <!-- Page steps -->
  <div class="pla_card no-card mobile spacing padding">
    <div class="text-section">
      <div class="text-title heading">
        <h2>Compare</h2>
      </div>
      <div class="text-paragraph">
        <p>
          Simply enter the exact registration (taking care to only use the
          number 0 and letter O correctly) to find the best deal available for
          your perfect registration.
        </p>
      </div>

      <div class="text-paragraph">
        <div class="faq-item">
          <mat-icon>help_outline</mat-icon>
          <h4>How do you show so many registrations prices?</h4>
        </div>
        <p class="indent">
          Most registration prices are updated automatically while others by our
          team at least once a day, some several times an hour such as those in
          an active DVLA auction. However, dealers may update their prices more
          often and consequently PlateX does not guarantee that prices are
          correct. Always double-check the price on the dealer's website before
          completing a purchase to ensure that the price you saw at PlateX is
          correct.
        </p>

        <div class="faq-item">
          <mat-icon>help_outline</mat-icon>
          <h4>Are the prices shown fully inclusive of VAT and shipping?</h4>
        </div>
        <p class="indent">
          While we endeavour to show all prices inclusive of shipping for
          technical or resource reasons, some dealer's price may miss shipping
          cost information or only be included at checkout. In more rare cases
          some dealer prices may exclude VAT in our listings. If we are informed
          that a dealer has not displayed prices with VAT, we will add a note to
          “check with the dealer” to prompt users to refer to the dealer's site
          yourself to check whether VAT and shipping costs are inclusive. PlateX
          currently compares the prices of 68 million unique registrations from
          over 35 dealers, the DVLA and auctions. In some instances there may
          only be a single price for a registration if the dealer chooses to be
          the sole supplier of a registration, a private sales listing or a DVLA
          auction.
        </p>
      </div>
    </div>
  </div>
</ng-template>
