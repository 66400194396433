import { Component, Input, OnInit } from '@angular/core';
import { PlatePaymentDetails } from 'src/app/models/platePaymentDetails';

declare global {
  interface Window {
    KlarnaOnsiteService: any;
  }
}

@Component({
  selector: 'pla-price-breakdown',
  templateUrl: './price-breakdown.component.html',
  styleUrls: ['./price-breakdown.component.scss'],
})
export class PriceBreakdownComponent implements OnInit {
  @Input() public showTitle: boolean = true;
  @Input() public showFree: boolean = false;
  @Input() public showExtras: boolean = false;
  @Input() public paymentDetails: PlatePaymentDetails;

  ngOnInit(): void {
    setTimeout(() => {
      window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
      // this.paypalService.setMessage(
      //   this.paymentDetails.priceBreakdown.total,
      //   'paypal-placement'
      // );
    }, 500);
  }
}
