import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export class PlatopediaFilter {
  constructor(
    public filterName: string,
    public filterId: string,
    public active: boolean,
    public disabled: boolean = false
  ) {}

  public toQueryString(last: boolean = false): string {
    if (!this.active) return '';
    return `${this.filterId}${last ? '' : ','}`; //NewPlates,
  }
}

export class PlatopediaSearchRequest {
  constructor(
    public letterGroup: string,
    public filters: PlatopediaFilter[],
    public safeHeader: string,
    public searchGroups: string
  ) {}

  public queryString: string;
  public indiLetter: string;
}

@Component({
  selector: 'pla-platopedia-page',
  templateUrl: './platopedia-page.component.html',
  styleUrls: ['./platopedia-page.component.scss'],
})
export class PlatopediaPageComponent implements OnInit {
  public filters: PlatopediaFilter[] = [
    new PlatopediaFilter('New Release', '24-release', false),
    new PlatopediaFilter('Names', 'name', false),
    // new PlatopediaFilter('Prefix', 'prefix', false),
  ];
  public searchOptions: PlatopediaSearchRequest[] = [
    new PlatopediaSearchRequest('AG', this.filters, 'A - G', 'ABCDEFG'),
    new PlatopediaSearchRequest('HN', this.filters, 'H - N', 'HIJKLMN'),
    new PlatopediaSearchRequest('OU', this.filters, 'O - U', 'OPQRSTU'),
    new PlatopediaSearchRequest('VZ', this.filters, 'V - Z', 'VWXYZ'),
  ];

  public searchChange: EventEmitter<PlatopediaSearchRequest> =
    new EventEmitter<PlatopediaSearchRequest>();
  public scrollToTopTrigger: EventEmitter<void> = new EventEmitter<void>();
  public searchRequest: PlatopediaSearchRequest;
  public canSearch: boolean = true;
  public indiLetter: string;

  constructor(private location: Location, private route: ActivatedRoute) {}

  ngOnInit(): void {
    var routeGroup = this.route.snapshot.params.searchCriteria;
    var queryString = this.route.snapshot.queryParams['filters'];
    this.indiLetter = this.route.snapshot.queryParams['letter'];

    this.setFilterStatus(queryString);
    if (routeGroup == '' || routeGroup == null) {
      this.searchRequest = this.searchOptions[0];
      if (this.indiLetter == null || this.indiLetter == '')
        this.indiLetter = this.searchRequest.searchGroups[0];
    } else {
      this.searchRequest = this.searchOptions.find(
        (so) => so.letterGroup == routeGroup
      );
      if (this.indiLetter == null || this.indiLetter == '')
        this.indiLetter = this.searchRequest.searchGroups[0];
    }
    setTimeout(() => {
      this.search();
    }, 50);
  }

  private setFilterStatus(queryString: string): void {
    if (queryString == '' || queryString == null) return;
    var filterIds = queryString.split(',');
    filterIds.forEach((filterId: string, index: number) => {
      this.filters.map((f) => {
        if (f.filterId == filterId) f.active = true;
      });
    });
  }

  public setGroup(sr: PlatopediaSearchRequest): void {
    this.searchRequest = sr;
    this.indiLetter = sr.searchGroups[0];
    this.search();
  }

  public toggleFilter(filter: PlatopediaFilter): void {
    if (filter.disabled) return;
    filter.active = !filter.active;
    this.search();
  }

  public search(): void {
    if (this.searchRequest == null) return; // cant search
    this.canSearch = true;
    var queryStringHeader = 'filters=';
    var queryString = queryStringHeader;
    this.filters.forEach((filter: PlatopediaFilter, index: number) => {
      queryString += filter.toQueryString();
    });

    if (queryString == queryStringHeader) queryString = '';

    this.searchRequest.queryString = `?${queryString}`;

    if (queryString == '')
      if (this.indiLetter == null || this.indiLetter == '') queryString = '';
      else queryString = `letter=${this.indiLetter}`;
    else if (this.indiLetter != null && this.indiLetter != '')
      queryString += `&letter=${this.indiLetter}`;
    this.searchRequest.indiLetter = this.indiLetter;

    this.location.go(
      `plateopedia/${this.searchRequest.letterGroup}`,
      queryString
    );
    this.searchChange.emit(this.searchRequest);
  }

  public setFilterLetter(letter: string): void {
    this.indiLetter = letter;
    this.search();
    this.scrollToTopTrigger.emit();
  }

  public genSearchFilterButtons(): string[] {
    var searchGroups = [];
    if (
      this.filters.findIndex((f) => f.active && f.filterId == 'name') > -1 &&
      this.filters.filter((f) => f.active).length == 1
    ) {
      searchGroups.push('ALL');
    } else {
      if (this.indiLetter == 'ALL')
        this.setFilterLetter(this.searchRequest.searchGroups[0]);
    }
    searchGroups.push(...this.searchRequest.searchGroups.split(''));
    return searchGroups;
  }
}
