<div>
  <div class="page-description-container"></div>
  <div class="content-container">
    <div class="center-content-container">
      <div class="component-container">
        <ng-container *ngIf="!loading; else loadingSpinner">
          <pla-messenger-list
            class="messenger-list"
            [conversations]="conversations"
            [selectedConversation]="selectedConversation"
            (selectCovnersation)="conversationSelectionHandler($event)"
            [ngClass]="{ active: !conversationActive }"
          ></pla-messenger-list>
          <pla-messenger-client
            [conversation]="selectedConversation"
            [conversationChange]="conversationChange"
            (conversationClose)="conversationClosedHandler($event)"
            [ngClass]="{ active: conversationActive }"
            class="messenger-client"
          ></pla-messenger-client>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingSpinner>
  <div class="full spinner-loader">
    <div>
      <mat-progress-spinner
        class="spinner cyan"
        mode="indeterminate"
        [color]="'#327b8d'"
        [diameter]="60"
      ></mat-progress-spinner>
    </div>
    <span>Loading Conversations...</span>
  </div>
</ng-template>
