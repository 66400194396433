<div class="page">
  <div class="page-description-container"></div>
  <div class="main-content-container content-container">
    <div class="center-content-container">
      <!-- SEARCH CRITERIA -->
      <div class="pla_card no-card no-left no-right mobile spacing no-top">
        <div>
          <pla-banner-image [taller]="true" [carBanner]="'MINI_JCW'">
            <div class="page-title">
              <h1>PLATEOPEDIA</h1>
              <span class="sub">Word Plate Encyclopaedia</span>
            </div>
          </pla-banner-image>
          <div class="search-input-container">
            <div class="search-area inner">
              <div class="box-container">
                <div
                  class="plate-background no-colour rounded search-input-sizing centered p-search-cont"
                >
                  <div class="lsb-container">
                    <ng-container *ngFor="let psr of searchOptions">
                      <button
                        class="letter-section-button"
                        (click)="setGroup(psr)"
                        [ngClass]="{
                          active:
                            searchRequest?.letterGroup == psr?.letterGroup ||
                            indiLetter === 'ALL'
                        }"
                      >
                        {{ psr.safeHeader }}
                      </button>
                    </ng-container>
                  </div>
                </div>

                <div class="lsb-container filters-menu justify-content-center">
                  <ng-container *ngFor="let filter of filters">
                    <button
                      class="filter-button"
                      (click)="toggleFilter(filter)"
                      [ngClass]="{
                        active: filter.active,
                        disabled: filter.disabled
                      }"
                    >
                      <div class="icon">
                        <mat-icon>search</mat-icon>
                      </div>
                      <span>{{ filter.filterName }}</span>
                    </button>
                  </ng-container>
                  <!-- <button class="filter-button disabled"></button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- RESULTS -->
      <div
        *ngIf="canSearch; else infoSection"
        class="pla_card no-card mobile spacing"
      >
        <pla-platopedia-list
          *ngIf="searchRequest"
          [searchChange]="searchChange"
          [customHeader]="customHeader"
          [scrollToTopTrigger]="scrollToTopTrigger"
        ></pla-platopedia-list>
      </div>

      <ng-template #customHeader>
        <ng-container *ngFor="let l of genSearchFilterButtons()">
          <button
            mat-icon-button
            class="indi_letter_button"
            [ngClass]="{ activeIndi: l == this.indiLetter }"
            (click)="setFilterLetter(l)"
          >
            {{ l }}
          </button>
        </ng-container>
      </ng-template>

      <ng-template #infoSection>
        <div class="pla_card no-card mobile spacing padding">
          <div class="text-section">
            <div class="text-title heading">
              <h2>Platopedia</h2>
            </div>
            <div class="text-paragraph">
              <p>
                Through referencing a multitude of dictionaries and
                encyclopaedias this feature provides a comprehensive list of
                word plates available from the
                <pla-glossary-term-word
                  [term]="'Current or New Style Registration'"
                  [text]="'Current'"
                ></pla-glossary-term-word>
                series. Along with more common words many results are highly
                specialised, so PLATOPEDIA also gives you the definitions.
              </p>
            </div>

            <div class="text-title">
              <h2>How to...</h2>
            </div>
            <div class="text-paragraph">
              <p class="indent">
                Simply click through the letters above the results to discover
                an array of complete words and their meanings.
              </p>
            </div>

            <div class="text-title">
              <h2>Did you know…</h2>
            </div>
            <div class="text-paragraph">
              <p class="indent">
                Whilst the DVLA will hold back many of notable word plates for
                auction some still slip through and for general release! One
                famous example is the registration KN19 HTS which sold at
                general release at only £399 despite the DVLA holding back its
                sister plate KN16 HTS which sold at auction for almost 100 times
                more at £36,802.
              </p>
              <p class="indent">
                Our registered users get access to the possible words available
                in the weeks to general release. Register for free here.
              </p>
            </div>

            <div class="text-paragraph">
              <div class="faq-item">
                <mat-icon>help_outline</mat-icon>
                <h4>
                  I want a particular number to go alongside my initials but
                  it’s not there?
                </h4>
              </div>
              <p>
                This may mean that the number was not part of the series of has
                already been sold. Toggle to ‘See All’ and if you see the
                registration, you want click Notify and should it ever come back
                to the market you can be informed!
              </p>

              <div class="faq-item">
                <mat-icon>help_outline</mat-icon>
                <h4>Can I put any of these registrations on my vehicle?</h4>
              </div>
              <p>
                You are not allowed to make a vehicle appear younger by
                transferring a more recent registration. Simply click on the
                registration to see the minimum age of a vehicle needed for that
                registration.
              </p>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
