import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalNumber',
})
export class OrdinalNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) {
      return '';
    }

    return `${value}${this.nth(value)}`;
  }

  nth(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }
}
