import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'pla-large-image-modal',
  templateUrl: './large-image-modal.component.html',
  styleUrls: ['./large-image-modal.component.css'],
})
export class LargeImageModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { b64: string },
    public dialogRef: MatDialogRef<LargeImageModalComponent>
  ) {
    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close();
    });
  }

  ngOnInit(): void {}

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }
}
