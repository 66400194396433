import { Component, OnInit } from '@angular/core';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { PageStep } from 'src/app/components/page-steps/page-steps.component';
import { RegistrationService } from 'src/app/services/registration-service';

@Component({
  selector: 'pla-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public meFeatured = new FeatureType(
    'Most Expensive Plates',
    'most-expensive'
  );
  public xmasFeatured = new FeatureType('🎅🏼 Christmas Plates 🎄', 'x-mas');
  public vDayFeatured = new FeatureType(
    '❤ Valentines Plates 😘',
    'valentines-2024'
  );
  public raFeatured = new FeatureType('Recently Added', 'recently-added');
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');
  public plateCount: number = Math.floor(
    Math.random() * (6820000 - 6810000 + 1) + 68000000
  );
  public pageSteps: PageStep[] = [
    new PageStep().AddBody(
      "<b>Search</b> your name or initials with the UK's most intelligent registration search"
    ),
    new PageStep().AddBody(
      '<b>Choose</b> from the largest selection of registrations available with private sellers, dealers and at the DVLA Auctions'
    ),
    new PageStep().AddBody(
      '<b>Compare</b> deals across the whole market to get the best possible price'
    ),
    // new PageStep().AddBody(
    //   '<b>Buy</b> direct with us or be transferred to the seller to checkout by card or choose a monthly payment option'
    // ),
  ];

  constructor(private registrationService: RegistrationService) {
    this.registrationService
      .getPlateCount()
      .subscribe((_: { totalCount: number }) => {
        this.plateCount = _.totalCount;
      });
  }

  ngOnInit(): void {}
}
