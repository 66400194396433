<div class="px-0 px-md-4 mb-4">
  <div class="bg-white pt-3 rounded-3 overflow-hidden shadow-sm">
    <div *ngIf="mainLoader">
      <small class="text-center w-100 d-block pb-3">
        Comparing registration prices from multiple sites...
      </small>
    </div>
    <div
      *ngIf="!mainLoader"
      class="d-flex align-items-center justify-content-between w-100 pb-3"
    >
      <!-- names -->
      <!-- <ng-container *ngFor="let name of siteNames; let i = index">
        <span
          style="--animation-order: {{ i }};"
          class="text-muted text-center fs-6 fw-bold flex-shrink-0 slow-appear"
        >
          {{ name }}
        </span>
      </ng-container> -->
      <small
        class="mx-2 text-muted align-self-center text-center fw-bold fs-6 w-100 d-block"
      >
        Finalising...
      </small>
    </div>
    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
  </div>
</div>
