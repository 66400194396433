import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { ResultType } from 'src/app/components/search-result-table/search-result-table.component';
import { MultiTermOption } from 'src/app/models/multiTermOption';
import { MultiTermPlate } from 'src/app/models/multiTermPlate';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchService } from 'src/app/services/search-service';

export class TagOption {
  constructor(
    public name: string,
    public tag: string,
    public backgroundImage: string
  ) {}
}

@Component({
  selector: 'pla-multi-search-page',
  templateUrl: './multi-search-page.component.html',
  styleUrls: ['./multi-search-page.component.scss'],
})
export class MultiSearchPageComponent implements OnInit {
  public tagOptions: TagOption[] = [
    new TagOption(
      'Professions',
      'jobs',
      'https://www.freshbooks.com/wp-content/uploads/be-my-own-accountant.jpg.optimal.jpg'
    ),
    new TagOption(
      'Sports',
      'sports',
      'https://cwbradio.com/library/sports-lg/Basketball-through-hoop-96.jpg'
    ),
    new TagOption(
      'Interests',
      'interests',
      'https://images.adsttc.com/media/images/55df/d969/e58e/cee5/3000/0102/newsletter/57_EST_15.jpg?1440733530'
    ),
    new TagOption(
      'Cars',
      'cars',
      'https://robbreport.com/wp-content/uploads/2021/10/f4001.jpg'
    ),
  ];
  public options: MultiTermOption[] = [];
  public tag: string;
  public searchTerm: string;
  public results: Registration[];
  public hideEvent: EventEmitter<any> = new EventEmitter<any>();
  public resultChange: EventEmitter<any> = new EventEmitter<any>();
  public resultType: ResultType = ResultType.Term;
  public loading: boolean = true;
  private searchDefault = false;

  constructor(
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private registrationService: RegistrationService
  ) {
    this.tag = this.activatedRoute.snapshot.paramMap.get('tag');
    this.searchTerm =
      this.activatedRoute.snapshot.paramMap.get('searchCriteria');

    this.hideEvent.subscribe((res) => {
      this.searchService.hideMTPlate(res.plate_id);
    });

    this.searchService.fetchMultiTermOptions(this.tag, (_) => {
      this.options = _;
      if (this.options == null) return;
      if (this.searchTerm == null) {
        if (this.options.length == 0) return; // shouldnt happen
        if (this.searchTerm == null || this.searchTerm == '')
          if (this.searchDefault) this.searchTerm = this.options[0].term;

        this.search();
      }
    });

    if (this.searchTerm == null) return;
    this.search();
  }

  ngOnInit(): void {}

  public changeTag(tagOption: TagOption): void {
    if (this.tag == tagOption.tag) return;
    this.tag = tagOption.tag;
    this.searchTerm = ''; // reset
    this.searchService.fetchMultiTermOptions(this.tag, (_) => {
      this.options = _;
      if (this.options == null) return;
      if (this.options.length == 0) return;
      this.searchTerm = this.options[0].term; // select first option for search
      this.search();
    });
  }

  public searchTermSelectionChanged(data: MatSelectChange): void {
    if (data == null) return;
    this.search();
  }

  private search(): void {
    if (this.searchTerm == null || this.searchTerm == '') return;
    this.location.go(`/categories/${this.tag}/${this.searchTerm}`);
    this.loading = true;
    this.searchService.fetchMultiTermResults(
      this.searchTerm,
      (res: MultiTermPlate[]) => {
        res.map((r) => {
          if (r == null || r.registration == null) return;
          r.registration['term'] = r.term;
          r.registration['plate_id'] = r.id;
          r.registration['index'] = r.index;
        });
        var registrations = res.map((r) => r.registration);
        this.results =
          this.registrationService.formatRegistrations(registrations);
        this.loading = false;
        this.resultChange.emit(this.results);
      }
    );
  }
}
