<tr
  class="result-row"
  [ngClass]="{ last: lastRow }"
  *ngIf="!loading; else skeletonRow"
>
  <td class="py-2">
    <pla-plate
      [plate]="plate"
      [showPrice]="false"
      [mobileSmall]="true"
      [smallPlate]="false"
      [showPercentage]="false"
      [noMargin]="true"
      [disabled]="!plate.available"
      [white]="whitePlate"
    ></pla-plate>

    <pla-rating-bar
      class="mt-2 d-block"
      *ngIf="showRating"
      [canVote]="loggedIn"
      [registration]="plate.registration"
      [term]="plate.search"
      [likes]="plate.likes"
      [dislikes]="plate.dislikes"
      [percentage]="plate.percentage"
    ></pla-rating-bar>
  </td>
  <div class="desktop-only flex-space"></div>
  <!-- CUSTOM TEXT -->
  <td class="no-padding flex-space" *ngIf="!showPrice && customText">
    <div>
      <span style="text-align: center; display: block; width: 100%">{{
        customText
      }}</span>
    </div>
  </td>
  <td class="no-padding flex-space" *ngIf="showPrice">
    <!-- PRICE -->
    <div class="price-container" [ngClass]="{ 'n-a-price': plate.price <= 0 }">
      <div *ngIf="plate.priceBreakdown; else rawPrice">
        <div *ngIf="!isPOA(plate); else poa">
          <p class="price-field desktop-only">
            {{ plate.priceBreakdown.total | currency : "GBP" }}
          </p>
          <p class="price-field mobile-only" (click)="gotoCompareLink(plate)">
            <ng-container
              *ngIf="
                plate.priceBreakdown.total < 100000;
                else showBreakdownPence
              "
            >
              {{ plate.priceBreakdown.total | currency : "GBP" }}
            </ng-container>
            <ng-template #showBreakdownPence>
              {{
                plate.priceBreakdown.total
                  | currency : "GBP" : "symbol" : "1.0-0"
              }}
            </ng-template>
          </p>
        </div>
        <ng-template #poa>
          <p class="price-field">POA</p>
        </ng-template>
      </div>
      <ng-template #rawPrice>
        <ng-container *ngIf="plate.price >= 0; else notAvailable">
          <p class="price-field desktop-only">
            {{ plate.price / 100 | currency : "GBP" }}
          </p>

          <p class="price-field mobile-only">
            <ng-container *ngIf="plate.price / 100 < 100000; else showRawPence">
              {{ plate.price / 100 | currency : "GBP" }}
            </ng-container>
            <ng-template #showRawPence>
              {{ plate.price / 100 | currency : "GBP" : "symbol" : "1.0-0" }}
            </ng-template>
          </p>
        </ng-container>
        <ng-template #notAvailable>
          <p class="price-field desktop-only">Not Available</p>
          <p class="price-field mobile-only">N/A</p>
        </ng-template>
      </ng-template>

      <div *ngIf="plate && plate.registered">
        <p style="text-align: center">Currently Registered</p>
      </div>

      <div *ngIf="plate.price >= 0">
        <ng-container
          *ngIf="
            (plate.plate_owner == 0 || plate.plate_owner == 5) &&
              plate.max_price > 0;
            else defaultBottomRow
          "
        >
          <p class="text-center">
            <span>to{{ " " }}</span>
            <span class="price-field">{{
              plate.max_price / 100 | currency : "GBP"
            }}</span>
          </p>
        </ng-container>
        <ng-template #defaultBottomRow>
          <p
            *ngIf="plate.seller"
            class="seller-name"
            style="text-align: center"
          >
            {{ plate.seller }}
          </p>
          <a
            href="https://dvlaauction.co.uk/search?q={{ plate.registration }}"
            target="_blank"
            *ngIf="!plate.seller && plate.plate_owner == 5"
            class="seller-name"
            style="text-align: center"
            >N/A</a
          >
          <!-- DVLA AUCTION... -->
        </ng-template>
      </div>
    </div>
    <!-- END PRICE -->
  </td>
  <div class="desktop-only flex-space"></div>
  <td class="actions-field flex-field row-action">
    <!-- MOBILE FAVOURITE -->
    <div
      class="dynamic action-button-width mobile-only"
      *ngIf="plate.available && plate.price >= 0 && showButton1"
    >
      <div class="btn-container-full-width">
        <pla-signed-up-content
          *ngIf="plate"
          [requesting]="'favourite plate ' + plate?.registration"
        >
          <button
            unlockedContent
            mat-icon-button
            (click)="favouriteRegistration(plate)"
          >
            <ng-container *ngIf="plate.favourite; else notFavourite">
              <mat-icon style="color: #01c4c7">bookmark_added</mat-icon>
            </ng-container>
          </button>

          <button lockedContent mat-icon-button>
            <mat-icon>bookmark_border</mat-icon>
          </button>
        </pla-signed-up-content>
      </div>
    </div>

    <!-- DESKTOP FAVOURITE -->
    <div
      class="action-button-width desktop-only"
      *ngIf="plate.available && showButton1"
    >
      <div class="btn-container-full-width">
        <pla-signed-up-content
          *ngIf="plate"
          [requesting]="'favourite plate ' + plate?.registration"
        >
          <button
            unlockedContent
            mat-raised-button
            (click)="favouriteRegistration(plate)"
          >
            <ng-container *ngIf="plate.favourite; else notFavourite"
              ><mat-icon style="color: #01c4c7"
                >bookmark_added</mat-icon
              ></ng-container
            >
            <span class="inline" style="margin-left: 5px">Favourite</span>
          </button>

          <button lockedContent mat-raised-button>
            <mat-icon>bookmark_border</mat-icon>
            <span class="inline" style="margin-left: 5px">Favourite</span>
          </button>
        </pla-signed-up-content>
      </div>
    </div>

    <!-- Plate not available but include favourite gap -->
    <div
      *ngIf="!plate.available && showButton1"
      class="action-button-width desktop-only"
    ></div>
    <!-- <div
      *ngIf="!plate.available && showButton1"
      class="dynamic action-button-width mobile-only"
    ></div> -->

    <ng-template #notFavourite>
      <mat-icon>bookmark_border</mat-icon>
    </ng-template>

    <!-- ACTIONS -->
    <div
      class="action-button-width btn-container-full-width desktop-only"
      *ngIf="showButton2"
    >
      <ng-container
        *ngIf="plate.price >= 0 && plate.plate_owner != 5; else notify"
      >
        <button
          class="navy-button"
          (click)="gotoCompareLink(plate)"
          mat-raised-button
        >
          Compare
        </button>
      </ng-container>
      <ng-template #notify>
        <button
          *ngIf="plate.plate_owner != 5; else viewAuction"
          class="lighter-navy-button raised-button"
          mat-raised-button
        >
          Sell
        </button>
      </ng-template>
      <ng-template #viewAuction>
        <button
          class="neon-button"
          (click)="notifyRegistration(plate)"
          mat-raised-button
        >
          View Auction
        </button>
      </ng-template>
    </div>

    <!-- class="dynamic action-button-width mobile-only" -->
    <div
      *ngIf="plate.price < 0 && showButton2"
      class="action-button-width can_shrink btn-container-no-right-padding mobile-only"
    >
      <button class="lighter-navy-button raised-button" mat-raised-button>
        Sell
      </button>
    </div>

    <div class="action-button-width max-content btn-container-no-right-padding">
      <ng-container *ngIf="plate.price >= 0; else notifyButton">
        <button
          *ngIf="plate.seller && plate.plate_owner != 5; else auctionPlate"
          class="cyan-button"
          (click)="openPlateDetail(plate)"
          mat-raised-button
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span class="inline">View</span>
            <mat-icon class="small">arrow_right</mat-icon>
          </div>
        </button>
        <ng-template #auctionPlate>
          <button
            class="turquoise-button raised-button"
            (click)="openPlateAuction(plate)"
            mat-raised-button
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <span class="inline">Bid</span>
              <mat-icon class="small">arrow_right</mat-icon>
            </div>
          </button>
        </ng-template>
      </ng-container>
      <ng-template #notifyButton>
        <pla-signed-up-content
          *ngIf="plate"
          [requesting]="'notify plate ' + plate?.registration"
        >
          <button lockedContent class="platex-button" mat-raised-button>
            Notify Me
          </button>
          <button
            unlockedContent
            *ngIf="!plate.notify; else mobileNotifyActive"
            class="platex-button"
            (click)="notifyRegistration(plate)"
            mat-raised-button
          >
            Notify Me
          </button>
          <ng-template #mobileNotifyActive>
            <button
              class="platex-border-button"
              (click)="notifyRegistration(plate)"
              mat-raised-button
            >
              Notified
            </button></ng-template
          >
        </pla-signed-up-content>
      </ng-template>
    </div>
  </td>
</tr>

<!-- Button templates, all control their own size + state based on screen width -->
<ng-template #favouriteButton>
  <ng-container *ngIf="plate.favourite; else notFavourite"></ng-container>

  <div class="mobile-only">
    <button mat-icon-button (click)="favouriteRegistration(plate)">
      <mat-icon style="color: #01c4c7">bookmark_added</mat-icon>
    </button>
  </div>

  <div class="desktop-only">
    <button mat-raised-button (click)="favouriteRegistration(plate)">
      <ng-container *ngIf="plate.favourite; else notFavourite"
        ><mat-icon style="color: #01c4c7"
          >bookmark_added</mat-icon
        ></ng-container
      >
      <span class="inline" style="margin-left: 5px">Favourite</span>
    </button>
  </div>
</ng-template>

<ng-template #viewButton>
  <button class="sell-button raised-button" mat-raised-button>Sell</button>
</ng-template>

<ng-template #viewAuctionButton>
  <button class="sell-button raised-button" mat-raised-button>Sell</button>
</ng-template>

<ng-template #bidButton>
  <button class="sell-button raised-button" mat-raised-button>Sell</button>
</ng-template>

<ng-template #notifyButton>
  <button class="sell-button raised-button" mat-raised-button>Sell</button>
</ng-template>

<ng-template #compareButton>
  <button class="sell-button raised-button" mat-raised-button>Sell</button>
</ng-template>

<ng-template #sellButton>
  <button class="sell-button raised-button" mat-raised-button>Sell</button>
</ng-template>

<!-- SKELETON ROW -->
<ng-template #skeletonRow>
  <div class="result-row skeleton">
    <td>
      <div class="plate-size skeleton-item"></div>
    </td>
    <div class="flex-space desktop-only"></div>
    <td class="no-padding flex-space">
      <div class="price-container n-a-price">
        <p class="price-size skeleton-item"></p>
        <p class="price-size skeleton-item"></p>
      </div>
    </td>
    <div class="desktop-only flex-space"></div>
    <td class="actions-field flex-field">
      <div class="action-button-width desktop-only">
        <div class="btn-container-full-width">
          <div class="action-button-size skeleton-item"></div>
        </div>
      </div>

      <div class="action-button-width btn-container-full-width desktop-only">
        <div class="action-button-size skeleton-item"></div>
      </div>

      <div class="dynamic action-button-width mobile-only">
        <div class="btn-container-full-width">
          <div class="icon-button skeleton-item"></div>
        </div>
      </div>

      <div class="action-button-width btn-container-no-right-padding">
        <div class="btn-container-full-width">
          <div class="action-button-size skeleton-item"></div>
        </div>
      </div>
    </td>
  </div>
</ng-template>
