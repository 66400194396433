import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTPCONSTANTS } from '../http/http-constants';
import { PlatexMessage } from '../models/platexMessage';
import { PlatexSendMessageRequest } from '../models/platexSendMessageRequest';
import { StartConversationRequest } from '../models/startConversationRequest';
import { UserConversation } from '../models/userConversation';

@Injectable({ providedIn: 'root' })
export class MessagingService {
  constructor(private http: HttpClient) {}

  // Get all conversations user is a part of
  public getConversations(callback: (_: UserConversation[]) => void): void {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/messaging/get-user-conversations`;
    this.http.get<UserConversation[]>(url).subscribe(
      (_) => {
        callback(_);
      },
      () => {
        callback(null);
      }
    );
  }

  // Get all messages from conversation
  public getMessagesInConversations(
    conversation: UserConversation,
    callback: (_: PlatexMessage[]) => void
  ): void {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/messaging/get-conversation-messages/${conversation.contextId}`;
    this.http.get<PlatexMessage[]>(url).subscribe(
      (_) => {
        callback(_);
      },
      () => {
        callback(null);
      }
    );
  }

  // Send message to conversation
  public sendMessageToConversation(
    conversation: UserConversation,
    message: string,
    callback: (status: boolean) => void
  ): void {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/messaging/send-message`;
    var messageRequest = new PlatexSendMessageRequest(
      conversation.contextId,
      message
    );
    this.http.post(url, messageRequest).subscribe(
      (_) => {
        callback(true);
      },
      () => {
        callback(false);
      }
    );
  }

  // TODO: REMOVE, WILL BE DONE THROUGH SELLERS HUB
  // Start conversation with user
  public startConversation(
    name: string,
    userId: string,
    callback: (status: boolean) => void
  ): void {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/messaging/start-conversation`;
    var messageRequest = new StartConversationRequest(name, userId);
    this.http.post(url, messageRequest).subscribe(
      (_) => {
        callback(true);
      },
      () => {
        callback(false);
      }
    );
  }
}
