import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HTTPCONSTANTS } from '../http/http-constants';
import BlogPost from '../models/blogPost';

@Injectable({ providedIn: 'root' })
export class BlogService {
  constructor(private http: HttpClient) {}

  public GetPost(blogHandle: string): Observable<BlogPost> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/blog/${blogHandle}`;
    return this.http.get<BlogPost>(url);
  }

  public GetArticles(): Observable<BlogPost[]> {
    var url = `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/blog`;
    return this.http.get<BlogPost[]>(url);
  }
}
