import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HTTPCONSTANTS } from 'src/app/http/http-constants';
import {
  ListingType,
  ListingTypeMapper,
} from 'src/app/models/enums/listingType';
import { PlatexListingOrder } from 'src/app/models/platexListingOrder';
import { ListingService } from 'src/app/services/listing-service';

@Component({
  selector: 'pla-account-selling',
  templateUrl: './account-selling.component.html',
  styleUrls: ['./account-selling.component.scss'],
})
export class AccountSellingComponent implements OnInit {
  public userListings: PlatexListingOrder[] = [];
  public listingLoadings: {} = {};
  public loading: boolean = false;

  constructor(
    private listingService: ListingService,
    private router: Router,
    private http: HttpClient,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.listingService.GetUserListings().subscribe(
      (listings: PlatexListingOrder[]) => {
        listings.map((l: PlatexListingOrder) => {
          this.listingLoadings[l.advertId] = false;
        });
        listings.sort((a, b) => {
          if (!a.active) return -1;
          var res =
            new Date(b.created).getTime() > new Date(a.created).getTime()
              ? -1
              : 1;
        });
        listings.reverse();
        this.userListings = listings;
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  public getSubPriceLine(listing: PlatexListingOrder): string {
    if (!listing.active) return 'Inactive';
    if (listing.listingType === ListingType.Platex_Commision)
      return 'On commission';
    var listing = Object.assign(
      new PlatexListingOrder(),
      listing
    ) as PlatexListingOrder;
    const sub = listing.getActiveSub();
    if (sub == null) return 'Inactive';
    const price = this.currencyPipe.transform(sub.price / 100, 'GBP');
    const priceObj = ListingTypeMapper(listing.listingType);
    return `${price} ${priceObj.period}`;
  }

  public viewListing(listing: PlatexListingOrder): void {
    this.router.navigate([
      `/plate-detail/${listing.registration.replace(' ', '')}`,
    ]);
  }

  public editListing(listing: PlatexListingOrder): void {
    this.router.navigate([`/advert-edit/${listing.advertId}`]);
  }

  public billingListing(listing: PlatexListingOrder): void {
    var listing = Object.assign(
      new PlatexListingOrder(),
      listing
    ) as PlatexListingOrder;
    const activeSub = listing.getActiveSub();
    if (activeSub == null) return; // TODO: show error
    this.listingLoadings[listing.advertId] = true;
    this.http
      .post<{ url: string }>(
        `${HTTPCONSTANTS.protocol}${HTTPCONSTANTS.apiBaseAddress}/stripe/create-customer-portal-session/${activeSub.id}`,
        listing
      )
      .subscribe(
        (_: { url: string }) => {
          this.listingLoadings[listing.advertId] = false;
          window.open(_.url, '_blank');
        },
        (err: any) => {
          this.listingLoadings[listing.advertId] = false;
          console.error(err);
        }
      );
  }
}
