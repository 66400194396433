<!-- TODO CLOSE IF ROUTE IS MATCHED -->
<!-- [ngClass]="{
    hasSearchNav: false && isSearchPage(),
    noSearchNav: true || !isSearchPage()
  }" -->
<div class="noSearchNav">
  <mat-drawer-container
    class="main-page-container overflow-visible"
    [ngClass]="{ noScroll: isFullScreenPage() }"
  >
    <mat-drawer
      #navDrawer
      mode="over"
      position="start"
      style="width: 100vw; position: fixed; background-color: rgba(0, 0, 0, 0)"
      class="MOBILE-NAV-DRAWER"
      [autoFocus]="false"
    >
      <div class="flex-container mobile-nav-header">
        <div class="logo">
          <a [routerLink]="'/landing'">
            <pla-platex-direct [colour]="'white'"></pla-platex-direct>
          </a>
        </div>
        <div class="flex-space"></div>
        <a class="nav-item" (click)="navDrawer.close()">
          <mat-icon>close</mat-icon>
        </a>
      </div>
      <!-- MOBILE NAVIGATION -->
      <div class="navigation">
        <!-- NON ICON OPTION LOOP -->
        <ng-container *ngFor="let navOption of navigation">
          <ng-container *ngIf="navOption.showOnMobile && !navOption.icon">
            <ng-container *ngIf="navOption.menu; else noMenu">
              <div
                class="item"
                [routerLink]="navOption.route"
                (click)="closeNav()"
              >
                <a>{{ navOption?.viewRoute }}</a>
              </div>

              <!-- SUB MENU -->
              <div class="sub-menu-container">
                <ng-container *ngFor="let navMenuItem of navOption.menu">
                  <!-- dont show sub sub menus, false -> true to change -->
                  <ng-container
                    *ngIf="false && navMenuItem.subMenu; else noSubMenu"
                  >
                    <div class="sub-item">
                      <a>{{ navMenuItem?.viewRoute }}</a>
                    </div>
                    <div
                      class="sub-sub-item"
                      *ngFor="let navMenuSubItem of navMenuItem.subMenu"
                      (click)="navMenuSubItem.action()"
                    >
                      <a>{{ navMenuSubItem?.viewRoute }}</a>
                    </div>
                  </ng-container>

                  <!-- NO SUB MENU -->
                  <ng-template #noSubMenu>
                    <div class="sub-item" (click)="navMenuItem.action()">
                      <a>{{ navMenuItem?.viewRoute }}</a>
                    </div>
                  </ng-template>
                </ng-container>
              </div>
            </ng-container>

            <!-- NO MAIN DROPDOWN MENU -->
            <ng-template #noMenu>
              <ng-container *ngIf="navOption != null">
                <div class="item">
                  <a [routerLink]="navOption.route" (click)="closeNav()">
                    {{ navOption?.viewRoute }}
                  </a>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
        </ng-container>

        <!-- ICON OPTION LOOPS -->
        <ng-container *ngFor="let navOption of navigation">
          <ng-container *ngIf="navOption.showOnMobile && navOption.icon">
            <ng-container *ngIf="navOption.menu; else noMenu">
              <div class="item">
                <a [matMenuTriggerFor]="menu">
                  <!-- {{ navOption?.viewRoute }} -->
                  <mat-icon class="material-icons-outlined">{{
                    navOption.iconName
                  }}</mat-icon>
                  <span *ngIf="navOption?.viewRoute" class="icon-button-text">{{
                    navOption?.viewRoute
                  }}</span>
                </a>
              </div>
              <mat-menu #menu="matMenu">
                <ng-container *ngFor="let navMenuItem of navOption.menu">
                  <button mat-menu-item (click)="navMenuItem.action()">
                    {{ navMenuItem?.viewRoute }}
                  </button>
                </ng-container>
              </mat-menu>
            </ng-container>
            <ng-template #noMenu>
              <div class="item">
                <a [routerLink]="navOption.route" (click)="closeNav()">
                  <!-- {{ navOption?.viewRoute }} -->
                  <mat-icon class="material-icons-outlined">{{
                    navOption.iconName
                  }}</mat-icon>
                  <span *ngIf="navOption?.viewRoute" class="icon-button-text">{{
                    navOption?.viewRoute
                  }}</span>
                </a>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
    </mat-drawer>

    <mat-drawer-content
      [ngClass]="{ noScroll: isFullScreenPage() }"
      class="overflow-visible"
    >
      <!-- <pla-top-bar></pla-top-bar> -->
      <div class="site-header-container fixed">
        <div class="site-header">
          <pla-header
            (navigationToggle)="navDrawer.open()"
            [navigation]="navigation"
          ></pla-header>
        </div>
        <div class="spacer full"></div>
      </div>
      <div class="not_page">
        <pla-search-variant-bar
          *ngIf="true || isSearchPage()"
          [searchOptions]="options"
        ></pla-search-variant-bar>

        <!-- <pla-feedback-modal *ngIf="notPlateDetailPage"></pla-feedback-modal> -->
        <router-outlet></router-outlet>
      </div>
      <pla-footer *ngIf="!isFullScreenPage()"></pla-footer>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
