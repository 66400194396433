<div class="component-container">
  <div class="content-container no-ds">
    <div class="nav-options-container">
      <ng-container *ngFor="let option of options">
        <a
          class="nav-option"
          [ngClass]="{ selected: isActiveRoute(option) }"
          [routerLink]="option.route"
          >{{ option.view_option }}</a
        >
      </ng-container>
    </div>
  </div>
</div>
