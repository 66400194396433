<div class="table-container">
  <table>
    <tr>
      <th>Sale Type</th>
      <th style="text-align: right">Price</th>
    </tr>
    <ng-container *ngFor="let row of data; let i = index">
      <tr
        data-bs-toggle="tooltip"
        [attr.data-bs-placement]="tooltipPos()"
        title="Inflated Value: {{
          row.inf_sold_price / 100 | currency : 'GBP'
        }}"
      >
        <td>
          <span>{{ saleTypeMapper(row, row.saleType) }} </span>
          <span>{{ row.sold_date | date : "MMM YYYY" }}</span>
        </td>
        <td style="text-align: right">
          {{ row.sold_price / 100 | currency : "GBP" }}
        </td>
      </tr>
    </ng-container>
  </table>
</div>

<!-- sold dvla auction april 2005 -->
