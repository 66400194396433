import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pla-see-more-button',
  templateUrl: './see-more-button.component.html',
  styleUrls: ['./see-more-button.component.css'],
})
export class SeeMoreButtonComponent implements OnInit {
  @Input('term') public term: string;
  @Input('align') public align: string = 'center';
  @Input('padding') public padding: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public seeMore(): void {
    this.router.navigate([`/search-results/${this.term}`]);
  }
}
